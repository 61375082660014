import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Arrow from "@material-ui/icons/KeyboardArrowRight";
import Public from "@material-ui/icons/Public";
import Avatar from "@material-ui/core/Avatar";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";

// constants
import { EDITOR } from "constants/noteRoles";

// styles
import styles from "./styles";
import "./anime.css";

class NotebookListItem extends Component {
  static propTypes = {
    classes: PropTypes.object,
    selectedNotebook: PropTypes.object,
    onSelect: PropTypes.func,
    onEdit: PropTypes.func,
    n: PropTypes.object,
    jiggle: PropTypes.bool,
    onRearrange: PropTypes.func,
  };

  render() {
    const {
      selectedNotebook,
      onSelect,
      onEdit,
      n,
      jiggle,
      onRearrange,
    } = this.props;

    return (
      <div>
        <ul
          style={{
            cursor: jiggle ? "grab" : "pointer",
            listStyleType: "none",
            paddingInlineStart: 0,
            animationDelay: jiggle ? `-${Math.random()}s` : undefined,
            animationDuration: jiggle
              ? `.${Math.floor(Math.random() * 11) + 20}s`
              : undefined,
          }}
          onMouseDown={() => {
            this.setState({
              mouseDown: true,
            });
            setTimeout(() => {
              if (this.state.mouseDown) {
                this.setState({
                  mouseDown: false,
                });
                onRearrange();
              }
            }, 1000);
          }}
          onMouseUp={() => {
            this.setState({
              mouseDown: false,
            });
          }}
          className="icon"
        >
          <ListItem
            button
            component="div"
            style={{
              borderLeft: n.color ? `solid 5px ${n.color}` : undefined,
            }}
            selected={selectedNotebook && selectedNotebook.id === n.id}
            onClick={() => onSelect(n)}
            onDoubleClick={() => {
              if (n.role === EDITOR) {
                onEdit(n);
              }
            }}
          >
            {n.icon ? (
              <ListItemIcon>
                <img
                  src={n.icon}
                  style={{
                    width: "30px",
                    height: "30px",
                    objectFit: "contain",
                  }}
                  alt="notebook icon"
                />
              </ListItemIcon>
            ) : (
              []
            )}
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography>{n.name}</Typography>
                  {n.public ? (
                    <Chip
                      label="Public"
                      style={{ fontSize: 12 }}
                      avatar={
                        <Avatar style={{ background: "none" }}>
                          <Public style={{ fontSize: 14 }} />
                        </Avatar>
                      }
                      size="small"
                    />
                  ) : (
                    []
                  )}
                </React.Fragment>
              }
            />
            <ListItemSecondaryAction>
              <Arrow />
            </ListItemSecondaryAction>
          </ListItem>
          <Divider />
        </ul>
      </div>
    );
  }
}

export default withStyles(styles)(NotebookListItem);
