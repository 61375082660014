export default (theme) => ({
  listItem1: {
    "& :before": {
      content: "Hello World",
    },
    "&:hover": {
      color: "red",
    },
  },
});
