import { GET, PUT, DELETE, POST } from "constants/methods";

import { REACT_APP_API_PREFIX } from "config/env";

const service = "interdoc";
const subSections = `${REACT_APP_API_PREFIX}/${service}/subSections`;

export function getSubSections() {
  const url = `${subSections}`;
  return {
    method: GET,
    url,
  };
}

export function getSubSectionById(id) {
  const url = `${subSections}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateSubSection(id, body) {
  const url = `${subSections}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteSubSection(id) {
  const url = `${subSections}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createSubSection(body) {
  const url = `${subSections}`;
  return {
    method: POST,
    url,
    body,
  };
}
