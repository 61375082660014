import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

// renderers
import Text from "../../renderers/Text";

import { copyTextToClipboard } from "helpers/url";

// styles
import styles from "./styles";

class Hide extends Component {
  static propTypes = {
    text: PropTypes.string,
    classes: PropTypes.object,
  };

  state = {
    hidden: true,
  };

  render() {
    const { text, classes } = this.props;
    const { hidden } = this.state;

    return (
      <Tooltip title={hidden ? "Reveal and copy to clipboard" : "Hide"}>
        <span
          className={hidden ? classes.hidden : classes.visible}
          onClick={() => {
            this.setState((prevState) => ({
              hidden: !prevState.hidden,
            }));
            copyTextToClipboard(text);
          }}
        >
          <Text>{text}</Text>
        </span>
      </Tooltip>
    );
  }
}

export default withStyles(styles)(Hide);
