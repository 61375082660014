import { networkAction } from "helpers/network/networkAction";

import {
  GET_SUB_SECTIONS,
  GET_SUB_SECTION_BY_ID,
  DELETE_SUB_SECTION,
  UPDATE_SUB_SECTION,
  CREATE_SUB_SECTION,
} from "constants/subSections";

import * as Api from "api";

export const getSubSections = () => async (dispatch) =>
  networkAction(dispatch, GET_SUB_SECTIONS, Api.getSubSections, []);

export const getSubSectionById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_SUB_SECTION_BY_ID, Api.getSubSectionById, [id]);

export const deleteSubSection = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_SUB_SECTION, Api.deleteSubSection, [id]);

export const createSubSection = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_SUB_SECTION, Api.createSubSection, [body]);

export const updateSubSection = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_SUB_SECTION, Api.updateSubSection, [id, body]);
