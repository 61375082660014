import hljs from "highlight.js/lib/highlight";

// Lets only register javascript, scss, html/xml
hljs.registerLanguage(
  "javascript",
  require("highlight.js/lib/languages/javascript")
);
hljs.registerLanguage("css", require("highlight.js/lib/languages/css"));
hljs.registerLanguage(
  "htmlbars",
  require("highlight.js/lib/languages/htmlbars")
);
hljs.registerLanguage("go", require("highlight.js/lib/languages/go"));
hljs.registerLanguage("bash", require("highlight.js/lib/languages/bash"));
hljs.registerLanguage("json", require("highlight.js/lib/languages/json"));
hljs.registerLanguage(
  "markdown",
  require("highlight.js/lib/languages/markdown")
);
hljs.registerLanguage("ruby", require("highlight.js/lib/languages/ruby"));
hljs.registerLanguage("yaml", require("highlight.js/lib/languages/yaml"));
hljs.registerLanguage("dart", require("highlight.js/lib/languages/dart"));

export default hljs;
