import React, { Component } from "react";
import PropTypes from "prop-types";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
import Info from "@material-ui/icons/Info";
import Grain from "@material-ui/icons/Grain";

import TreeSubSections from "components/TreeSubSections";

// styles
import styles from "./styles";

class NoteMenu extends Component {
  static propTypes = {
    classes: PropTypes.object,
    selectedSubSection: PropTypes.object,
    updateSection: PropTypes.func,
    updateSubSection: PropTypes.func,
    onSelect: PropTypes.func,
    onEdit: PropTypes.func,
    refresh: PropTypes.func,
    onAdd: PropTypes.func,
    note: PropTypes.object,
    edit: PropTypes.bool,
  };

  constructor(...args) {
    super(...args);
    const { note } = this.props;
    this.state = {
      sections: note.sections,
      orderChanged: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.edit !== nextProps.edit) {
      return true;
    }
    if (
      this.props.selectedSubSection &&
      nextProps.selectedSubSection &&
      this.props.selectedSubSection.id !== nextProps.selectedSubSection.id
    ) {
      return true;
    }
    if (nextState.orderChanged) {
      return true;
    }
    if (
      this.props.note.refreshKey &&
      this.props.note.refreshKey &&
      this.props.note.refreshKey !== nextProps.note.refreshKey
    ) {
      this.state.sections = nextProps.note.sections; //eslint-disable-line
      return true;
    }
    return false;
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { updateSection } = this.props;
    const sectionToUpdate = this.state.sections[oldIndex];
    updateSection(sectionToUpdate.id, { order: newIndex });
    this.setState(({ sections }) => ({
      sections: arrayMove(sections, oldIndex, newIndex),
      orderChanged: true,
    }));
    setTimeout(() => this.setState({ orderChanged: false }), 200);
  };

  shouldCancelStart = (e) => {
    console.log(e.target.tagName);
    if (e.target.tagName === "P") {
      return true; // Return true to cancel sorting
    }
  };

  render() {
    const {
      classes,
      onSelect,
      refresh,
      onAdd,
      onEdit,
      edit,
      selectedSubSection,
      updateSubSection,
    } = this.props;

    const { sections } = this.state;
    const allNodeIds = [];
    for (const k in sections) {
      if (sections.hasOwnProperty(k)) {
        const section = sections[k];
        allNodeIds.push(`section_${section.id}`);
      }
    }

    const SortableItem = SortableElement((g) => (
      <React.Fragment>
        {g.value.subSections ? (
          <TreeItem
            nodeId={`section_${g.value.id}`}
            label={g.value.title}
            icon={
              edit ? (
                <Grain style={{ fontSize: 14, color: "rgb(155,155,155)" }} />
              ) : undefined
            }
            classes={{
              root: classes.noFocus,
              label:
                g.value.id === selectedSubSection.sectionID
                  ? classes.selectedSectionLabel
                  : classes.sectionLabel,
            }}
            onDoubleClick={edit ? () => onEdit("section", g.value) : undefined}
          >
            <TreeSubSections
              subSections={g.value.subSections}
              selectedSubSection={selectedSubSection}
              updateSubSection={updateSubSection}
              refresh={refresh}
              onSelect={onSelect}
              onEdit={onEdit}
              edit={edit}
              key={`subSection_${g.value.id}`}
              i={g.index}
            />
            {edit ? (
              <TreeItem
                nodeId={`new_subsection${g.value.id}`}
                label={"+ Subsection"}
                classes={{
                  root: classes.noFocus,
                  label: classes.add,
                }}
                onClick={() => onAdd("subSection", g.value.id)}
              />
            ) : (
              []
            )}
          </TreeItem>
        ) : (
          <TreeItem
            nodeId={`section_${g.value.id}`}
            label={g.value.title}
            classes={{
              root: classes.noFocus,
              label:
                selectedSubSection &&
                g.value.id === selectedSubSection.sectionID
                  ? classes.selectedSectionLabel
                  : classes.sectionLabel,
            }}
            icon={
              edit ? (
                <Grain style={{ fontSize: 14, color: "rgb(155,155,155)" }} />
              ) : undefined
            }
            onDoubleClick={edit ? () => onEdit("section", g.value) : undefined}
          >
            {edit ? (
              <TreeItem
                nodeId={`new_subsection${g.value.id}`}
                label={"+ Subsection"}
                classes={{
                  root: classes.noFocus,
                  label: classes.add,
                }}
                onClick={() => onAdd("subSection", g.value.id)}
              />
            ) : (
              []
            )}
          </TreeItem>
        )}
      </React.Fragment>
    ));

    const SortableList = SortableContainer(({ items }) => {
      return (
        <div container spacing={16}>
          {items &&
            items.map((value, index) => (
              <SortableItem
                disabled={!edit}
                key={value.id}
                index={index}
                value={value}
              />
            ))}
        </div>
      );
    });

    let defaultExpanded = selectedSubSection
      ? [`section_${selectedSubSection.sectionID}`]
      : [];
    if (edit) defaultExpanded = allNodeIds;

    return (
      <div key={edit && sections.length}>
        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          className={classes.container}
          defaultExpanded={defaultExpanded}
        >
          <SortableList
            axis="y"
            items={sections}
            onSortEnd={this.onSortEnd}
            shouldCancelStart={this.shouldCancelStart}
          />
          {edit ? (
            <TreeItem
              nodeId={"new_section"}
              label={"+ Section"}
              classes={{
                root: classes.noFocus,
                label: classes.add,
              }}
              onClick={() => onAdd("section")}
            />
          ) : (
            []
          )}
        </TreeView>
        {edit ? (
          <div style={{ padding: 20 }}>
            <Typography variant="caption" color="textSecondary">
              <Info style={{ fontSize: 14 }} /> Double click to rename an item.
              Drag and drop on the grip to reorder.
            </Typography>
          </div>
        ) : (
          []
        )}
      </div>
    );
  }
}

export default withStyles(styles)(NoteMenu);
