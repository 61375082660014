export default (theme) => ({
  container: {
    padding: 5,
  },
  sectionLabel: {
    padding: 6,
    fontFamily: "realist, sans-serif",
  },
  sectionLabelSelected: {
    padding: 6,
    fontFamily: "realist, sans-serif",
    color: theme.palette.primary.color[500],
  },
  subSectionLabel: {
    padding: 6,
    fontFamily: "realist, sans-serif",
    fontWeight: 300,
    fontSize: 14,
  },
  subSectionLabelSelected: {
    padding: 6,
    fontFamily: "realist, sans-serif",
    fontWeight: 300,
    fontSize: 14,
    color: theme.palette.primary.color[500],
  },
  noFocus: {
    "& :focus .MuiTreeItem-content": {
      background: "none !important",
    },
    "& :hover .MuiTreeItem-content": {
      background: "none !important",
    },
  },
});
