import React, { Component } from "react";
import PropTypes from "prop-types";
import Centrifuge from "centrifuge";
import SockJS from "sockjs-client";

// material-ui
import { withStyles } from "@material-ui/core/styles";

// constants
import { CENTRIFUGO_URL, CENTRIFUGO_TOKEN } from "config";

// styles
import styles from "./styles";

class Centrifugo extends Component {
  static propTypes = {
    classes: PropTypes.object,
    websocketMessageReceived: PropTypes.func,
    children: PropTypes.node,
  };

  static childContextTypes = {
    WebsocketCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.centrifuge = new Centrifuge(CENTRIFUGO_URL, {
      sockjs: SockJS,
    });
    this.connect();
  }

  getChildContext() {
    return {
      WebsocketCenter: {
        subscribe: this.subscribe.bind(this),
        connect: this.connect.bind(this),
        getInstance: this.getInstance.bind(this),
      },
    };
  }

  getInstance() {
    return this.centrifuge;
  }

  connect() {
    this.centrifuge.setToken(CENTRIFUGO_TOKEN);
    this.centrifuge.connect();
  }

  subscribe(channel, onMessageReceived) {
    const { websocketMessageReceived } = this.props;
    const subscription = this.centrifuge.subscribe(channel, (m) => {
      onMessageReceived(m);
      websocketMessageReceived(m);
    });
    return subscription;
  }

  render() {
    const { children } = this.props;

    return <React.Fragment>{children}</React.Fragment>;
  }
}

export default withStyles(styles)(Centrifugo);
