export default {
  name: "Api",
  description: "Show an API call that contains the method and the URL",
  example: '[[api method="POST" url="/account/signin" ]]',
  attributes: [
    {
      name: "url",
      description: "resource API endpoint",
      type: "String",
      example: "/account/signin",
      required: true,
    },
    {
      name: "method",
      description: "REST method",
      type: "String",
      example: "POST | GET | DELETE | PUT",
      required: true,
    },
  ],
};
