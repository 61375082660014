import { parseShortcode } from "helpers/shortcodes";

export default function shortcodes(options) {
  var startBlock = (options || {}).startBlock || "[[";
  var endBlock = (options || {}).endBlock || "]]";
  if (isRemarkParser(this.Parser)) {
    var parser = this.Parser.prototype;
    parser.blockTokenizers.shortcode = shortcodeTokenizer;
    parser.blockMethods.splice(
      parser.blockMethods.indexOf("html"),
      0,
      "shortcode"
    );
  }
  if (isRemarkCompiler(this.Compiler)) {
    var compiler = this.Compiler.prototype;
    compiler.visitors.shortcode = shortcodeCompiler;
  }

  function locator(value, fromIndex) {
    return value.indexOf(startBlock, fromIndex);
  }

  function shortcodeTokenizer(eat, value, silent) {
    var entireShortcode;
    var innerShortcode;
    var parsedShortcode;
    var endPosition;
    var endBlockPosition;

    if (!value.startsWith(startBlock)) return;

    endBlockPosition = value.indexOf(endBlock, startBlock.length);
    if (endBlockPosition === -1) return;

    endPosition = endBlockPosition + endBlock.length;
    entireShortcode = value.slice(0, endPosition);
    innerShortcode = value.slice(startBlock.length, endBlockPosition);

    parsedShortcode = parseShortcode(innerShortcode);
    // If there is no parsed data, something fishy is up - return nothing.
    if (!parsedShortcode) return;

    /* Exit with true in silent mode after successful parse - never used (yet) */
    /* istanbul ignore if */
    if (silent) {
      return true;
    }

    return eat(entireShortcode)({
      type: "shortcode",
      identifier: parsedShortcode.identifier,
      attributes: parsedShortcode.attributes,
    });
  }
  shortcodeTokenizer.locator = locator;

  function shortcodeCompiler(node) {
    var attributes = "";
    var keys = Object.keys(node.attributes || {});
    if (keys.length > 0) {
      attributes =
        " " +
        keys
          .map(function (key) {
            return key + '="' + node.attributes[key] + '"';
          })
          .join(" ");
    }
    return startBlock + " " + node.identifier + attributes + " " + endBlock;
  }
}

function isRemarkParser(parser) {
  return Boolean(
    parser &&
      parser.prototype &&
      parser.prototype.inlineTokenizers &&
      parser.prototype.inlineTokenizers.break &&
      parser.prototype.inlineTokenizers.break.locator
  );
}

function isRemarkCompiler(compiler) {
  return Boolean(compiler && compiler.prototype);
}
