import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Grain from "@material-ui/icons/Grain";

// styles
import styles from "./styles";

class WrapperItem extends Component {
  static propTypes = {
    children: PropTypes.any,
    disabled: PropTypes.bool,
  };

  state = {
    hover: false,
  };

  render() {
    const { children, disabled } = this.props;
    const { hover } = this.state;

    return (
      <div
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        style={{ display: "flex", alignItems: "center" }}
      >
        <Grain
          style={{
            fontSize: 14,
            opacity: hover && !disabled ? 1 : 0,
            color: "rgb(155,155,155)",
            cursor: disabled ? undefined : "grab",
          }}
          id="grab"
        />
        <div style={{ flexGrow: 1 }}>{children}</div>
      </div>
    );
  }
}

export default withStyles(styles)(WrapperItem);
