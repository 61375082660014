import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

import { NOTE } from "constants/routes";

// styles
import styles from "./styles";

class NoteSearch extends Component {
  static propTypes = {
    classes: PropTypes.object,
    noteSearch: PropTypes.func,
    history: PropTypes.object,
  };

  state = {
    search: "",
    focused: false,
    loading: false,
  };

  async search() {
    const { noteSearch } = this.props;

    const { search } = this.state;
    const filters = `?filters=[{"name": "content", "comparison": "eq", "value": "${search}"}]`;

    this.setState({ loading: true });
    const resp = await noteSearch(filters);

    this.setState({
      response: resp.payload,
      loading: false,
    });
  }

  handleChange(e) {
    const search = e.target.value;
    if (search.length >= 4) {
      this.search();
    }

    this.setState({ search });
  }

  render() {
    const { classes } = this.props;
    const { search, focused, loading, response } = this.state;

    const list = (
      <div>
        {loading ? (
          <div style={{ padding: 20, textAlign: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <List component="nav">
            {response && response.length === 0 ? (
              <ListItem>
                <ListItemText>No Result</ListItemText>
              </ListItem>
            ) : (
              <div />
            )}
            {response &&
              response.map((item) => (
                <div
                  key={`${item.noteID}_${item.sectionID}_${item.subSectionID}`}
                >
                  <ListItem
                    button
                    onClick={() =>
                      window.location.replace(
                        `${NOTE}?bookmark=${item.noteID}.${item.subSectionID}`
                      )
                    }
                  >
                    <Grid container justify="space-between">
                      <Grid item>
                        <Typography variant="h6">{item.noteName}</Typography>
                        <Breadcrumbs separator="›" aria-label="breadcrumb">
                          <Typography variant="caption" color="textSecondary">
                            {item.sectionName}
                          </Typography>
                          <Typography variant="caption" color="textSecondary">
                            {item.subSectionName}
                          </Typography>
                        </Breadcrumbs>
                      </Grid>
                      <Grid item>
                        <Chip label={`v${item.noteVersion}`} />
                      </Grid>
                      <Grid item xs={12}>
                        <div className={classes.preview}>
                          <Typography variant="caption">
                            {item.contentPreview}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <Divider />
                </div>
              ))}
          </List>
        )}
      </div>
    );

    return (
      <ClickAwayListener onClickAway={() => this.setState({ focused: false })}>
        <div style={{ position: "relative" }}>
          <Paper className={classes.search}>
            <InputBase
              className={classes.input}
              placeholder="Search on Interdoc"
              inputProps={{ "aria-label": "search interdoc" }}
              onFocus={() => this.setState({ focused: true })}
              value={search}
              onChange={this.handleChange.bind(this)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  this.search();
                }
              }}
            />
            <IconButton
              aria-label="search"
              className={classes.iconButton}
              color="inherit"
            >
              <SearchIcon />
            </IconButton>
          </Paper>
          <div className={classes.result}>
            <Hidden xsDown>
              {(focused && search.length >= 4) ||
              (focused && response && response.length) ||
              loading ? (
                <Paper
                  elevation={24}
                  style={{
                    position: "absolute",
                    maxWidth: 600,
                    minWidth: 350,
                    width: "100%",
                    zIndex: 999999,
                    maxHeight: "80vh",
                    overflow: "scroll",
                  }}
                >
                  {list}
                </Paper>
              ) : (
                <div />
              )}
            </Hidden>
            <Hidden smUp>
              {(focused && search.length >= 4) ||
              (focused && response && response.length) ||
              loading ? (
                <Paper
                  elevation={24}
                  style={{
                    position: "fixed",
                    margin: "auto",
                    left: 0,
                    maxWidth: 600,
                    width: "100vw",
                    zIndex: 999999,
                    maxHeight: "80vh",
                    overflow: "scroll",
                  }}
                >
                  {list}
                </Paper>
              ) : (
                <div />
              )}
            </Hidden>
          </div>
        </div>
      </ClickAwayListener>
    );
  }
}

export default withStyles(styles)(NoteSearch);
