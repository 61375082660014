export const EDITOR = "editor";
export const USER = "user";
export const READER = "reader";
export const roles = [EDITOR, USER, READER];
export const roleNames = {
  EDITOR: "Editor",
  USER: "User",
  READER: "Reader",
};
export const roleDescriptions = {
  [EDITOR]:
    "You are an Editor for this Notebook. It means that you can read, interact and edit Notes. You can also manage Signatories and Users.",
  [USER]:
    "You are a User for this Notebook. It means that you can read and interact with Notes.",
  [READER]:
    "You are a Reader for this Notebook. It means that you can only read Notes without any interaction.",
};
