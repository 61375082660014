import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

// custom
import InlineCode from "../../renderers/InlineCode";

// styles
import styles from "./styles";

class Api extends Component {
  static propTypes = {
    method: PropTypes.string,
    url: PropTypes.string,
  };

  render() {
    const { method, url } = this.props;

    const methodLC = method.toLowerCase();

    let background = "#2196f3";
    if (methodLC === "put") background = "#009688";
    if (methodLC === "post") background = "#ff9800";
    if (methodLC === "del" || methodLC === "delete") background = "#f44336";

    return (
      <div style={{ marginBottom: 10 }}>
        <Grid
          container
          spacing={1}
          alignItems="center"
          style={{
            // background: 'rgba(155,155,155,0.1)',
            // border: 'solid 1px rgba(155,155,155,0.3)',
            fontSize: 14,
            fontWeight: 500,
          }}
        >
          <Grid item>
            <Button
              style={{
                background,
                color: "white",
                padding: 0,
              }}
              size="small"
            >
              {method}
            </Button>
          </Grid>
          <Grid
            item
            style={{
              textAlign: "center",
            }}
          >
            <InlineCode>{url}</InlineCode>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(Api);
