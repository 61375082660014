import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// actions
import * as Actions from "actions";

// helpers
import { getJsonFromUrl } from "helpers";

// component
import WrapperMyAccountPage from "../component/WrapperMyAccountPage";

const mapStateToProps = (state) => ({
  user: state.user,
  userApps: state.userApps,
  accessTokens: state.accessTokens,
});

const mapDispatchToProps = (dispatch) => ({
  getCurrentUser: (...args) => dispatch(Actions.getCurrentUser(...args)),
  updateUser: (...args) => dispatch(Actions.updateUser(...args)),
  updateUserPassword: (...args) =>
    dispatch(Actions.updateUserPassword(...args)),
  getOrganizationById: (...args) =>
    dispatch(Actions.getOrganizationById(...args)),
  createOrganization: (...args) =>
    dispatch(Actions.createOrganization(...args)),
  deleteOrganization: (...args) =>
    dispatch(Actions.deleteOrganization(...args)),
  deleteOrganizationUser: (...args) =>
    dispatch(Actions.deleteOrganizationUser(...args)),
  updateOrganization: (...args) =>
    dispatch(Actions.updateOrganization(...args)),
  getOrganizationUsers: (...args) =>
    dispatch(Actions.getOrganizationUsers(...args)),
  addUserToOrganization: (...args) =>
    dispatch(Actions.addUserToOrganization(...args)),
  joinOrganization: (...args) => dispatch(Actions.joinOrganization(...args)),
});
class MyAccountPage extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    userApps: PropTypes.array,
    getCurrentUser: PropTypes.func,
    updateUser: PropTypes.func,
    updateUserPassword: PropTypes.func,
    history: PropTypes.object,
    location: PropTypes.object,

    getOrganizations: PropTypes.func,
    getOrganizationById: PropTypes.func,
    createOrganization: PropTypes.func,
    deleteOrganization: PropTypes.func,
    updateOrganization: PropTypes.func,
    getOrganizationUsers: PropTypes.func,
    addUserToOrganization: PropTypes.func,
    deleteOrganizationUser: PropTypes.func,
    joinOrganization: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
    };
  }

  componentDidMount() {
    this.refresh();
  }

  refresh() {
    const { getCurrentUser } = this.props;

    return Promise.all([getCurrentUser()]);
  }

  render() {
    const { urlParams } = this.state;

    const {
      user,
      history,
      updateUser,
      updateUserPassword,
      userApps,
      getOrganizationById,
      createOrganization,
      deleteOrganization,
      updateOrganization,
      getOrganizationUsers,
      deleteOrganizationUser,
      addUserToOrganization,
      joinOrganization,
    } = this.props;

    return (
      <WrapperMyAccountPage
        history={history}
        refresh={this.refresh.bind(this)}
        user={user}
        updateUser={updateUser}
        updateUserPassword={updateUserPassword}
        urlParams={urlParams}
        userApps={userApps}
        organizations={user.organizations}
        getOrganizationById={getOrganizationById}
        createOrganization={createOrganization}
        deleteOrganization={deleteOrganization}
        updateOrganization={updateOrganization}
        getOrganizationUsers={getOrganizationUsers}
        deleteOrganizationUser={deleteOrganizationUser}
        addUserToOrganization={addUserToOrganization}
        joinOrganization={joinOrganization}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(MyAccountPage);
