import { Divider, InputBase, Typography } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Portal from "@material-ui/core/Portal";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

const colors = {
  must: "#2196f3",
  should: "#3f51b5",
  could: "#673ab7",
  wont: "#f44336",
  "won't": "#f44336",
};

class Kb extends Component {
  static propTypes = {
    title: PropTypes.string,
    problem: PropTypes.string,
    resolution: PropTypes.string,
    tags: PropTypes.string,
    onDelete: PropTypes.func,
    onAddShortcode: PropTypes.func,
    last: PropTypes.bool,
    id: PropTypes.number,
    onShortcodeChange: PropTypes.func,
  };

  static contextTypes = {
    Wrapper: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { title, problem, resolution, tags } = this.props;

    this.state = {
      focusedInput: null,
      priorityAnchor: null,
      title,
      problem,
      resolution,
      tags,
      loaded: false,
      edit: false,
    };
  }

  componentDidMount() {
    const { loaded } = this.state;
    if (!loaded) {
      this.setState({ loaded: true });
    }
  }

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  footer() {
    const { id, onAddShortcode } = this.props;
    if (!onAddShortcode) return [];
    return (
      <Grid container justify="flex-end" style={{ padding: "8px 0px 8px 0px" }}>
        <Grid item>
          <Chip
            label="+ Add New"
            variant="outlined"
            onClick={() =>
              onAddShortcode(
                id,
                `[[kb\n\tid="${new Date().getTime()}"\n\ttags=""\n\tproblem=""\n\tresolution=""\n\ttitle=""\n]]`
              )
            }
          />
        </Grid>
      </Grid>
    );
  }

  render() {
    const { last, onShortcodeChange, id, theme } = this.props;

    const { Wrapper } = this.context;

    const { loaded, problem, resolution, tags, title } = this.state;

    const primaryColor = theme.palette.primary.color[600];

    return (
      <div
        style={{
          margin: "3px 0px 30px 0px",
          borderLeft: "solid 4px grey",
          paddingLeft: 20,
        }}
      >
        <Typography
          variant="h6"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <InputBase
            value={title}
            fullWidth
            placeholder="Title"
            style={{ fontSize: 22 }}
            onChange={this.handleChange("title")}
            onBlur={() => {
              if (onShortcodeChange && title !== this.props.title) {
                onShortcodeChange(id, { title });
              }
            }}
          />
        </Typography>
        <Divider />
        <Typography variant="overline" color="textSecondary">
          <b>Problem </b>
        </Typography>
        <Typography variant="body2">
          <InputBase
            value={problem}
            fullWidth
            placeholder="Explain the problem..."
            style={{ color: primaryColor }}
            onChange={this.handleChange("problem")}
            onBlur={() => {
              if (onShortcodeChange && problem !== this.props.problem) {
                onShortcodeChange(id, { problem });
              }
            }}
          />
        </Typography>
        <Typography variant="overline" color="textSecondary">
          <b>Resolution</b>
        </Typography>
        <Typography variant="body2">
          <InputBase
            value={resolution}
            fullWidth
            style={{ color: primaryColor }}
            placeholder="Explain the resolution..."
            onChange={this.handleChange("resolution")}
            onBlur={() => {
              if (onShortcodeChange && resolution !== this.props.resolution) {
                onShortcodeChange(id, { resolution });
              }
            }}
          />
        </Typography>
        <Typography variant="caption" color="textSecondary">
          <InputBase
            value={tags}
            fullWidth
            style={{ fontSize: 10 }}
            placeholder="Tags..."
            onChange={this.handleChange("tags")}
            onBlur={() => {
              if (onShortcodeChange && tags !== this.props.tags) {
                onShortcodeChange(id, { tags });
              }
            }}
          />
        </Typography>
        <br />
        {loaded ? (
          <React.Fragment>
            {last ? (
              <Portal container={Wrapper.getFooterPortal()}>
                {this.footer()}
              </Portal>
            ) : (
              []
            )}
          </React.Fragment>
        ) : (
          []
        )}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Kb);
