export default {
  name: "Moscow",
  description: "Show a feature request using the Moscow syntax",
  example:
    '[[moscow feature="Solve issue #65" priority="must" reference="1.1" completed="false"]]',
  attributes: [
    {
      name: "feature",
      description: "Description of the feature",
      type: "String",
      example:
        "As a Interdoc user, I want to be able to download a note in PDF Format.",
      required: true,
    },
    {
      name: "priority",
      description:
        "Priority of the method (https://en.wikipedia.org/wiki/MoSCoW_method)",
      type: "String",
      example: "must | should | chould | won't",
      required: true,
    },
    {
      name: "reference",
      description: "Feature's Reference",
      type: "String",
      example: "1.1",
      required: false,
    },
    {
      name: "completed",
      description: "Feature has been implemented",
      type: "Bool",
      example: "true",
      required: true,
    },
  ],
};
