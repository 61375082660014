import React, { Component } from "react";

// material-ui
import { withStyles } from "@material-ui/core/styles";

// styles
import styles from "./styles";

class WrapperDefaultPage extends Component {
  render() {
    return <div />;
  }
}
export default withStyles(styles)(WrapperDefaultPage);
