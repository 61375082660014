import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";

// styles
import styles from "./styles";

class ListItem extends Component {
  static propTypes = {
    children: PropTypes.any,
    index: PropTypes.number,
    ordered: PropTypes.bool,
    checked: PropTypes.bool,
    checkboxId: PropTypes.number,
    onCheck: PropTypes.func,
  };

  render() {
    const {
      children,
      index,
      ordered,
      checked,
      checkboxId,
      onCheck,
    } = this.props;

    if (checked !== null) {
      return (
        <li>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ paddingRight: 4 }}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                defaultChecked={checked}
                onChange={(e) => onCheck(checkboxId, e.target.checked)}
                disabled={!onCheck}
                color="primary"
                style={{ padding: 5 }}
              />
            </div>
            <div>{children}</div>
          </div>
        </li>
      );
    }

    return (
      <li>
        <div style={{ display: "flex" }}>
          <div style={{ paddingRight: 4 }}>
            {ordered ? `${index + 1}. ` : <b>∙</b>}
          </div>
          <div>{children}</div>
        </div>
      </li>
    );
  }
}

export default withStyles(styles)(ListItem);
