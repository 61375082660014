export default (theme) => ({
  appGrid: {
    height: 47,
  },
  AppBarGreetings: {
    color: "white",
    padding: 12,
  },
  white: {
    color: "white",
  },
});
