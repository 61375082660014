export function getCookie(cookiename) {
  // Get name followed by anything except a semicolon
  const cookiestring = RegExp(`${cookiename}[^;]+`).exec(document.cookie);

  // Return everything after the equal sign, or an empty string if the cookie name not found
  const ret = decodeURIComponent(
    cookiestring ? cookiestring.toString().replace(/^[^=]+./, "") : ""
  );
  return ret;
}

export function setCookie(cookies, key, value) {
  cookies.set(key, value, { path: "/" });
}
