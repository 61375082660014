// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TreeItem from "@material-ui/lab/TreeItem";
import TreeView from "@material-ui/lab/TreeView";
import PropTypes from "prop-types";
import React, { Component } from "react";
import markdown from "remark-parse";
import unified from "unified";
// styles
import styles from "./styles";

class ContentSummary extends Component {
  static propTypes = {
    classes: PropTypes.object,
    source: PropTypes.string,
    depth: PropTypes.number,
    onSelect: PropTypes.func,
    expanded: PropTypes.bool,
    visibleAnchor: PropTypes.string,
  };

  static defaultProps = {
    depth: 3,
  };

  constructor(...args) {
    super(...args);
    const { source } = this.props;
    this.state = {
      parse: this.parse(source),
    };
  }

  getTreeItems(node) {
    const { classes, onSelect, visibleAnchor } = this.props;
    let labelClass;
    if (node.depth === 1) {
      if (visibleAnchor === node.anchor) {
        labelClass = classes.sectionLabelSelected;
      } else {
        labelClass = classes.sectionLabel;
      }
    } else {
      if (visibleAnchor === node.anchor) {
        labelClass = classes.subSectionLabelSelected;
      } else {
        labelClass = classes.subSectionLabel;
      }
    }

    if (node.children.length === 0) {
      return (
        <TreeItem
          nodeId={node.anchor}
          key={node.anchor}
          label={node.title}
          classes={{
            root: classes.noFocus,
            label: labelClass,
          }}
          onClick={onSelect(node.anchor)}
        />
      );
    }
    return (
      <TreeItem
        nodeId={node.anchor}
        key={node.anchor}
        label={node.title}
        style={{
          color: visibleAnchor === node.anchor ? "#2196f3" : undefined,
        }}
        classes={{
          root: classes.noFocus,
          label: labelClass,
        }}
      >
        {node.children.map((t) => this.getTreeItems(t))}
      </TreeItem>
    );
  }

  getAnchor(title) {
    return `${title}`.toLowerCase().replace(/ /g, "-");
  }

  getSummary() {
    const { classes, expanded } = this.props;
    const { parse } = this.state;
    const { tree, anchors } = parse;
    return (
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        className={classes.container}
        defaultExpanded={expanded ? anchors : []}
      >
        {tree.map((t) => this.getTreeItems(t))}
      </TreeView>
    );
  }

  parse(source) {
    const { depth } = this.props;
    const processor = unified()
      .use(markdown)
      .parse(source);

    const { children } = processor;
    const headings = children.filter(
      (c) => c.type === "heading" && c.depth <= depth
    );
    const tree = [];
    const anchors = [];
    const depthMap = {};
    let tmpNode;

    for (const key in headings) {
      if (headings.hasOwnProperty(key)) {
        const heading = headings[key];
        const title = heading.children[0] ? heading.children[0].value : "";
        const anchor = this.getAnchor(title);
        const node = {
          title,
          anchor,
          depth: heading.depth,
          children: [],
        };
        anchors.push(anchor);
        if (tree.length === 0) {
          // first node
          tree.push(node);
          depthMap[1] = tree;
          tmpNode = node;
        } else if (heading.depth === tmpNode.depth) {
          // same level
          if (depthMap[heading.depth]) {
            depthMap[heading.depth] && depthMap[heading.depth].push(node);
          }
          tmpNode = node;
        } else if (heading.depth > tmpNode.depth) {
          // upper level
          tmpNode.children.push(node);
          depthMap[heading.depth] = tmpNode.children;
          tmpNode = node;
        } else if (heading.depth < tmpNode.depth) {
          // lower level
          depthMap[heading.depth] && depthMap[heading.depth].push(node);
          tmpNode = node;
        }
      }
    }

    return { tree, anchors };
  }

  render() {
    const { classes, source } = this.props;

    return (
      <div className={classes.container}>
        <Typography style={{ fontFamily: "realist, sans-serif" }}>
          Content
        </Typography>
        {this.getSummary(source)}
      </div>
    );
  }
}

export default withStyles(styles)(ContentSummary);
