import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

// styles
import styles from "./styles";

class SubSection extends Component {
  static propTypes = {
    classes: PropTypes.object,
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        <Typography variant="body1">
          <span role="img" aria-label="Cat">
            🐱
          </span>{" "}
          Miaou !
        </Typography>
      </div>
    );
  }
}

export default withStyles(styles)(SubSection);
