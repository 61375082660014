import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Close from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Copy from "@material-ui/icons/AddToPhotosTwoTone";

// helpers
import { getJsonFromUrl } from "helpers";
import { copyTextToClipboard } from "helpers/url";

// styles
import styles from "./styles";

class SignNote extends Component {
  static propTypes = {
    classes: PropTypes.object,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    note: PropTypes.object,
  };

  render() {
    const { onClose, classes, open, note } = this.props;
    const urlParams = getJsonFromUrl(window.location);
    let href = window.location.href;
    if (urlParams.token === undefined) {
      href = `${window.location.origin}${window.location.pathname}?bookmark=${
        urlParams.bookmark
      }&token=${note && note.tagID}`;
    }

    return (
      <Dialog
        onClose={onClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        style={{
          textAlign: "center",
        }}
      >
        <DialogTitle id="simple-dialog-title">
          <img
            src="https://storage.googleapis.com/wid-arranger/interdoc/e7042762-f05d-43b0-875b-b6209bc2261e/study.png"
            alt="study"
          />
          <br />
          Share the note
        </DialogTitle>
        <DialogContent>
          <Typography variant="caption" color="textSecondary">
            Anyone on the internet with the link can <b>read</b> the note. No
            authentication required.
          </Typography>
          <Grid
            style={{
              border: "solid 1px rgba(155,155,155,0.3)",
              background: "rgba(155,155,155,0.1)",
              margin: 4,
              padding: 4,
            }}
            container
            alignItems="center"
            spacing={1}
          >
            <Grid item style={{ width: "calc(100% - 40px)" }}>
              {href}
            </Grid>
            <Grid item style={{ width: 30 }}>
              <Tooltip title="Copy to Clipboard">
                <IconButton
                  style={{
                    padding: 5,
                  }}
                  onClick={() => {
                    copyTextToClipboard(href);
                  }}
                >
                  <Copy style={{ fontSize: 18 }} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            className={classes.close}
            onClick={onClose}
          >
            <Close className={classes.leftIcon} />
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(SignNote);
