export default {
  name: "Download",
  description: "Show a Download badge link to a downloadable document",
  example: '[[download url="https://muse.mu/albums/simulation-theory.zip"]]',
  attributes: [
    {
      name: "url",
      description: "URL of the file",
      type: "String",
      example: "https://muse.mu/albums/simulation-theory.zip",
      required: true,
    },
    {
      name: "name",
      description: "Name of the file",
      type: "String",
      example: "Simulation Theory",
      required: false,
    },
  ],
};
