import { networkAction } from "helpers/network/networkAction";

import {
  GET_SECTIONS,
  GET_SECTION_BY_ID,
  DELETE_SECTION,
  UPDATE_SECTION,
  CREATE_SECTION,
} from "constants/sections";

import * as Api from "api";

export const getSections = () => async (dispatch) =>
  networkAction(dispatch, GET_SECTIONS, Api.getSections, []);

export const getSectionById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_SECTION_BY_ID, Api.getSectionById, [id]);

export const deleteSection = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_SECTION, Api.deleteSection, [id]);

export const createSection = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_SECTION, Api.createSection, [body]);

export const updateSection = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_SECTION, Api.updateSection, [id, body]);
