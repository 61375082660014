import React, { Component } from "react";
import PropTypes from "prop-types";

// reactor
import Page from "components/Page";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

// styles
import styles from "./styles";

class WrapperDefaultPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
  };

  render() {
    const { classes } = this.props;
    return (
      <Page helmet="Page not found">
        <Grid
          container
          alignItems="center"
          justify="center"
          className={classes.container}
        >
          <Grid item style={{ textAlign: "center" }}>
            <Typography variant="h1">
              <span role="img" aria-label="duno">
                💁🏻‍♂️
              </span>
            </Typography>
            <Typography variant="h2" style={{ fontWeight: 600 }}>
              Oooooops !
            </Typography>
            <Typography variant="h6">Resource not found.</Typography>
            <br />
            <Typography>The intern must have lost it again !!!</Typography>
          </Grid>
        </Grid>
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperDefaultPage);
