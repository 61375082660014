export default (theme) => ({
  inlineCode: {
    fontFamily: "source-code-pro, monospace;",
    fontSize: 12,
    background: "rgba(155, 155, 155, 0.1)",
    border: "solid 1px rgba(155, 155, 155, 0.3)",
    borderRadius: 4,
    padding: "0px 2px 0px 2px",
  },
});
