export default {
  name: "Repo",
  description: "Show a Gitlab badge that redirects you to a repo",
  example: '[[repo url="https://git.wid.la/wa/interdoc-v2-frontend/issues/9"]]',
  attributes: [
    {
      name: "url",
      description: "URL of the gitlab repo",
      type: "String",
      example: "https://git.wid.la/wa/interdoc-v2-frontend/issues/9",
      required: true,
    },
  ],
};
