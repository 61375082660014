const shortcodeRegexp = /\[\[[a-zA-Z]+(( |\n|\t|)*([a-zA-Z]+="[^"]*"))*(\n|)\]\]/gm;

export function getShortcode(content, id) {
  const regexp = shortcodeRegexp;
  let myArray; // eslint-disable-line
  const matches = content.match(regexp);
  return matches.find((i) => i.indexOf(`${id}`) !== -1);
}

export function getShortcodesInString(content) {
  const regexp = shortcodeRegexp;
  let myArray; // eslint-disable-line
  const matches = content.match(regexp);
  return matches;
}

export function replaceShortcodeAttribute(shortcode, attributeName, newValue) {
  let newShortcode = `${shortcode}`;
  const regexp = new RegExp(`${attributeName}="[^"]*"`, "gm");
  if (regexp.test(shortcode)) {
    newShortcode = newShortcode.replace(
      regexp,
      `${attributeName}="${newValue}"`
    );
  } else {
    // attribute does not
    newShortcode = `${shortcode.substring(
      0,
      shortcode.length - 2
    )}\t${attributeName}="${newValue}"\n]]`;
  }

  return newShortcode;
}

export function parseShortcode(innerShortcode) {
  // we remove line breaks to get the shortcode in one line
  var oneLineShortcode = innerShortcode.replace(/\n/g, "");
  var trimmedInnerShortcode = oneLineShortcode.trim();

  // If no shortcode, it was blank between the blocks - return nothing.
  if (!trimmedInnerShortcode) return;

  // If no whitespace, then shortcode is just name with no attributes.
  if (!hasWhiteSpace(trimmedInnerShortcode)) {
    return { identifier: trimmedInnerShortcode, attributes: {} };
  }

  var splitShortcode = trimmedInnerShortcode.match(/^(\S+)\s(.*)/).slice(1);
  var shortcodeName = splitShortcode[0];
  var attributeString = splitShortcode[1];
  var attributes = parseShortcodeAttributes(attributeString);

  // If no attributes parsed, something went wrong - return nothing.
  if (!attributes) return;

  return {
    identifier: shortcodeName,
    attributes: attributes,
  };
}

export function parseShortcodeAttributes(attributeString) {
  var attributes = {};
  var attrMatch = attributeString
    .trim()
    .match(/(?:[\w-]*=\s*(?:(?:"[^"]*")|(?:'[^']*')|[^}\s]+))/g);

  if (attrMatch) {
    attrMatch.map((item) => {
      //eslint-disable-line
      var split = item.split("=");
      var key = split.shift().trim();
      // Strip surrounding quotes from value, if they exist.
      var val = split
        .join("=")
        .trim()
        .replace(/^"(.*)"$/, "$1");
      attributes[key] = val;
    });
  }
  return attributes;
}

export function parseShortcodeIdentifier(shortcode) {
  return "task";
}

export function hasWhiteSpace(s) {
  return /\s/g.test(s);
}
