import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Popover from "@material-ui/core/Popover";
import Portal from "@material-ui/core/Portal";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Close from "@material-ui/icons/Close";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { CirclePicker } from "react-color";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "./datePicker.css";
import Gitlab from "./gitlab.svg";
// styles
import styles from "./styles";

const colors = {
  done: "#4caf50",
  stuck: "#f44336",
  "working on it": "#2196f3",
  "to review": "#009688",
  "not started": "#9e9e9e",
};

class Task extends Component {
  static propTypes = {
    toDo: PropTypes.string,
    first: PropTypes.bool,
    last: PropTypes.bool,
    id: PropTypes.number,
    classes: PropTypes.object,
    onShortcodeChange: PropTypes.func,
    onAddShortcode: PropTypes.func,
    onDelete: PropTypes.func,
    owner: PropTypes.string,
    status: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    color: PropTypes.string,
    url: PropTypes.string,
  };

  static contextTypes = {
    Wrapper: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { startDate, endDate, toDo, owner, color, url } = this.props;

    this.state = {
      startDate: startDate && startDate.length ? moment(startDate) : null,
      endDate: endDate && endDate.length ? moment(endDate) : null,
      focusedInput: null,
      colorAnchor: null,
      displayColorPicker: false,
      toDo,
      owner,
      color: color || "#2196f3",
      url,
      loaded: false,
    };
  }

  componentDidMount() {
    const { loaded } = this.state;
    if (!loaded) {
      this.setState({ loaded: true });
    }
  }

  componentWillUnmount() {
    const { toDo } = this.props;
    console.log(`${toDo} Unmounts`);
  }

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  header() {
    return (
      <div style={{ display: "flex", width: "100%" }}>
        <div
          style={{
            flex: 2,
            textAlign: "center",
          }}
        >
          <Typography variant="caption" color="textSecondary">
            Task
          </Typography>
        </div>
        <div
          style={{
            flex: 1,
            textAlign: "center",
          }}
        >
          <Typography variant="caption" color="textSecondary">
            Asignee
          </Typography>
        </div>
        <div
          style={{
            flex: 1,
            textAlign: "center",
          }}
        >
          <Typography variant="caption" color="textSecondary">
            Status
          </Typography>
        </div>
        <div
          style={{
            width: 192,
            textAlign: "center",
          }}
        >
          <Typography variant="caption" color="textSecondary">
            Due Date
          </Typography>
        </div>
      </div>
    );
  }

  footer() {
    const { id, onAddShortcode, color } = this.props;
    if (!onAddShortcode) return [];
    return (
      <Grid container justify="flex-end" style={{ padding: "8px 0px 8px 0px" }}>
        <Grid item>
          <Chip
            label="+ Add New"
            variant="outlined"
            onClick={() =>
              onAddShortcode(
                id,
                `[[task\n\tid="${new Date().getTime()}"\n\towner=""\n\tstatus="Not started"\n\ttoDo=""\n\tcolor="${color}"\n]]`
              )
            }
          />
        </Grid>
      </Grid>
    );
  }

  StatusMenu(props) {
    const { status, id, onShortcodeChange } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);

    function handleClick(event) {
      event.stopPropagation();
      event.preventDefault();
      setAnchorEl(event.currentTarget);
    }

    function handleClose() {
      setAnchorEl(null);
    }

    const statusLC = status.toLowerCase();
    let background = colors[statusLC];
    if (background === undefined) background = "#9e9e9e";

    const menuObj = Object.assign({}, colors);
    delete menuObj[status];
    const menuOptions = [];
    for (const key in menuObj) {
      if (menuObj.hasOwnProperty(key)) {
        const item = menuObj[key];
        menuOptions.push({
          label: key.charAt(0).toUpperCase() + key.slice(1),
          color: item,
        });
      }
    }

    return (
      <div>
        <Button
          style={{
            background,
            color: "white",
            textTransform: "none",
          }}
          onClick={handleClick}
          size="small"
          disabled={!onShortcodeChange}
        >
          {status}
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {menuOptions.map((o) => (
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleClose();
                onShortcodeChange(id, { status: o.label });
              }}
              key={o.label}
            >
              <Button
                style={{
                  background: o.color,
                  color: "white",
                  textTransform: "none",
                }}
              >
                {o.label}
              </Button>
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  }

  render() {
    const {
      status,
      classes,
      onShortcodeChange,
      onDelete,
      id,
      first,
      last,
    } = this.props;

    const {
      startDate,
      endDate,
      toDo,
      owner,
      color,
      gitlabAnchor,
      colorAnchor,
      url,
      loaded,
    } = this.state;

    const { Wrapper } = this.context;

    const now = moment();
    let duration = "";
    if (startDate && endDate) {
      duration = `${endDate.diff(startDate, "days")}`;
    }
    let label = "";
    let labelClass = "";

    const late = now.diff(endDate, "days");
    const begin = now.diff(startDate, "days");

    if (status.toLowerCase() !== "done") {
      if (late > 0) {
        label = `Late ${late}d`;
        labelClass = classes.late;
      }

      if (late <= 0 && begin >= 0) {
        label = `Due ${Math.abs(late)}d`;
        labelClass = classes.begin;
      }

      if (late < 0 && begin < 0) {
        label = `In ${Math.abs(begin)}d`;
        labelClass = classes.begin;
      }
    }

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          background: "rgba(155,155,155,0.1)",
          border: "solid 1px rgba(155,155,155,0.3)",
          marginTop: -1,
          fontSize: 14,
          fontWeight: 500,
          fontFamily: "realist, sans-serif",
          position: "relative",
        }}
      >
        <Tooltip title={onShortcodeChange ? "Change Color" : ""}>
          <div
            style={{
              width: 5,
              height: "100%",
              background: color,
              cursor: "pointer",
              position: "absolute",
            }}
            onClick={(e) => {
              if (onShortcodeChange) {
                this.setState({
                  colorAnchor: e.currentTarget,
                });
              }
            }}
          />
        </Tooltip>
        <Popover
          onClose={() =>
            this.setState({
              colorAnchor: null,
            })
          }
          open={Boolean(colorAnchor)}
          anchorEl={colorAnchor}
        >
          <div style={{ padding: 10 }}>
            <CirclePicker
              onChange={(c) => {
                this.setState({ color: c.hex, colorAnchor: null });
                onShortcodeChange(id, { color: c.hex });
              }}
            />
          </div>
        </Popover>
        <div
          style={{
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            paddingLeft: 5,
          }}
        >
          <div
            style={{
              flex: 2,
              padding: 4,
              borderRight: "solid 1px rgba(155,155,155,0.3)",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Tooltip title={url ? "Open in Gitlab" : ""}>
              <img // eslint-disable-line
                src={Gitlab}
                style={{
                  height: 14,
                  paddingRight: 4,
                  cursor: "pointer",
                  filter: !url ? "saturate(0%)" : undefined,
                  opacity: url ? 1 : 0.4,
                }}
                onMouseEnter={(e) => {
                  if (onShortcodeChange) {
                    this.setState({ imgHover: true });
                    const { currentTarget } = e;
                    setTimeout(() => {
                      if (this.state.imgHover) {
                        this.setState({ gitlabAnchor: currentTarget });
                      }
                    }, 1000);
                  }
                }}
                onMouseLeave={() => this.setState({ imgHover: false })}
                alt="gitlab"
                onClick={
                  url
                    ? () => window.open(url, "_blank")
                    : (e) => this.setState({ gitlabAnchor: e.currentTarget })
                }
              />
            </Tooltip>
            <Popover
              onClose={() =>
                this.setState({
                  gitlabAnchor: null,
                })
              }
              open={Boolean(gitlabAnchor)}
              anchorEl={gitlabAnchor}
            >
              <div style={{ padding: 10 }}>
                <InputBase
                  value={url}
                  fullWidth
                  placeholder="Gitlab URL"
                  multiline
                  readOnly={!onShortcodeChange}
                  className={classes.inputBase}
                  onChange={this.handleChange("url")}
                  onBlur={() => {
                    if (onShortcodeChange && url !== this.props.url) {
                      onShortcodeChange(id, { url });
                    }
                  }}
                />
              </div>
            </Popover>
            <InputBase
              value={toDo}
              fullWidth
              multiline
              readOnly={!onShortcodeChange}
              className={classes.inputBase}
              onChange={this.handleChange("toDo")}
              onBlur={() => {
                if (onShortcodeChange && toDo !== this.props.toDo) {
                  onShortcodeChange(id, { toDo });
                }
              }}
            />
          </div>
          <div
            style={{
              flex: 1,
              padding: 4,
              textAlign: "center",
              borderRight: "solid 1px rgba(155,155,155,0.3)",
            }}
          >
            <InputBase
              value={owner}
              fullWidth
              readOnly={!onShortcodeChange}
              multiline
              className={classes.inputBase}
              onChange={this.handleChange("owner")}
              onBlur={() => {
                if (onShortcodeChange && owner !== this.props.owner) {
                  onShortcodeChange(id, { owner });
                }
              }}
            />
          </div>
          <div
            style={{
              flex: 1,
              padding: 4,
              borderRight: "solid 1px rgba(155,155,155,0.3)",
              textAlign: "center",
            }}
          >
            <this.StatusMenu
              status={status}
              id={id}
              onShortcodeChange={onShortcodeChange}
            />
          </div>
          <div
            style={{
              padding: 4,
              width: 100,
            }}
          >
            <DateRangePicker
              isOutsideRange={() => false}
              disabled={!onShortcodeChange}
              startDateId="startDate"
              endDateId="endDate"
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              onDatesChange={({ startDate, endDate }) => {
                if (startDate && endDate) {
                  onShortcodeChange(id, {
                    startDate: startDate.format("YYYY-MM-DD"),
                    endDate: endDate.format("YYYY-MM-DD"),
                  });
                }
                this.setState({ startDate, endDate });
              }}
              focusedInput={this.state.focusedInput}
              onFocusChange={(focusedInput) => {
                this.setState({ focusedInput });
              }}
            />
          </div>
          <div
            style={{
              width: 50,
              padding: 4,
            }}
          >
            {startDate && endDate ? (
              <Grid item>
                {duration && duration.length ? `${duration} Days` : "-"}
                <br />
                <span className={labelClass}>{label}</span>
              </Grid>
            ) : (
              <Grid item>
                <Typography
                  color="textSecondary"
                  variant="caption"
                  style={{ fontFamily: "realist, sans-serif" }}
                >
                  Not Set
                </Typography>
              </Grid>
            )}
          </div>
        </div>
        <IconButton
          style={{ padding: 3 }}
          onClick={() => onDelete(id)}
          disabled={!onShortcodeChange}
        >
          <Close style={{ fontSize: 18 }} />
        </IconButton>
        {loaded ? (
          <React.Fragment>
            {first ? (
              <Portal container={Wrapper.getHeaderPortal()}>
                {this.header()}
              </Portal>
            ) : (
              []
            )}
            {last ? (
              <Portal container={Wrapper.getFooterPortal()}>
                {this.footer()}
              </Portal>
            ) : (
              []
            )}
          </React.Fragment>
        ) : (
          []
        )}
      </div>
    );
  }
}

export default withStyles(styles)(Task);
