import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactPlayer from "react-player";

// material-ui
import { withStyles } from "@material-ui/core/styles";

// styles
import styles from "./styles";

class Video extends Component {
  static propTypes = {
    url: PropTypes.string,
  };

  render() {
    const { url } = this.props;

    return (
      <ReactPlayer
        url={url}
        controls
        width={"100%"}
        style={{
          background: undefined,
          width: "100%",
          maxWidth: 600,
        }}
      />
    );
  }
}

export default withStyles(styles)(Video);
