import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

// component
import WrapperNotebooksPage from "../component/WrapperNotebooksPage";

const mapStateToProps = (state) => ({
  users: state.users,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  getNotebooks: (...args) => dispatch(Actions.getNotebooks(...args)),
  getNotes: (...args) => dispatch(Actions.getNotes(...args)),
  createNote: (...args) => dispatch(Actions.createNote(...args)),
  updateNote: (...args) => dispatch(Actions.updateNote(...args)),
  createNotebook: (...args) => dispatch(Actions.createNotebook(...args)),
  deleteNotebook: (...args) => dispatch(Actions.deleteNotebook(...args)),
  updateNotebook: (...args) => dispatch(Actions.updateNotebook(...args)),
  getNotebookUsers: (...args) => dispatch(Actions.getNotebookUsers(...args)),
  createUserNotebook: (...args) =>
    dispatch(Actions.createUserNotebook(...args)),
  updateUserNotebook: (...args) =>
    dispatch(Actions.updateUserNotebook(...args)),
  deleteUserNotebook: (...args) =>
    dispatch(Actions.deleteUserNotebook(...args)),
  getInterdocUsers: (...args) => dispatch(Actions.getInterdocUsers(...args)),
  importNote: (...args) => dispatch(Actions.importNote(...args)),
});
class NotebooksPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    getNotebooks: PropTypes.func,
    createNote: PropTypes.func,
    updateNote: PropTypes.func,
    createNotebook: PropTypes.func,
    deleteNotebook: PropTypes.func,
    updateNotebook: PropTypes.func,

    createUserNotebook: PropTypes.func,
    updateUserNotebook: PropTypes.func,
    deleteUserNotebook: PropTypes.func,

    getInterdocUsers: PropTypes.func,
    importNote: PropTypes.func,
    users: PropTypes.array,
    user: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { location, getInterdocUsers } = this.props;
    const urlParams = getJsonFromUrl(location);
    const notebookID = urlParams.selected;
    this.state = {
      urlParams,
      notebookID,
      loading: true,
    };
    getInterdocUsers();
  }

  componentDidMount() {
    this.refresh();
  }

  onSelect(n) {
    const { history } = this.props;
    this.setState({ selectedNotebook: n, notebookID: n.id });
    history.push({
      search: `?selected=${n.id}`,
    });
  }

  async refresh() {
    const { getNotebooks, getNotebookUsers, getNotes, history } = this.props;
    const { notebookID } = this.state;

    const resps = await Promise.all([getNotes(), getNotebooks()]);
    const notes = resps[0].payload;
    const notebooks = resps[1].payload;
    let selectedNotebook = notebooks[0];

    if (notebookID && notebooks) {
      selectedNotebook = notebooks.find((n) => n.id === Number(notebookID));
    } else if (selectedNotebook) {
      history.push({
        search: `?selected=${selectedNotebook.id}`,
      });
    }

    for (const key in notebooks) {
      if (notebooks.hasOwnProperty(key)) {
        const notebook = notebooks[key];
        notebook.notes = notes.filter((n) => n.notebookID === notebook.id);
        getNotebookUsers(notebook.id).then((r) => {
          const users = r.payload;
          notebook.users = users;
          this.setState({
            notebooks,
          });
        });
      }
    }

    this.setState({
      notebooks,
      refreshKey: new Date().getTime(),
      selectedNotebook,
      loading: false,
    });
  }

  render() {
    const {
      location,
      history,
      createNote,
      createNotebook,
      deleteNotebook,
      updateNotebook,
      updateNote,
      createUserNotebook,
      updateUserNotebook,
      deleteUserNotebook,
      importNote,
      users,
      user,
    } = this.props;

    const {
      urlParams,
      loading,
      notebooks,
      selectedNotebook,
      refreshKey,
    } = this.state;

    return (
      <WrapperNotebooksPage
        history={history}
        location={location}
        urlParams={urlParams}
        notebooks={notebooks}
        selectedNotebook={selectedNotebook}
        createNote={createNote}
        refresh={this.refresh.bind(this)}
        refreshKey={refreshKey}
        updateNote={updateNote}
        onSelect={this.onSelect.bind(this)}
        createNotebook={createNotebook}
        deleteNotebook={deleteNotebook}
        updateNotebook={updateNotebook}
        importNote={importNote}
        createUserNotebook={createUserNotebook}
        updateUserNotebook={updateUserNotebook}
        deleteUserNotebook={deleteUserNotebook}
        users={users}
        signatories={user && user.signatories}
        loading={loading}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(NotebooksPage);
