import React, { Component } from "react";
import PropTypes from "prop-types";
import { CirclePicker } from "react-color";

// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Check";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Close from "@material-ui/icons/Close";

// styles
import styles from "./styles";

class FormNotebook extends Component {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,

    close: PropTypes.func,
    open: PropTypes.bool,

    notebook: PropTypes.object,

    createNotebook: PropTypes.func,
    updateNotebook: PropTypes.func,
    deleteNotebook: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
    UploadCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      name: "",
      icon: "",
      color: "",
      pub: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { UploadCenter } = this.context;
    if (nextProps.open && !this.props.open) {
      this.init(nextProps.notebook);
      UploadCenter.register(
        this.upload.bind(this),
        this.userDidDrop.bind(this),
        () => console.log("upload callback"),
        undefined
      );
    }

    if (!nextProps.open && this.props.open) {
      UploadCenter.disregard();
    }
  }

  handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  handleCheckboxChange = (name) => (event) => {
    const { target } = event;
    const { checked } = target;

    this.setState({
      [name]: checked,
    });
  };

  async upload() {
    const { UploadCenter } = this.context;
    const { files } = this.state;
    for (const key in files) {
      if (files.hasOwnProperty(key)) {
        const file = files[key];
        UploadCenter.upload(file).then((r) => {
          this.setState({
            icon: r.payload,
          });
        });
      }
    }
  }

  userDidDrop(files) {
    this.setState({ files });
  }

  init(notebook) {
    this.setState({
      loading: false,
      name: notebook && notebook.name ? notebook.name : "",
      pub: notebook && notebook.public ? notebook.public : false,
      icon: notebook && notebook.icon ? notebook.icon : "",
      color: notebook && notebook.color ? notebook.color : "",
    });
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle:
          "If you delete this notebook, you won't be able to recover it.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteNotebook, notebook, close, refresh } = this.props;

    const { NotificationCenter } = this.context;

    const resp = await deleteNotebook(notebook.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "Notebook has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      close();
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async save() {
    const {
      updateNotebook,
      createNotebook,
      notebook,
      refresh,
      close,
    } = this.props;

    const { name, pub, icon, color } = this.state;

    const { NotificationCenter } = this.context;

    let resp;
    this.setState({ loading: true });
    if (notebook) {
      resp = await updateNotebook(notebook.id, {
        name,
        icon,
        color,
        public: pub,
      });
    } else {
      resp = await createNotebook({
        name,
        icon,
        color,
        public: pub,
      });
    }
    close();
    refresh();
    this.setState({ loading: false });

    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: notebook
          ? "Notebook has been updated."
          : "Notebook has been created.",
        success: true,
        timestamp: new Date().getTime(),
      });

      setTimeout(NotificationCenter.hide, 2000);
    }
  }

  render() {
    const { classes, fullScreen, open, notebook, close } = this.props;

    const { name, loading, pub, icon, color } = this.state;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        onClose={close}
        aria-labelledby="responsive-dialog-title"
        classes={{
          paper: classes.paper,
        }}
      >
        {loading ? (
          <div className={classes.progressContainer}>
            <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
            <CircularProgress />
          </div>
        ) : (
          <div>
            <DialogTitle id="responsive-dialog-title">
              {notebook ? "Update Notebook" : "Let's Create a New Notebook"}
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={24}>
                <Grid item xs={12}>
                  <TextField
                    id="name"
                    label="Notebook name"
                    className={classes.textField}
                    value={name}
                    onChange={this.handleChange("name")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="icon"
                    label="Icon URL"
                    className={classes.textField}
                    value={icon}
                    onChange={this.handleChange("icon")}
                    helperText="Drag and drop an image to upload"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={pub}
                        onChange={this.handleCheckboxChange("pub")}
                        value="Default"
                      />
                    }
                    label="Public"
                  />
                </Grid>
                <Grid item xs={12}>
                  <CirclePicker
                    color={color}
                    onChange={(c) => {
                      this.setState({
                        color: c.hex,
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              {notebook && (
                <Button
                  className={classes.delete}
                  onClick={this.delete.bind(this)}
                >
                  <DeleteIcon className={classes.leftIcon} />
                  &nbsp;Delete
                </Button>
              )}
              <Button className={classes.cancel} autoFocus onClick={close}>
                <Close className={classes.leftIcon} />
                Close
              </Button>
              <Button
                color="primary"
                className={classes.save}
                autoFocus
                onClick={this.save.bind(this)}
              >
                <SaveIcon className={classes.leftIcon} />
                {notebook ? "Save" : "Create"}
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormNotebook));
