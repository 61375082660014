module.exports = wrappers;

function wrappers(options) {
  // 1st call
  var startBlock = (options || {}).startBlock || "{{wrapper}}";
  var endBlock = (options || {}).endBlock || "{{/wrapper}}";

  if (isRemarkParser(this.Parser)) {
    var parser = this.Parser.prototype;
    parser.blockTokenizers.wrapper = wrapperTokenizer;
    parser.blockMethods.splice(
      parser.blockMethods.indexOf("html"),
      0,
      "wrapper"
    );
  }

  function locator(value, fromIndex) {
    return value.indexOf(startBlock, fromIndex);
  }

  function wrapperTokenizer(eat, value, silent) {
    var entireWrapper;
    var innerWrapper;
    var endPosition;
    var endBlockPosition;

    if (!value.startsWith(startBlock)) return;

    endBlockPosition = value.indexOf(endBlock, startBlock.length);
    if (endBlockPosition === -1) return;

    endPosition = endBlockPosition + endBlock.length;
    entireWrapper = value.slice(0, endPosition);
    innerWrapper = value.slice(startBlock.length, endBlockPosition);

    // // If there is no parsed data, something fishy is up - return nothing.
    // if (!parsedWrapper) return;

    /* Exit with true in silent mode after successful parse - never used (yet) */
    /* istanbul ignore if */
    if (silent) {
      return true;
    }

    return eat(entireWrapper)({
      type: "wrapper",
      identifier: "group",
      child: innerWrapper,
    });
  }
  wrapperTokenizer.locator = locator;
}

function isRemarkParser(parser) {
  return Boolean(
    parser &&
      parser.prototype &&
      parser.prototype.inlineTokenizers &&
      parser.prototype.inlineTokenizers.break &&
      parser.prototype.inlineTokenizers.break.locator
  );
}
