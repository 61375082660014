import { emphasize } from "@material-ui/core/styles/colorManipulator";

export default (theme) => ({
  content: {
    marginLeft: 226,
    marginRight: 226,
    maxWidth: "calc(100vw - 492px)",
    height: "calc(calc(var(--vh, 1vh) * 100) - 87px)",
    overflow: "scroll",
    background: theme.palette.background.paper,
    padding: 20,
    position: "relative",
  },
  contentMobile: {
    background: theme.palette.background.paper,
    padding: 20,
    width: "calc(100vw - 40px)",
    height: "calc(calc(var(--vh, 1vh) * 100) - 131px)",
    overflow: "scroll",
    position: "relative",
  },
  codeMirror: {
    height: "calc(calc(var(--vh, 1vh) * 100) - 47px)",
    overflow: "scroll",
    background: theme.palette.background.paper,
    position: "relative",
  },
  codeMirrorMobile: {
    background: theme.palette.background.paper,
    padding: 0,
    wordWrap: "break-word",
    maxWidth: "100vw",
  },
  leftDrawer: {
    width: 226,
    height: "calc(calc(var(--vh, 1vh) * 100) - 47px)",
    background: theme.palette.background.default,
    position: "absolute",
    zIndex: 99,
  },
  leftDrawerMobile: {
    width: 226,
    background: theme.palette.background.default,
    zIndex: 0,
  },
  rightDrawer: {
    width: 226,
    height: "calc(calc(var(--vh, 1vh) * 100) - 47px)",
    background: theme.palette.background.paper,
    border: "none",
    position: "absolute",
    zIndex: 0,
  },
  background: {
    background: theme.palette.background.default,
  },
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  tabsRoot: {
    background: theme.palette.background.paper,
  },
  chip: {
    backgroundColor: theme.palette.grey[100],
    height: 24,
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
  backChip: {
    backgroundColor: "none",
    height: 24,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: "none",
    },
  },
  avatar: {
    background: "none",
    marginRight: -theme.spacing(1.5),
  },
  breadcrumb: {
    marginTop: -20,
    marginLeft: -20,
    width: "calc(100% + 40px)",
    backgroundColor: theme.palette.background.paper,
    padding: 10,
    position: "sticky",
    top: -20,
    zIndex: 999,
  },
  mobileBreadcrumb: {
    background: theme.palette.background.paper,
    padding: 10,
    position: "sticky",
    top: 0,
    zIndex: 999,
  },
});
