import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import InputBase from "@material-ui/core/InputBase";
import Chip from "@material-ui/core/Chip";
import Portal from "@material-ui/core/Portal";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";

// styles
import styles from "./styles";

const colors = {
  must: "#2196f3",
  should: "#3f51b5",
  could: "#673ab7",
  wont: "#f44336",
  "won't": "#f44336",
};

class Moscow extends Component {
  static propTypes = {
    feature: PropTypes.string,
    priority: PropTypes.string,
    onDelete: PropTypes.func,
    completed: PropTypes.string,
    onAddShortcode: PropTypes.func,
    last: PropTypes.bool,
    id: PropTypes.number,
    onShortcodeChange: PropTypes.func,
  };

  static contextTypes = {
    Wrapper: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { feature, priority } = this.props;

    this.state = {
      focusedInput: null,
      priorityAnchor: null,
      feature,
      priority,
      loaded: false,
    };
  }

  componentDidMount() {
    const { loaded } = this.state;
    if (!loaded) {
      this.setState({ loaded: true });
    }
  }

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  footer() {
    const { id, onAddShortcode } = this.props;
    if (!onAddShortcode) return [];
    return (
      <Grid container justify="flex-end" style={{ padding: "8px 0px 8px 0px" }}>
        <Grid item>
          <Chip
            label="+ Add New"
            variant="outlined"
            onClick={() =>
              onAddShortcode(
                id,
                `[[moscow\n\tid="${new Date().getTime()}"\n\tpriority="must"\n\tcompleted="false"\n\tfeature=""\n]]`
              )
            }
          />
        </Grid>
      </Grid>
    );
  }

  MoscowMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { priority, background, onShortcodeChange, id } = props;

    function handleClick(event) {
      event.stopPropagation();
      event.preventDefault();
      setAnchorEl(event.currentTarget);
    }

    function handleClose() {
      setAnchorEl(null);
    }

    function onChangePriority(p) {
      handleClose();
      console.log("HERE");
      onShortcodeChange(id, { priority: p });
    }

    return (
      <div>
        <Chip
          onClick={handleClick}
          label={priority}
          style={{ background, color: "white", textTransform: "capitalize" }}
        />
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          onSelect={(e) => console.log(e)}
        >
          <MenuItem onClick={() => onChangePriority("must")}>
            <Chip
              label={"Must"}
              style={{
                background: colors.must,
                color: "white",
                textTransform: "capitalize",
              }}
            />
          </MenuItem>
          <MenuItem onClick={() => onChangePriority("should")}>
            <Chip
              label={"Should"}
              style={{
                background: colors.should,
                color: "white",
                textTransform: "capitalize",
              }}
            />
          </MenuItem>
          <MenuItem onClick={() => onChangePriority("could")}>
            <Chip
              label={"Could"}
              style={{
                background: colors.could,
                color: "white",
                textTransform: "capitalize",
              }}
            />
          </MenuItem>
          <MenuItem onClick={() => onChangePriority("wont")}>
            <Chip
              label={"Won't"}
              style={{
                background: colors.wont,
                color: "white",
                textTransform: "capitalize",
              }}
            />
          </MenuItem>
        </Menu>
      </div>
    );
  }

  render() {
    const {
      priority,
      completed,
      onShortcodeChange,
      id,
      classes,
      onDelete,
      last,
    } = this.props;

    const { Wrapper } = this.context;

    const { loaded, feature, reference } = this.state;

    const priorityLC = priority.toLowerCase();

    let background = colors[priorityLC];
    if (background === undefined) background = "#9e9e9e";

    return (
      <div style={{ margin: "3px 0px 3px 0px" }}>
        <Grid
          container
          direction="row"
          alignItems="stretch"
          spacing={1}
          style={{
            borderBottom: "solid 1px rgba(155,155,155,0.3)",
            fontSize: 14,
            fontWeight: 500,
            position: "relative",
            fontFamily: "realist, sans-serif",
          }}
        >
          <Grid
            item
            style={{
              width: 120,
              color: "white",
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <div>
                <this.MoscowMenu
                  id={id}
                  priority={priority}
                  background={background}
                  colors={colors}
                  onShortcodeChange={onShortcodeChange}
                />
              </div>
            </div>
          </Grid>
          <Grid
            item
            style={{
              width: "calc(100% - 190px)",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <InputBase
                value={feature}
                fullWidth
                multiline
                readOnly={!onShortcodeChange}
                className={classes.inputBase}
                onChange={this.handleChange("feature")}
                onBlur={() => {
                  if (onShortcodeChange && feature !== this.props.feature) {
                    onShortcodeChange(id, { feature });
                  }
                }}
              />
            </div>
          </Grid>
          <Grid
            item
            style={{
              width: 70,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                height: "100%",
                width: 70,
              }}
            >
              <div style={{ width: 50 }}>
                <Checkbox
                  color="primary"
                  defaultChecked={completed === "true"}
                  disabled={!onShortcodeChange}
                  onChange={(e) =>
                    onShortcodeChange(id, {
                      completed: `${e.target.checked ? "true" : "false"}`,
                    })
                  }
                />
              </div>
              <div style={{ width: 20 }}>
                <IconButton
                  style={{ padding: 3 }}
                  onClick={() => onDelete(id)}
                  disabled={!onShortcodeChange}
                >
                  <Close style={{ fontSize: 18 }} />
                </IconButton>
              </div>
            </div>
          </Grid>
        </Grid>
        {loaded ? (
          <React.Fragment>
            {last ? (
              <Portal container={Wrapper.getFooterPortal()}>
                {this.footer()}
              </Portal>
            ) : (
              []
            )}
          </React.Fragment>
        ) : (
          []
        )}
      </div>
    );
  }
}

export default withStyles(styles)(Moscow);
