export default (theme) => ({
  container: {
    background: "rgba(155,155,155,0.1)",
    border: "solid 1px rgba(155,155,155,0.3)",
    marginBottom: -1,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: theme.palette.type === "dark" ? "white" : "black",
  },
});
