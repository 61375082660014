import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Info from "@material-ui/icons/Info";
import Help from "@material-ui/icons/Help";
import Error from "@material-ui/icons/Error";
import Warning from "@material-ui/icons/Warning";
import Comment from "@material-ui/icons/Comment";

// renderers
import Text from "../../renderers/Text";

// styles
import styles from "./styles";

const levels = {
  notes: {
    style: {
      color: "#0d3c61",
      backgroundColor: "rgba(33,150,243, 0.2)",
      borderColor: "rgba(33,150,243, 0.3)",
    },
    label: "Notes",
    icon: Info,
  },
  tip: {
    style: {
      color: "#1e4620",
      backgroundColor: "rgba(76,175,80, 0.2)",
      borderColor: "rgba(76,175,80, 0.3)",
    },
    label: "Tip",
    icon: Help,
  },
  error: {
    style: {
      color: "#d32f2f",
      backgroundColor: "rgba(244,67,54, 0.2)",
      borderColor: "rgba(244,67,54, 0.3)",
    },
    label: "Error",
    icon: Error,
  },
  warning: {
    style: {
      color: "#ff6d00",
      backgroundColor: "rgba(255,152,0, 0.2)",
      borderColor: "rgba(255,152,0, 0.3)",
    },
    label: "Warning",
    icon: Warning,
  },
  comments: {
    style: {
      color: "#7f6003",
      backgroundColor: "rgba(255,193,7, 0.2)",
      borderColor: "rgba(255,193,7, 0.3)",
    },
    label: "Comment",
    icon: Comment,
  },
};

class Message extends Component {
  static propTypes = {
    type: PropTypes.number,
    text: PropTypes.string,
    level: PropTypes.string,
    classes: PropTypes.object,
  };

  render() {
    const { text, level, classes } = this.props;
    const selectedLevel = levels[level];

    return (
      <div
        className={classes.messageBox}
        style={selectedLevel ? { ...selectedLevel.style } : undefined}
      >
        {selectedLevel ? <selectedLevel.icon style={{ fontSize: 15 }} /> : []}{" "}
        <b>{selectedLevel ? selectedLevel.label : ""}:</b> <Text>{text}</Text>
      </div>
    );
  }
}

export default withStyles(styles)(Message);
