import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
// actions
import * as Actions from "actions";
// config
import {
  REACT_APP_API,
  REACT_APP_CLIENT_COLOR,
  REACT_APP_CLIENT_EMAIL,
  REACT_APP_CLIENT_NAME,
  REACT_APP_CLIENT_WEBSITE,
} from "config";
import { routeNames } from "constants/routeNames";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import * as Routes from "routes";
import theme from "themes/theme";
// component
import WrapperRootPage from "../component/WrapperRootPage";

const mapStateToProps = (state) => ({
  errors: state.errors,
  app: state.app,
  user: state.user,
  userApps: state.userApps,
});

const mapDispatchToProps = (dispatch) => ({
  checkAccess: (...args) => dispatch(Actions.checkAccess(...args)),
  getCurrentUser: (...args) => dispatch(Actions.getCurrentUser(...args)),
  getUserApps: (...args) => dispatch(Actions.getUserApps(...args)),
  storeResetPasswordToken: (...args) =>
    dispatch(Actions.storeResetPasswordToken(...args)),
  signout: (...args) => dispatch(Actions.signout(...args)),
  requestResetPassword: (...args) =>
    dispatch(Actions.requestResetPassword(...args)),
  resetPassword: (...args) => dispatch(Actions.resetPassword(...args)),
  signup: (...args) => dispatch(Actions.signup(...args)),
  validateEmail: (...args) => dispatch(Actions.validateEmail(...args)),
  setDarkMode: (...args) => dispatch(Actions.setDarkMode(...args)),
  stopImpersonate: (...args) => dispatch(Actions.stopImpersonate(...args)),
  setOrganizationByDefault: (...args) =>
    dispatch(Actions.setOrganizationByDefault(...args)),
  joinOrganization: (...args) => dispatch(Actions.joinOrganization(...args)),
  websocketMessageReceived: (...args) =>
    dispatch(Actions.websocketMessageReceived(...args)),
  getMySignatories: (...args) => dispatch(Actions.getMySignatories(...args)),
  upload: (...args) => dispatch(Actions.upload(...args)),
  noteSearch: (...args) => dispatch(Actions.noteSearch(...args)),
});

class RootPage extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    checkAccess: PropTypes.func,
    getCurrentUser: PropTypes.func,
    getUserApps: PropTypes.func,
    signout: PropTypes.func,
    signup: PropTypes.func,
    validateEmail: PropTypes.func,
    requestResetPassword: PropTypes.func,
    resetPassword: PropTypes.func,
    websocketMessageReceived: PropTypes.func,

    user: PropTypes.object,
    userApps: PropTypes.array,
    errors: PropTypes.object,
    app: PropTypes.object,

    storeResetPasswordToken: PropTypes.func,
    setDarkMode: PropTypes.func,

    stopImpersonate: PropTypes.func,
    getMySignatories: PropTypes.func,
    setOrganizationByDefault: PropTypes.func,
    joinOrganization: PropTypes.func,
    upload: PropTypes.func,
    noteSearch: PropTypes.func,
  };

  static childContextTypes = {
    checkAccess: PropTypes.func,
    userApps: PropTypes.array,
  };

  getChildContext() {
    const { checkAccess, userApps } = this.props;
    return {
      checkAccess,
      userApps,
    };
  }

  componentWillMount() {
    console.log(
      `%cDeveloped by ${REACT_APP_CLIENT_NAME}`,
      `color: ${REACT_APP_CLIENT_COLOR}; font-size: 28px`
    );
    console.log(
      "%cin Los Angeles, California",
      "color: #607d8b; font-size: 18px"
    );
    console.log(
      `%c${REACT_APP_CLIENT_EMAIL}`,
      "color: #607d8b; font-size: 12px"
    );
    console.log(
      `%c${REACT_APP_CLIENT_WEBSITE}`,
      "color: #607d8b; font-size: 12px"
    );
    console.log(
      `%cRunning on ${REACT_APP_API} API`,
      "color: #607d8b; font-size: 12px"
    );
    // setFavicon(REACT_APP_FAVICON);

    const dm =
      window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)");
    dm.addListener(this.darkModeChanged.bind(this));
  }

  getRoutes() {
    const { user } = this.props;
    const routes = [];

    for (const k in routeNames) {
      if (routeNames.hasOwnProperty(k)) {
        const route = Routes[routeNames[k]](user ? user.services : {});
        if (route.onEnter()) {
          routes.unshift(route);
        }
      }
    }

    return routes;
  }

  getAllRoutes() {
    const { user } = this.props;
    const routes = [];

    for (const k in routeNames) {
      if (routeNames.hasOwnProperty(k)) {
        const route = Routes[routeNames[k]](user ? user.services : {});
        routes.unshift(route);
      }
    }

    return routes;
  }

  init(args) {
    console.log("init");
    const { getUserApps, getMySignatories } = this.props;
    return Promise.all([getUserApps(args), getMySignatories(args)]);
  }

  darkModeChanged(dm) {
    const { setDarkMode } = this.props;
    setDarkMode(dm.matches);
  }

  render() {
    const {
      app,
      user,
      getCurrentUser,
      checkAccess,
      signout,
      errors,
      signup,
      validateEmail,
      requestResetPassword,
      storeResetPasswordToken,
      resetPassword,
      setDarkMode,
      userApps,
      stopImpersonate,
      history,
      setOrganizationByDefault,
      websocketMessageReceived,
      joinOrganization,
      noteSearch,
      upload,
    } = this.props;

    const muiTheme = createMuiTheme({
      palette: {
        ...theme,
        type: this.props.app.darkMode ? "dark" : "light",
      },
      overrides: {
        MuiTypography: {
          // Name of the component ⚛️ / style sheet
          root: {
            // Name of the rule
            color: this.props.app.darkMode ? "white" : "black", // Some CSS
          },
        },
      },
    });

    return (
      <MuiThemeProvider theme={muiTheme}>
        <Router>
          <WrapperRootPage
            stopImpersonate={stopImpersonate}
            setDarkMode={setDarkMode}
            app={app}
            checkAccess={checkAccess}
            getCurrentUser={getCurrentUser}
            storeResetPasswordToken={storeResetPasswordToken}
            signout={signout}
            requestResetPassword={requestResetPassword}
            resetPassword={resetPassword}
            websocketMessageReceived={websocketMessageReceived}
            validateEmail={validateEmail}
            signup={signup}
            user={user}
            userApps={userApps}
            errors={errors}
            routes={this.getRoutes()}
            allRoutes={this.getAllRoutes()}
            init={this.init.bind(this)}
            history={history}
            setOrganizationByDefault={setOrganizationByDefault}
            joinOrganization={joinOrganization}
            upload={upload}
            noteSearch={noteSearch}
          />
        </Router>
      </MuiThemeProvider>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(RootPage);
