import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Assignment from "@material-ui/icons/Assignment";
import CalendarViewDayIcon from "@material-ui/icons/CalendarViewDay";
import Checkbox from "@material-ui/icons/CheckBox";
import Code from "@material-ui/icons/Code";
import Extension from "@material-ui/icons/Extension";
import FormatBold from "@material-ui/icons/FormatBold";
import FormatItalic from "@material-ui/icons/FormatItalic";
import FormatListBulleted from "@material-ui/icons/FormatListBulleted";
import FormatListNumbered from "@material-ui/icons/FormatListNumbered";
import FormatQuote from "@material-ui/icons/FormatQuote";
import Link from "@material-ui/icons/Link";
import LocalLibrary from "@material-ui/icons/LocalLibrary";
import Video from "@material-ui/icons/OndemandVideo";
import SettingsEthernet from "@material-ui/icons/SettingsEthernet";
import SpeakerNotes from "@material-ui/icons/SpeakerNotes";
import TableChart from "@material-ui/icons/TableChart";
import ViewStream from "@material-ui/icons/ViewStream";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import PropTypes from "prop-types";
import React, { Component } from "react";
// images
import Gitlab from "./gitlab.svg";
// styles
import styles from "./styles";

class MarkdownToolbar extends Component {
  static propTypes = {
    classes: PropTypes.object,
    editor: PropTypes.object,
    children: PropTypes.node,
  };

  toolbarAction(action, options) {
    const { editor } = this.props;

    const moveCursor = (line, val) => {
      const cursor = editor.getCursor();
      cursor.line += line;
      cursor.ch += val;
      editor.setCursor(cursor);
    };

    const selection = editor.getSelection();
    switch (action) {
      case "bold":
        editor.replaceSelection(`**${selection}**`);
        if (selection.length === 0) moveCursor(0, -2);
        break;
      case "italic":
        editor.replaceSelection(`*${selection}*`);
        if (selection.length === 0) moveCursor(0, -2);
        break;
      case "quote":
        editor.replaceSelection(
          `${selection.length ? "" : "\n"}> ${selection}`
        );
        break;
      case "code":
        editor.replaceSelection(
          `${selection.length ? "" : "\n"}\`${selection}\``
        );
        if (selection.length === 0) moveCursor(0, -1);
        break;
      case "link":
        editor.replaceSelection(
          `${selection.length ? "" : "\n"}[${selection}](url)`
        );
        break;
      case "bullet":
        editor.replaceSelection(
          `${selection.length ? "" : "\n"}* ${selection}`
        );
        break;
      case "numbered":
        editor.replaceSelection(
          `${selection.length ? "" : "\n"}1. ${selection}`
        );
        break;
      case "checkbox":
        editor.replaceSelection(
          `${selection.length ? "" : "\n"}* [ ] ${selection}`
        );
        break;
      case "table":
        editor.replaceSelection(`${
          selection.length ? "" : "\n"
        }| header | header |
| ------ | ------ |
| cell | cell |
| cell | cell |`);
        break;
      case "message":
        editor.replaceSelection(
          `${selection.length ? "" : "\n"}[[message\n\tlevel="${
            options.level
          }"\n\ttext="${selection}"\n]]`
        );
        if (selection.length === 0) moveCursor(-1, 5);
        break;
      case "codeheader":
        editor.replaceSelection(
          `${
            selection.length ? "" : "\n"
          }[[codeheader\n\ttitle="${selection}"\n]]\n\`\`\`\n\`\`\``
        );
        if (selection.length === 0) moveCursor(-3, 5);
        break;
      case "video":
        editor.replaceSelection(
          `${selection.length ? "" : "\n"}[[video\n\turl="${selection}"\n]]`
        );
        if (selection.length === 0) moveCursor(-1, 4);
        break;
      case "task":
        editor.replaceSelection(
          `${
            selection.length ? "" : "\n"
          }[[task\n\tid="${new Date().getTime()}"\n\towner=""\n\tstatus="Not started"\n\tcolor="#03a9f4"\n\ttoDo="${selection}"\n]]`
        );
        if (selection.length === 0) moveCursor(-1, 5);
        break;
      case "api":
        editor.replaceSelection(
          `${
            selection.length ? "" : "\n"
          }[[api\n\tmethod="post"\n\turl="${selection}"\n]]`
        );
        if (selection.length === 0) moveCursor(-1, 4);
        break;
      case "moscow":
        editor.replaceSelection(
          `${
            selection.length ? "" : "\n"
          }[[moscow\n\tid="${new Date().getTime()}"\n\tpriority="must"\n\tcompleted="false"\n\tfeature="${selection}"\n]]`
        );
        if (selection.length === 0) moveCursor(-1, 8);
        break;
      case "hide":
        editor.replaceSelection(
          `${selection.length ? "" : "\n"}[[hide\n\ttext="${selection}"\n]]`
        );
        if (selection.length === 0) moveCursor(-1, 5);
        break;
      case "repo":
        editor.replaceSelection(
          `${selection.length ? "" : "\n"}[[repo\n\turl="${selection}"\n]]`
        );
        if (selection.length === 0) moveCursor(-1, 4);
        break;
      case "kb":
        editor.replaceSelection(
          `${
            selection.length ? "" : "\n"
          }[[kb\n\tid="${new Date().getTime()}"\n\ttags="${selection}"\n\tproblem=""\n\tresolution=""\n\ttitle=""\n]]`
        );
        if (selection.length === 0) moveCursor(-1, 6);
        break;
      case "wrapper":
        editor.replaceSelection(
          `${
            selection.length ? "" : "\n"
          }{{wrapper}}\n${selection}\n{{/wrapper}}`
        );
        if (selection.length === 0) moveCursor(-1, 4);
        break;
      default:
        break;
    }
    editor.focus();
  }

  Message(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { classes, toolbarAction } = props;

    function handleClick(event) {
      setAnchorEl(event.currentTarget);
    }

    function handleClose() {
      setAnchorEl(null);
    }

    return (
      <div>
        <Tooltip title="Add a message">
          <IconButton
            className={classes.toolbarButton}
            onClick={handleClick}
            color="primary"
          >
            <SpeakerNotes className={classes.toolbarIcon} />
          </IconButton>
        </Tooltip>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          disableAutoFocusItem
          variant="menu"
        >
          <MenuItem
            onClick={() => {
              handleClose();
              toolbarAction("message", { level: "notes" });
            }}
          >
            Notes
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              toolbarAction("message", { level: "tip" });
            }}
          >
            Tip
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              toolbarAction("message", { level: "error" });
            }}
          >
            Error
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              toolbarAction("message", { level: "warning" });
            }}
          >
            Warning
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              toolbarAction("message", { level: "comments" });
            }}
          >
            Comments
          </MenuItem>
        </Menu>
      </div>
    );
  }

  render() {
    const { classes, children } = this.props;

    return (
      <div className={classes.toolbar}>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          style={{ height: 40 }}
        >
          <Grid item>{children}</Grid>
          <Grid item>
            <Grid container>
              <Grid item>
                <Tooltip title="Add bold text">
                  <IconButton
                    className={classes.toolbarButton}
                    onClick={() => this.toolbarAction("bold")}
                  >
                    <FormatBold className={classes.toolbarIcon} />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Add italic text">
                  <IconButton
                    className={classes.toolbarButton}
                    onClick={() => this.toolbarAction("italic")}
                  >
                    <FormatItalic className={classes.toolbarIcon} />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Insert a quote">
                  <IconButton
                    className={classes.toolbarButton}
                    onClick={() => this.toolbarAction("quote")}
                  >
                    <FormatQuote className={classes.toolbarIcon} />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Insert a code">
                  <IconButton
                    className={classes.toolbarButton}
                    onClick={() => this.toolbarAction("code")}
                  >
                    <Code className={classes.toolbarIcon} />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Insert a link">
                  <IconButton
                    className={classes.toolbarButton}
                    onClick={() => this.toolbarAction("link")}
                  >
                    <Link className={classes.toolbarIcon} />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Add a bullet list">
                  <IconButton
                    className={classes.toolbarButton}
                    onClick={() => this.toolbarAction("bullet")}
                  >
                    <FormatListBulleted className={classes.toolbarIcon} />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Add a numbered list">
                  <IconButton
                    className={classes.toolbarButton}
                    onClick={() => this.toolbarAction("numbered")}
                  >
                    <FormatListNumbered className={classes.toolbarIcon} />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Add a task list">
                  <IconButton
                    className={classes.toolbarButton}
                    onClick={() => this.toolbarAction("checkbox")}
                  >
                    <Checkbox className={classes.toolbarIcon} />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Add a table">
                  <IconButton
                    className={classes.toolbarButton}
                    onClick={() => this.toolbarAction("table")}
                  >
                    <TableChart className={classes.toolbarIcon} />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Add a code header">
                  <IconButton
                    className={classes.toolbarButton}
                    onClick={() => this.toolbarAction("codeheader")}
                    color="primary"
                  >
                    <ViewStream className={classes.toolbarIcon} />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <this.Message
                  classes={classes}
                  toolbarAction={this.toolbarAction.bind(this)}
                />
              </Grid>
              <Grid item>
                <Tooltip title="Add a video">
                  <IconButton
                    className={classes.toolbarButton}
                    onClick={() => this.toolbarAction("video")}
                    color="primary"
                  >
                    <Video className={classes.toolbarIcon} />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Add a task">
                  <IconButton
                    className={classes.toolbarButton}
                    onClick={() => this.toolbarAction("task")}
                    color="primary"
                  >
                    <Assignment className={classes.toolbarIcon} />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Add an api call">
                  <IconButton
                    className={classes.toolbarButton}
                    onClick={() => this.toolbarAction("api")}
                    color="primary"
                  >
                    <SettingsEthernet className={classes.toolbarIcon} />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Add a MOSCOW item">
                  <IconButton
                    className={classes.toolbarButton}
                    onClick={() => this.toolbarAction("moscow")}
                    color="primary"
                  >
                    <Extension className={classes.toolbarIcon} />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Add a protected text">
                  <IconButton
                    className={classes.toolbarButton}
                    onClick={() => this.toolbarAction("hide")}
                    color="primary"
                  >
                    <VisibilityOff className={classes.toolbarIcon} />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Add a Gitlab repo link">
                  <IconButton
                    className={classes.toolbarButton}
                    onClick={() => this.toolbarAction("repo")}
                    color="primary"
                  >
                    <img src={Gitlab} style={{ height: 18 }} alt="gitlab" />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Add a Knowledge">
                  <IconButton
                    className={classes.toolbarButton}
                    onClick={() => this.toolbarAction("kb")}
                    color="primary"
                  >
                    <LocalLibrary className={classes.toolbarIcon} />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Embed in Wrapper">
                  <IconButton
                    className={classes.toolbarButton}
                    onClick={() => this.toolbarAction("wrapper")}
                    style={{ color: "#9c27b0" }}
                  >
                    <CalendarViewDayIcon className={classes.toolbarIcon} />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(MarkdownToolbar);
