export default {
  name: "Message",
  description:
    "Display a task that include the to do, the owner, the status and the dueDate",
  example:
    '[[task text="Solve issue #65" owner="Olivier" status="working" dueDate="2019-09-01"]]',
  attributes: [
    {
      name: "toDo",
      description: "The description of the task",
      type: "String",
      example: "Solve issue #65",
      required: true,
    },
    {
      name: "owner",
      description: "The personn assigned to the task",
      type: "String",
      example: "Olivier",
      required: false,
    },
    {
      name: "status",
      description: "Status of the task",
      type: "String",
      example: "pending | working | stuck | done",
      required: true,
    },
    {
      name: "color",
      description: "Border right color",
      type: "String",
      example: "#2196f3",
      required: false,
    },
    {
      name: "url",
      description: "Gitlab url",
      type: "String",
      example: "https://git.wid.la/wa/interdoc-v2-frontend/issues/9",
      required: false,
    },
    {
      name: "startDate",
      description: "Start Date of the task",
      type: "YYYY/MM/DD",
      example: "2019/09/01",
      required: false,
    },
    {
      name: "endDate",
      description: "End Date of the task",
      type: "YYYY/MM/DD",
      example: "2019/09/04",
      required: false,
    },
  ],
};
