import React, { Component } from "react";
import PropTypes from "prop-types";
import BareHighlight from "react-fast-highlight/lib/BareHighlight";
import hljs from "./customhljs";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Copy from "@material-ui/icons/AddToPhotosTwoTone";

// helpers
import { copyTextToClipboard } from "helpers/url";

// styles
import styles from "./styles";

class Code extends Component {
  static propTypes = {
    value: PropTypes.node,
    language: PropTypes.string,
    darkMode: PropTypes.bool,
  };

  state = {
    copied: false,
  };

  render() {
    const { value, language, darkMode } = this.props;
    const { copied } = this.state;

    return (
      <div
        style={{
          background: darkMode ? "#333333" : "#F8F8F8",
          margin: "0px 0px 10px 0px",
          padding: 2,
          borderBottomLeftRadius: 4,
          borderBottomRightRadius: 4,
          position: "relative",
          border: `solid 1px ${darkMode ? "#444444" : "#e9e9e9"}`,
          width: "calc(100% - 4px)",
        }}
      >
        <link
          rel="stylesheet"
          href={
            darkMode
              ? "https://cdnjs.cloudflare.com/ajax/libs/highlight.js/9.13.1/styles/agate.min.css"
              : "https://cdnjs.cloudflare.com/ajax/libs/highlight.js/9.13.1/styles/github.min.css"
          }
        />
        <BareHighlight
          languages={[
            "javascript",
            "css",
            "html",
            "go",
            "bash",
            "json",
            "markdown",
            "ruby",
            "yaml",
            "dart",
          ]}
          className={language}
          highlightjs={hljs}
        >
          {value || ""}
        </BareHighlight>
        {copied ? (
          <div
            style={{
              color: darkMode ? "#F8F8F8" : "#333333",
              position: "absolute",
              top: 2,
              right: 2,
              padding: 2,
              fontSize: 12,
            }}
          >
            Copied
          </div>
        ) : (
          <Tooltip title="Copy to Clipboard">
            <IconButton
              style={{
                color: darkMode ? "#F8F8F8" : "#333333",
                position: "absolute",
                top: 2,
                right: 2,
                padding: 2,
              }}
              onClick={() => {
                copyTextToClipboard(value);
                this.setState({ copied: true });
                setTimeout(() => this.setState({ copied: false }), 2000);
              }}
            >
              <Copy style={{ fontSize: 16 }} />
            </IconButton>
          </Tooltip>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(Code);
