import Grid from "@material-ui/core/Grid";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactMarkdown from "react-markdown";
import htmlParser from "react-markdown/plugins/html-parser";
import Blockquote from "./renderers/Blockquote";
import Code from "./renderers/Code";
// Renderers
import Heading from "./renderers/Heading";
import Image from "./renderers/Image";
import InlineCode from "./renderers/InlineCode";
import Link from "./renderers/Link";
import List from "./renderers/List";
import ListItem from "./renderers/ListItem";
import Paragraph from "./renderers/Paragraph";
import Root from "./renderers/Root";
import Table from "./renderers/Table";
import TableBody from "./renderers/TableBody";
import TableCell from "./renderers/TableCell";
import TableHead from "./renderers/TableHead";
import TableRow from "./renderers/TableRow";
import Text from "./renderers/Text";
import shortcodeParser from "./shortcodeParser";
// Shortcodes
import * as Shortcodes from "./shortcodes";
import * as Docs from "./shortcodes/docs";
// styles
import styles from "./styles";
// wrappper
import Wrapper from "./wrapper";
import wrapperParser from "./wrapperParser";

class Markdown extends Component {
  static propTypes = {
    source: PropTypes.string,
    darkMode: PropTypes.bool,
    scrollTo: PropTypes.func,
    setVisible: PropTypes.func,
    save: PropTypes.func,
    onCheck: PropTypes.func,
    onChange: PropTypes.func,
    onShortcodeChange: PropTypes.func,
    onAddShortcode: PropTypes.func,
    onDelete: PropTypes.func,
  };

  static childContextTypes = {
    Wrapper: PropTypes.object,
  };

  shouldComponentUpdate(nextProps) {
    if (
      nextProps.darkMode !== this.props.darkMode ||
      nextProps.source !== this.props.source
    ) {
      return true;
    }
    return false;
  }

  interpretWrapper({ identifier, child }) {
    //eslint-disable-line
    const { onChange, source, save, onAddShortcode } = this.props;

    return (
      <Wrapper
        interpretShortcode={this.interpretShortcode.bind(this)}
        onAddShortcode={onAddShortcode}
        child={child}
        source={source}
        save={save}
        onChange={onChange}
      />
    );
  }

  interpretShortcode({ identifier, attributes }, args) {
    const { classes, onShortcodeChange, onDelete, onAddShortcode } = this.props;
    const capitalizedIdentifier =
      identifier.charAt(0).toUpperCase() + identifier.slice(1);

    const Interpretation = Shortcodes[capitalizedIdentifier];
    if (Interpretation) {
      const documentation = Docs[`${capitalizedIdentifier}Doc`];
      const requiredFields = documentation.attributes.filter((a) => a.required);
      let attributesAreValid = true;
      const missing = [];
      for (const k in requiredFields) {
        if (requiredFields.hasOwnProperty(k)) {
          const field = requiredFields[k];
          if (attributes[field.name] === undefined) {
            attributesAreValid = false;
            missing.push(field);
          }
        }
      }
      if (attributesAreValid) {
        return (
          <Interpretation
            {...attributes}
            {...args}
            onShortcodeChange={onShortcodeChange}
            onAddShortcode={onAddShortcode}
            onDelete={onDelete}
          />
        );
      }

      return (
        <div style={{ display: "flex" }}>
          <div className={classes.shortcodeDoc}>
            <b style={{ fontSize: 14 }}>{documentation.name}</b>:{" "}
            {documentation.description}
            <br />
            <b style={{ fontSize: 14 }}>Example</b>: {documentation.example}
            <br />
            <b style={{ fontSize: 14 }}>Attributes</b>:
            <ul>
              {documentation.attributes.map((a) => (
                <li>
                  <u>{a.name}</u>
                  {a.required ? " - REQUIRED - " : ""}[<i>{a.type}</i>]:{" "}
                  {a.description}.
                  <br />
                  Ex: {a.example}.
                </li>
              ))}
            </ul>
            <b style={{ fontSize: 14 }}>Missing Attribute(s):</b>:
            <Grid container spacing={1}>
              {missing.map((m) => (
                <Grid item>
                  <span key={m.name} className={classes.missing}>
                    {m.name}
                  </span>
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      );
    }

    return <span className={classes.shortcodeMessage}>Unknown Shortcode</span>;
  }

  getMd(source) {
    const { darkMode, scrollTo, setVisible, onCheck } = this.props;

    const parseHtml = htmlParser({
      isValidNode: (node) => node.type !== "script",
      processingInstructions: [
        /* ... */
      ],
    });

    let i = 0;

    return (
      <ReactMarkdown
        source={source}
        renderers={{
          root: (props) => <Root {...props} />,
          heading: (props) => (
            <Heading {...props} scrollTo={scrollTo} setVisible={setVisible} />
          ),
          text: (props) => <Text {...props} />,
          image: (props) => <Image {...props} />,
          paragraph: (props) => <Paragraph {...props} />,
          link: (props) => <Link {...props} />,
          linkReference: (props) => <Link {...props} />,
          list: (props) => <List {...props} />,
          listItem: (props) => {
            let checkboxId;
            console.log(props);
            if (props.checked !== null) {
              checkboxId = i;
              i += 1;
            }
            console.log(checkboxId);
            return (
              <ListItem {...props} checkboxId={checkboxId} onCheck={onCheck} />
            );
          },
          blockquote: (props) => <Blockquote {...props} />,
          table: (props) => <Table {...props} />,
          tableHead: (props) => <TableHead {...props} />,
          tableBody: (props) => <TableBody {...props} />,
          tableRow: (props) => <TableRow {...props} />,
          tableCell: (props) => <TableCell {...props} />,
          code: (props) => <Code {...props} darkMode={darkMode} />,
          inlineCode: (props) => <InlineCode {...props} />,
          shortcode: this.interpretShortcode.bind(this),
          wrapper: this.interpretWrapper.bind(this),
        }}
        plugins={[[shortcodeParser], [wrapperParser]]}
        escapeHtml={false}
        astPlugins={[parseHtml]}
      />
    );
  }

  render() {
    const { source } = this.props;

    return this.getMd(source);
  }
}

export default withStyles(styles)(Markdown);
