export default {
  name: "Codeheader",
  description: "Show a title (like a path) to your code.",
  example: '[[codeheader title="lib/main.dart"]]',
  attributes: [
    {
      name: "title",
      description: "The title you want to display",
      type: "String",
      example: "lib/main.dart",
      required: true,
    },
  ],
};
