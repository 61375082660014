export default (theme) => ({
  messageBox: {
    padding: "1rem 1rem",
    marginBottom: "1rem",
    border: "1px solid transparent",
    color: theme.palette.type === "dark" ? "white !important" : undefined,
  },
  late: {
    fontFamily: "source-code-pro, monospace;",
    fontSize: 10,
    color: "white",
    background: "#f44336",
    border: "solid 1px #e53935",
    borderRadius: 4,
    padding: "0px 2px 0px 2px",
  },
  begin: {
    fontFamily: "source-code-pro, monospace;",
    fontSize: 10,
    color: "white",
    background: "#607d8b",
    border: "solid 1px #546e7a",
    borderRadius: 4,
    padding: "0px 2px 0px 2px",
  },
  due: {
    fontFamily: "source-code-pro, monospace;",
    fontSize: 10,
    color: "white",
    background: "#2196f3",
    border: "solid 1px #1e88e5",
    borderRadius: 4,
    padding: "0px 2px 0px 2px",
  },
  inputBase: {
    fontFamily: "realist, sans-serif",
    fontSize: 14,
    padding: 4,
  },
});
