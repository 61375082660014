import { NOTE } from "constants/routes";
import { checkAccess } from "helpers/auth";
import NotePage from "./container/NotePage";

export default function getSettingsRoute(userAuth) {
  const requireAuth = {};
  return {
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    path: NOTE,
    withSidebar: false,
    withAppBar: true,
    component: NotePage,
  };
}
