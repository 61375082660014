import React, { Component } from "react";
import PropTypes from "prop-types";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import TreeItem from "@material-ui/lab/TreeItem";
import Grain from "@material-ui/icons/Grain";

// styles
import styles from "./styles";

class TreeSubSections extends Component {
  static propTypes = {
    classes: PropTypes.object,
    subSections: PropTypes.func,
    selectedSubSection: PropTypes.object,
    updateSubSection: PropTypes.func,
    onSelect: PropTypes.func,
    onEdit: PropTypes.func,
    edit: PropTypes.bool,
    i: PropTypes.string,
  };

  constructor(...args) {
    super(...args);
    const { subSections } = this.props;
    this.state = {
      subSections,
    };
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { updateSubSection } = this.props;
    const subSectionToUpdate = this.state.subSections[oldIndex];
    updateSubSection(subSectionToUpdate.id, { order: newIndex });
    this.setState(({ subSections }) => ({
      subSections: arrayMove(subSections, oldIndex, newIndex),
    }));
  };

  shouldCancelStart = (e) => {
    console.log(e.target.tagName);
    if (e.target.tagName === "P") {
      return true; // Return true to cancel sorting
    }
  };

  render() {
    const {
      classes,
      onSelect,
      onEdit,
      edit,
      i,
      selectedSubSection,
    } = this.props;

    const { subSections } = this.state;

    const SortableItem = SortableElement((g) => (
      <TreeItem
        nodeId={`subSection_${g.value.id}`}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          if (edit) {
            setTimeout(() => onSelect(Number(i), Number(g.index)), 300);
          } else {
            onSelect(Number(i), Number(g.index));
          }
        }}
        onDoubleClick={
          edit
            ? (e) => {
                e.stopPropagation();
                e.preventDefault();
                onEdit("subSection", g.value);
              }
            : undefined
        }
        label={g.value.title}
        icon={
          edit ? (
            <Grain style={{ fontSize: 14, color: "rgb(155,155,155)" }} />
          ) : undefined
        }
        classes={{
          root: classes.noFocus,
          label:
            g.value.id === selectedSubSection.id
              ? classes.selectedSubSectionLabel
              : classes.subSectionLabel,
        }}
        style={{
          cursor: edit ? "grab" : undefined,
        }}
      />
    ));

    const SortableList = SortableContainer(({ items }) => {
      return (
        <div container spacing={16}>
          {items &&
            items.map((value, index) => (
              <SortableItem
                disabled={!edit}
                key={value.id}
                index={index}
                value={value}
              />
            ))}
        </div>
      );
    });

    return (
      <SortableList
        axis="y"
        items={subSections}
        onSortEnd={this.onSortEnd}
        onSortStart={(_, event) => event.preventDefault()}
        shouldCancelStart={this.shouldCancelStart}
      />
    );
  }
}

export default withStyles(styles)(TreeSubSections);
