export default (theme) => ({
  container: {
    padding: 5,
  },
  book: {
    padding: 10,
    position: "relative",
    cursor: "pointer",
    textAlign: "center",
    fontWeight: "600",
    color: theme.palette.type === "dark" ? "white" : "black",
    height: 220,
  },
  footer: {
    background: theme.palette.primary.color[500],
    borderRadius: 4,
  },
  versionTag: {
    position: "absolute",
    background: theme.palette.primary.color[500],
    color: "white",
    transform: "rotate(-45deg)",
    width: "100px",
    left: "-30px",
    top: "13px",
    textAlign: "center",
  },
  signature: {
    margin: "auto",
  },
  signatureRoot: {
    background: "#ff9800",
    color: "white",
  },
});
