import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

import { NOTEBOOKS } from "constants/routes";

// component
import WrapperNotePage from "../component/WrapperNotePage";

const mapStateToProps = (state) => ({
  app: state.app,
  user: state.user,
  users: state.users,
});

const mapDispatchToProps = (dispatch) => ({
  getNoteById: (...args) => dispatch(Actions.getNoteById(...args)),
  getPublicNoteById: (...args) => dispatch(Actions.getPublicNoteById(...args)),
  updateSection: (...args) => dispatch(Actions.updateSection(...args)),
  createSection: (...args) => dispatch(Actions.createSection(...args)),
  deleteSection: (...args) => dispatch(Actions.deleteSection(...args)),
  updateSubSection: (...args) => dispatch(Actions.updateSubSection(...args)),
  createSubSection: (...args) => dispatch(Actions.createSubSection(...args)),
  deleteSubSection: (...args) => dispatch(Actions.deleteSubSection(...args)),
  getSubSectionById: (...args) => dispatch(Actions.getSubSectionById(...args)),
  getSectionById: (...args) => dispatch(Actions.getSectionById(...args)),
  updateNote: (...args) => dispatch(Actions.updateNote(...args)),
  deleteNote: (...args) => dispatch(Actions.deleteNote(...args)),
  upload: (...args) => dispatch(Actions.upload(...args)),
  updateSignatory: (...args) => dispatch(Actions.updateSignatory(...args)),
  getMySignatories: (...args) => dispatch(Actions.getMySignatories(...args)),
  getSignatories: (...args) => dispatch(Actions.getSignatories(...args)),
  createSignatory: (...args) => dispatch(Actions.createSignatory(...args)),
  deleteSignatory: (...args) => dispatch(Actions.deleteSignatory(...args)),
  getInterdocUsers: (...args) => dispatch(Actions.getInterdocUsers(...args)),
  getNotebooks: (...args) => dispatch(Actions.getNotebooks(...args)),
  duplicateNote: (...args) => dispatch(Actions.duplicateNote(...args)),
});
class NotePage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    user: PropTypes.object,
    app: PropTypes.object,
    updateSection: PropTypes.func,
    createSection: PropTypes.func,
    deleteSection: PropTypes.func,
    getSectionById: PropTypes.func,

    updateSubSection: PropTypes.func,
    createSubSection: PropTypes.func,
    deleteSubSection: PropTypes.func,
    getSubSectionById: PropTypes.func,

    upload: PropTypes.func,

    updateSignatory: PropTypes.func,
    getMySignatories: PropTypes.func,
    getSignatories: PropTypes.func,
    createSignatory: PropTypes.func,
    deleteSignatory: PropTypes.func,

    // notes
    getNoteById: PropTypes.func,
    updateNote: PropTypes.func,
    deleteNote: PropTypes.func,
    getPublicNoteById: PropTypes.func,

    // users
    getInterdocUsers: PropTypes.func,
    users: PropTypes.array,

    // notebooks
    getNotebooks: PropTypes.func,
    duplicateNote: PropTypes.func,
  };

  static contextTypes = {
    appBarMenuHook: PropTypes.func,
    onAppLogoClickedHook: PropTypes.func,
    WebsocketCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const {
      location,
      history,
      getInterdocUsers,
      createSignatory,
      getNotebooks,
      user,
      getMySignatories,
    } = this.props;
    const urlParams = getJsonFromUrl(location);
    const { bookmark, signatureRequired } = urlParams;
    this.state = {};
    if (bookmark === undefined) {
      history.push(NOTEBOOKS);
      return;
    }
    const split = bookmark.split(".");

    const noteID = split[0];
    const subSectionID = split[1];

    if (noteID === undefined) {
      history.push(NOTEBOOKS);
    }
    const token = urlParams.token;
    this.state = {
      noteID,
      subSectionID,
      token,
      notebooks: [],
      i: 0,
      j: 0,
      loading: true,
      ignoreWS: false,
    };

    if (!token) {
      getInterdocUsers();
      getNotebooks().then((resp) => this.setState({ notebooks: resp.payload }));
    }

    if (signatureRequired) {
      createSignatory({
        userUID: user.uuid,
        noteID: Number(noteID),
      }).then(() => getMySignatories().then(this.refresh.bind(this)));
    } else {
      this.refresh();
    }
  }

  componentWillUnmount() {
    const { appBarMenuHook } = this.context;
    const { subscription } = this.state;
    appBarMenuHook();
    if (subscription) {
      subscription.unsubscribe();
    }
  }

  onSelect(i, j) {
    const { history } = this.props;
    const { noteID, note, token } = this.state;
    const ss = note.sections[i].subSections[j];
    ss.section = this.findSectionFor(ss, note);
    this.setState({ i, j, subSectionID: ss.id });
    history.push({
      search: `?bookmark=${noteID}.${ss.id}${token ? `&token=${token}` : ""}`,
    });
  }

  async wsCallback(message) {
    const { ignoreWS, note } = this.state;
    const { getSubSectionById } = this.props;

    const newNote = Object.assign({}, note);
    if (ignoreWS) {
      console.log("-- WS IGNORE");
      return;
    }
    let resp;
    let obj;
    let i;
    let j;
    const { data } = message;
    const { id, resource } = data;
    const refreshKey = new Date().getTime();
    switch (resource) {
      case "subSections":
        resp = await getSubSectionById(id);
        obj = resp.payload;
        obj.section = this.findSectionFor(obj, note);
        i = newNote.sections.findIndex((s) => s.id === obj.sectionID);
        if (newNote.sections[i].subSections) {
          j = newNote.sections[i].subSections.findIndex(
            (ss) => ss.id === obj.id
          );
          newNote.sections[i].subSections[j] = obj;
          newNote.refreshKey = refreshKey;
        }
        this.setState({ note: newNote });
        break;
      case "notes":
      case "sections":
        this.refresh();
        break;
      default:
        break;
    }
  }

  findSectionFor(subSection, note) {
    for (const key in note.sections) {
      if (note.sections.hasOwnProperty(key)) {
        const section = note.sections[key];
        if (
          section.subSections &&
          section.subSections.findIndex((ss) => ss.id === subSection.id) !== -1
        ) {
          return section;
        }
      }
    }
    return undefined;
  }

  async refresh() {
    const { noteID, subSectionID, subscription, token } = this.state;
    const { WebsocketCenter, onAppLogoClickedHook } = this.context;
    const { getNoteById, getPublicNoteById, history } = this.props;

    let resp;
    if (token) {
      resp = await getPublicNoteById(token);
    } else {
      resp = await getNoteById(noteID);
    }
    if (resp.success) {
      const note = resp.payload;
      if (subscription === undefined) {
        const s = WebsocketCenter.subscribe(
          `interdoc:${note.tagID}`,
          this.wsCallback.bind(this)
        );
        this.setState({ subscription: s });
      }
      if (note.sections === undefined) note.sections = [];
      let selectedSubSection;
      // find subSection
      for (const i in note.sections) {
        if (note.sections.hasOwnProperty(i)) {
          const s = note.sections[i];
          for (const j in s.subSections) {
            if (s.subSections.hasOwnProperty(j)) {
              const ss = s.subSections[j];
              ss.next = undefined;
              if (s.subSections[Number(j) + 1]) {
                ss.next = () => this.onSelect(Number(i), Number(j) + 1);
              } else if (
                note.sections[Number(i) + 1] &&
                note.sections[Number(i) + 1] &&
                note.sections[Number(i) + 1].subSections &&
                note.sections[Number(i) + 1].subSections.length
              ) {
                ss.next = () => this.onSelect(Number(i) + 1, 0);
              }
              if (selectedSubSection === undefined) {
                selectedSubSection = { i, j };
              }
              if (ss.id === Number(subSectionID)) {
                selectedSubSection = { i, j };
              }

              ss.section = this.findSectionFor(ss, note);
            }
          }
        }
      }

      const signatory =
        this.props.user &&
        this.props.user.signatories &&
        this.props.user.signatories.find((s) => s.noteID === Number(noteID));

      onAppLogoClickedHook(() =>
        history.push(`${NOTEBOOKS}?selected=${note.notebookID}`)
      );

      if (signatory) {
        note.signatory = signatory;
      } else {
        note.signatory = false;
      }

      if (token) {
        note.role = "public";
      }

      note.refreshKey = new Date().getTime();
      this.setState({ ...selectedSubSection, note, loading: false });
    }
  }

  render() {
    const {
      location,
      history,
      app,
      updateSection,
      createSection,
      deleteSection,
      updateSubSection,
      createSubSection,
      deleteSubSection,
      updateNote,
      deleteNote,
      upload,
      updateSignatory,
      getMySignatories,
      getSignatories,
      createSignatory,
      deleteSignatory,
      duplicateNote,
      users,
      getNoteById,
    } = this.props;

    const {
      urlParams,
      loading,
      note,
      i,
      j,
      subscription,
      notebooks,
    } = this.state;

    return (
      <WrapperNotePage
        history={history}
        location={location}
        urlParams={urlParams}
        loading={loading}
        app={app}
        note={note}
        subscription={subscription}
        selectedSubSection={
          note && note.id && note.sections[i] && note.sections[i].subSections
            ? note.sections[i].subSections[j]
            : undefined
        }
        updateSection={updateSection}
        createSection={createSection}
        deleteSection={deleteSection}
        updateSubSection={updateSubSection}
        createSubSection={createSubSection}
        deleteSubSection={deleteSubSection}
        updateSignatory={updateSignatory}
        getMySignatories={getMySignatories}
        getSignatories={getSignatories}
        createSignatory={createSignatory}
        deleteSignatory={deleteSignatory}
        duplicateNote={duplicateNote}
        getNoteById={getNoteById}
        upload={upload}
        updateNote={updateNote}
        deleteNote={deleteNote}
        users={users}
        notebooks={notebooks}
        onSelect={this.onSelect.bind(this)}
        refresh={this.refresh.bind(this)}
        setIgnoreWS={(ign) => this.setState({ ignoreWS: ign })}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(NotePage);
