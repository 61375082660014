export default {
  name: "Video",
  description:
    "Embed video from different provider including file paths, YouTube, Facebook, Twitch, SoundCloud, Streamable, Vimeo, Wistia, Mixcloud, and DailyMotion",
  example: '[[video url="https://www.youtube.com/watch?v=h2eKImKZviw"]]',
  attributes: [
    {
      name: "url",
      description: "The url of the video",
      type: "String",
      example: "https://www.youtube.com/watch?v=h2eKImKZviw",
      required: true,
    },
  ],
};
