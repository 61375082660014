import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";

// material-ui
import { withStyles } from "@material-ui/core/styles";

// styles
import styles from "./styles";

class Timer extends Component {
  static propTypes = {
    classes: PropTypes.object,
    timeout: PropTypes.object,
    callback: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const interval = setInterval(() => {
      this.upateLabel();
    }, 100);
    this.state = {
      interval,
      label: "-",
    };
  }

  componentWillUnmount() {
    const { interval } = this.state;
    clearInterval(interval);
  }

  async upateLabel() {
    const { timeout, callback } = this.props;
    const { interval } = this.state;

    const now = moment();
    const diff = timeout.diff(now);
    this.setState({ label: ` ${Math.round((diff / 1000) * 10) / 10} sec` });
    if (diff <= 0) {
      clearInterval(interval);
      this.setState({ label: " Syncing..." });
      await callback();
      this.setState({ label: " -" });
    }
  }

  render() {
    const { label } = this.state;

    return <span>{label}</span>;
  }
}

export default withStyles(styles)(Timer);
