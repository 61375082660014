import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";

// styles
import styles from "./styles";

class Blockquote extends Component {
  static propTypes = {
    children: PropTypes.any,
    classes: PropTypes.object,
  };

  render() {
    const { children, classes } = this.props;

    return <div className={classes.blockquote}>{children}</div>;
  }
}

export default withStyles(styles)(Blockquote);
