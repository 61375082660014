import { GET, PUT, DELETE, POST } from "constants/methods";

import { REACT_APP_API_PREFIX } from "config/env";

const service = "interdoc";
const sections = `${REACT_APP_API_PREFIX}/${service}/sections`;

export function getSections() {
  const url = `${sections}`;
  return {
    method: GET,
    url,
  };
}

export function getSectionById(id) {
  const url = `${sections}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateSection(id, body) {
  const url = `${sections}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteSection(id) {
  const url = `${sections}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createSection(body) {
  const url = `${sections}`;
  return {
    method: POST,
    url,
    body,
  };
}
