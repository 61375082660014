export default () => ({
  container: {
    background: "rgba(155,155,155,0.1)",
    border: "solid 1px rgba(155,155,155,0.3)",
    borderLeft: "solid 5px #ff9800",
    fontSize: 14,
    fontWeight: 500,
    fontFamily: "realist, sans-serif",
  },
});
