export default (theme) => ({
  search: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    maxWidth: 400,
    margin: "auto",
    width: "100%",
    background: "rgba(255,255,255,0.1)",
    color: "white",
  },
  result: {
    position: "relative",
    maxWidth: 600,
    margin: "auto",
    width: "100%",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    color: "white",
    width: 0,
  },
  iconButton: {
    padding: 4,
  },
  versionTag: {
    position: "absolute",
    background: theme.palette.primary.color[500],
    color: "white",
    transform: "rotate(-45deg)",
    width: "100px",
    left: "-30px",
    top: "13px",
    textAlign: "center",
  },
  preview: {
    background: "rgba(155,155,155,0.1)",
    border: "solid 1px rgba(155,155,155,0.3)",
    borderRadius: 4,
    padding: 4,
  },
});
