import subSections from "./subSections";
import sections from "./sections";
import notebooks from "./notebooks";
import notes from "./notes";
import errors from "./errors";
import app from "./app";
import user from "./user";
import users from "./users";
import userApps from "./userApps";
import { combineReducers } from "redux"; //eslint-disable-line
export default combineReducers({
  errors,
  user,
  users,
  app,
  userApps,
  notes,
  notebooks,
  sections,
  subSections,
});
