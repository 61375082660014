export default (theme) => ({
  container: {
    padding: 5,
  },
  toolbar: {
    position: "fixed",
    width: "calc(100vw - 226px)",
    background: theme.palette.background.paper,
    left: 226,
    top: 47,
    height: 40,
    borderBottom: "solid 1px rgba(155, 155, 155, 0.3)",
    zIndex: 999,
  },
  toolbarButton: {
    padding: 5,
  },
  toolbarIcon: {
    fontSize: 18,
  },
});
