// routes
import { MY_ACCOUNT } from "constants/routes";

import AccountCircle from "@material-ui/icons/AccountCircleTwoTone";

export const menu = [
  {
    icon: AccountCircle,
    label: "My Account",
    path: MY_ACCOUNT,
    divider: true,
  },
];
