import { networkAction } from "helpers/network/networkAction";

import {
  GET_SIGNATORIES,
  GET_MY_SIGNATORIES,
  GET_SIGNATORY_BY_ID,
  DELETE_SIGNATORY,
  UPDATE_SIGNATORY,
  CREATE_SIGNATORY,
} from "constants/signatories";

import * as Api from "api";

export const getSignatories = (filters) => async (dispatch) =>
  networkAction(dispatch, GET_SIGNATORIES, Api.getSignatories, [filters]);

export const getMySignatories = (args) => async (dispatch) =>
  networkAction(dispatch, GET_MY_SIGNATORIES, Api.getMySignatories, [args]);

export const getSignatoryById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_SIGNATORY_BY_ID, Api.getSignatoryById, [id]);

export const deleteSignatory = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_SIGNATORY, Api.deleteSignatory, [id]);

export const createSignatory = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_SIGNATORY, Api.createSignatory, [body]);

export const updateSignatory = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_SIGNATORY, Api.updateSignatory, [id, body]);
