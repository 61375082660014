import React, { Component } from "react";
import PropTypes from "prop-types";
import "./anime.css";

// material-ui
import { withStyles } from "@material-ui/core/styles";

// styles
import styles from "./styles";

class Jiggle extends Component {
  static propTypes = {
    classes: PropTypes.object,
    children: PropTypes.node,
    jiggle: PropTypes.bool,
  };

  render() {
    const { children, jiggle } = this.props;

    return (
      <div
        style={{
          animationDelay: jiggle ? `-${Math.random()}s` : undefined,
          animationDuration: jiggle
            ? `.${Math.floor(Math.random() * 11) + 20}s`
            : undefined,
        }}
        className="jiggle"
      >
        {children}
      </div>
    );
  }
}

export default withStyles(styles)(Jiggle);
