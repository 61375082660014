import { GET, PUT, DELETE, POST } from "constants/methods";

import { REACT_APP_API_PREFIX } from "config/env";

const service = "interdoc";
const signatories = `${REACT_APP_API_PREFIX}/${service}/signatories`;

export function getSignatories(filters) {
  const url = `${signatories}${filters || ""}`;
  return {
    method: GET,
    url,
  };
}

export function getMySignatories() {
  const url = `${signatories}/me`;
  return {
    method: GET,
    url,
  };
}

export function getSignatoryById(id) {
  const url = `${signatories}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateSignatory(id, body) {
  const url = `${signatories}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteSignatory(id) {
  const url = `${signatories}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createSignatory(body) {
  const url = `${signatories}`;
  return {
    method: POST,
    url,
    body,
  };
}
