import { GET_SUB_SECTIONS } from "constants/subSections";

export default (state = [], action) => {
  switch (action.type) {
    case `${GET_SUB_SECTIONS}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
