export default (theme) => ({
  blockquote: {
    background: "rgba(142,155,155,0.1)",
    borderLeft: "solid 3px rgba(155,155,155,0.3)",
    // border: 'solid 1px rgba(155,155,155,0.2)',
    marginBottom: 20,
    padding: 12,
    marginTop: 20,
    fontWeight: 400,
  },
});
