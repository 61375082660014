import React, { Component } from "react";
import PropTypes from "prop-types";
import CodeMirror from "react-codemirror";
import "codemirror/addon/search/searchcursor";
import moment from "moment";

// material-ui
import { withStyles } from "@material-ui/core/styles";

// custom
import MarkdownToolbar from "components/MarkdownToolbar";
import Timer from "components/Timer";

// helpers
import { getJsonFromUrl } from "helpers";

// styles
import styles from "./styles";
import imageMime from "./supportedImageMime";

class MarkdownEditor extends Component {
  static propTypes = {
    classes: PropTypes.object,
    value: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.object,
    subscription: PropTypes.object,
    refreshKey: PropTypes.number,
    setIgnoreWS: PropTypes.func,
    save: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
    UploadCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { UploadCenter } = this.context;
    UploadCenter.register(
      this.upload.bind(this),
      this.userDidDrop.bind(this),
      () => console.log("upload callback"),
      undefined
    );
    this.codeMirror = React.createRef();

    const urlParams = getJsonFromUrl(window.location);

    this.state = {
      timer: Number(urlParams.timer) || 3,
    };
  }

  componentDidMount() {
    const { editor } = this.state;
    if (editor === undefined) {
      const e = this.codeMirror.current.getCodeMirror();
      e.on("cursorActivity", () => {
        // const line = this.state.editor.getLine(this.state.editor.getCursor().line);
        const from = this.state.editor.getCursor("from");
        const to = this.state.editor.getCursor("to");

        console.log(from, to);

        this.setState({
          from,
          to,
          // line,
        });
      });
      setTimeout(() => {
        e.focus();
        e.setCursor(e.lineCount(), 0);
      }, 200);

      this.setState({
        editor: e,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { editor, from, to } = this.state;
    if (nextProps.refreshKey !== this.props.refreshKey) {
      const toPos = {
        from,
        to,
      };
      editor.setValue(nextProps.value);
      // if (line) {
      //   const search = editor.getSearchCursor(line);
      //   search.findNext();
      //   if (search.atOccurrence) {
      //     toPos.from.line = search.pos.to.line;
      //     toPos.to.line = search.pos.to.line;
      //   }
      // }
      if (toPos && toPos.from && toPos.to) {
        editor.focus();
        editor.setCursor(toPos.to);
        editor.setSelection(toPos.from, toPos.to);
      }
    }
  }

  componentWillUnmount() {
    const { save, setIgnoreWS } = this.props;
    const { timeout } = this.state;

    if (timeout) {
      const now = moment();
      const diff = timeout.diff(now);
      if (diff) {
        setIgnoreWS(false);
        save();
      }
    }
  }

  onChange(content) {
    const { setIgnoreWS, onChange } = this.props;
    const { editor, timer } = this.state;
    onChange(content);
    const from = editor.getCursor("from");
    const to = editor.getCursor("to");
    // const line = editor.getLine(editor.getCursor());
    // const token = this.state.editor.getTokenAt(cursor);
    const timeout = moment().add(timer, "seconds");
    setIgnoreWS(true);
    this.setState({
      timeout,
      from,
      to,
      // line,
    });
  }

  userDidDrop(files) {
    this.setState({ files });
  }

  async upload() {
    const { upload } = this.props;
    const { files, editor } = this.state;
    const doc = editor.getDoc();
    const cursor = doc.getCursor();
    for (const key in files) {
      if (files.hasOwnProperty(key)) {
        const file = files[key];
        upload(file).then((r) => {
          if (imageMime.find((m) => m === file.type)) {
            doc.replaceRange(`![](${r.payload})`, cursor);
            // it is an image
          } else {
            doc.replaceRange(`[[download\n\turl="${r.payload}"\n]]`, cursor);
            // it is something downloadable
          }
        });
      }
    }
  }

  render() {
    const {
      classes,
      value,
      options,
      subscription,
      save,
      setIgnoreWS,
    } = this.props;

    const { editor, timeout } = this.state;

    return (
      <div className={classes.codeMirror}>
        <div style={{ paddingTop: 40 }}>
          {editor ? (
            <MarkdownToolbar editor={editor}>
              <div style={{ fontSize: 10, padding: "2px 4px 2px 4px" }}>
                <div>
                  {subscription ? (
                    <span style={{ color: "#4caf50" }}>
                      Listenning: <b>{subscription.channel}</b>
                    </span>
                  ) : (
                    <span style={{ color: "#f44336" }}>
                      WS Status: Not Connected
                    </span>
                  )}
                </div>
                <div>
                  <span style={{ color: "rgb(155,155,155)" }}>
                    Timer:
                    {timeout ? (
                      <Timer
                        key={timeout}
                        timeout={timeout}
                        callback={() => {
                          save();
                          this.setState({ timeout: undefined });
                          setIgnoreWS(false);
                        }}
                      />
                    ) : (
                      " -"
                    )}
                  </span>
                </div>
              </div>
            </MarkdownToolbar>
          ) : (
            []
          )}
          <CodeMirror
            value={value}
            onChange={this.onChange.bind(this)}
            options={options}
            className="CustomCodemirror"
            ref={this.codeMirror}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(MarkdownEditor);
