export default (theme) => ({
  container: {
    width: "calc(100vw - 40px)",
    height: "calc(100vh - 40px)",
    padding: 20,
    background: theme.palette.background.default,
  },
  h1: {
    fontSize: 48,
    fontWeight: 400,
    marginBottom: 25,
    marginTop: 25,
    opacity: 0.7,
    fontFamily: "realist, sans-serif",
  },
  h2: {
    fontSize: 28,
    fontWeight: 500,
    paddingBottom: 4,
    borderBottom: `1px solid ${
      theme.palette.type === "dark"
        ? "rgba(255,255,255,0.4)"
        : "rgba(0,0,0,0.4)"
    }`,
    width: "100%",
    display: "block",
    marginBottom: 25,
    marginTop: 25,
    fontFamily: "realist, sans-serif",
  },
  h3: {
    fontSize: 26,
    marginBottom: 25,
    marginTop: 25,
    fontFamily: "realist, sans-serif",
  },
  h4: {
    fontSize: 22,
    marginBottom: 25,
    marginTop: 25,
    fontFamily: "realist, sans-serif",
  },
  h5: {
    fontSize: 18,
    marginBottom: 25,
    marginTop: 25,
    fontFamily: "realist, sans-serif",
  },
  h6: {
    fontSize: 16,
    color: theme.palette.text,
    marginBottom: 25,
    marginTop: 25,
    opacity: 0.5,
    fontFamily: "realist, sans-serif",
  },
});
