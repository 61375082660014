import React, { Component } from "react";
import PropTypes from "prop-types";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";

// helpers
import { getShortcodesInString, parseShortcode } from "helpers/shortcodes";

// material-ui
import { withStyles } from "@material-ui/core/styles";

// styles
import styles from "./styles";

import WrapperItem from "./wrapperItem";

class Wrapper extends Component {
  static propTypes = {
    classes: PropTypes.object,
    interpretShortcode: PropTypes.func,
    save: PropTypes.func,
    onChange: PropTypes.func,
    source: PropTypes.string,
    child: PropTypes.string,
  };

  static childContextTypes = {
    Wrapper: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { child } = this.props;

    const parsedShortcodes = getShortcodesInString(child);
    const shortcodes = [];
    for (const key in parsedShortcodes) {
      if (parsedShortcodes.hasOwnProperty(key)) {
        const shortcode = parsedShortcodes[key];
        const parse = parseShortcode(shortcode.replace(/(\[\[|\]\])/gm, ""));
        shortcodes.push({
          string: shortcode,
          parse,
        });
      }
    }

    this.state = {
      shortcodes,
    };

    this.header = React.createRef();
    this.footer = React.createRef();
  }

  getChildContext() {
    return {
      Wrapper: {
        getHeaderPortal: this.getHeaderPortal.bind(this),
        getFooterPortal: this.getFooterPortal.bind(this),
      },
    };
  }

  getHeaderPortal() {
    return this.header.current;
  }

  getFooterPortal() {
    return this.footer.current;
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) return;
    const { source, onChange, child, save } = this.props;
    this.setState(
      ({ shortcodes }) => ({
        shortcodes: arrayMove(shortcodes, oldIndex, newIndex),
      }),
      () => {
        onChange(source.replace(child, this.shortcodesToString()), save);
      }
    );
  };

  shouldCancelStart = (e) => {
    console.log(e);
    if (
      !(
        e.target.id === "grab" ||
        (e.target.nearestViewportElement &&
          e.target.nearestViewportElement.id === "grab")
      )
    ) {
      return true; // Return true to cancel sorting
    }
  };

  shortcodesToString() {
    const { shortcodes } = this.state;
    let str = "";

    for (const key in shortcodes) {
      if (shortcodes.hasOwnProperty(key)) {
        const shortcode = shortcodes[key];
        str += `${shortcode.string}\n`;
      }
    }

    return str;
  }

  render() {
    const { interpretShortcode, save } = this.props;
    const { shortcodes } = this.state;

    const SortableItem = SortableElement((g) => (
      <WrapperItem disabled={!save}>
        {interpretShortcode(g.value.parse, {
          first: Number(g.index) === 0,
          last: Number(g.index) === shortcodes.length - 1,
        })}
      </WrapperItem>
    ));

    const SortableList = SortableContainer(({ items }) => (
      <div>
        {items &&
          items.map((value, index) => (
            <SortableItem
              key={value.id}
              index={index}
              value={value}
              disabled={!save}
            />
          ))}
      </div>
    ));

    return (
      <div>
        <div ref={this.header} style={{ paddingLeft: 15 }} />
        <SortableList
          axis="y"
          items={shortcodes}
          onSortEnd={this.onSortEnd}
          shouldCancelStart={this.shouldCancelStart}
        />
        <div ref={this.footer} />
      </div>
    );
  }
}

export default withStyles(styles)(Wrapper);
