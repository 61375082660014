export default {
  name: "Hide",
  description:
    "Hide text, click on it to reveal. Perfect for password protection",
  example: '[[hide text="This is a secret message"]]',
  attributes: [
    {
      name: "text",
      description: "The content of your want to hide",
      type: "String",
      example: "This is a secret message",
      required: true,
    },
  ],
};
