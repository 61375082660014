import React from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import { DELETE, PUT, GET, POST } from "constants/methods";

// styles
import styles from "./styles";

class TableMethod extends React.Component {
  static propTypes = {
    datum: PropTypes.object,
  };

  render() {
    const { datum } = this.props;

    let color = "gray";

    switch (datum.method) {
      case DELETE:
      case DELETE.toUpperCase():
        color = "danger";
        break;
      case POST:
      case POST.toUpperCase():
        color = "info";
        break;
      case GET:
      case GET.toUpperCase():
        color = "success";
        break;
      case PUT:
      case PUT.toUpperCase():
        color = "rose";
        break;
      default:
    }

    return (
      <Chip
        style={{
          background: color,
          color: "white",
        }}
        label={datum.method}
      />
    );
  }
}

export default withStyles(styles)(TableMethod);
