import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Route, Switch, withRouter } from "react-router-dom";
import ReactGA from "react-ga";

import { DEFAULT, SIGNIN } from "constants/routes";

import { menu } from "config/menu";
import Signup from "components/Signup";
import NotificationCenter from "components/NotificationCenter";
import Upload from "components/Upload";
import ForgotPassword from "components/ForgotPassword";
import ResetPassword from "components/ResetPassword";
import KeyboardEvents from "components/KeyboardEvents";
import UrlActions from "components/UrlActions";
import EnableCookieMessage from "components/EnableCookieMessage";
import { withCookies } from "react-cookie";

// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import classNames from "classnames";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Hidden from "@material-ui/core/Hidden";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Fingerprint from "@material-ui/icons/Fingerprint";
import Collapse from "@material-ui/core/Collapse";
import Grow from "@material-ui/core/Grow";
import Typography from "@material-ui/core/Typography";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Left from "@material-ui/icons/KeyboardArrowLeft";
import Right from "@material-ui/icons/KeyboardArrowRight";

// reactor
import AuthChecker from "components/AuthChecker";
import MessageDrawer from "components/MessageDrawer";
import EnvMessage from "components/EnvMessage";
import Centrifugo from "components/Centrifugo";

// custom
import AppBar from "components/AppBar";

// helpers
import { redirectAccordingToRole } from "helpers/redirect";
import { getCookie, setCookie } from "helpers/cookie";

import {
  GOOGLE_ANALYTICS_KEY,
  ENV_MESSAGE,
  REACT_APP_ROOT_BACKGROUND,
} from "config";

// styles
import styles from "./styles";

class WrapperRootPage extends React.Component {
  static propTypes = {
    routes: PropTypes.array,
    history: PropTypes.object,
    location: PropTypes.object,
    classes: PropTypes.object,
    theme: PropTypes.object,
    allRoutes: PropTypes.array,
    cookies: PropTypes.any,

    // Reducers
    user: PropTypes.object,
    userApps: PropTypes.array,

    // Api
    getCurrentUser: PropTypes.func,
    websocketMessageReceived: PropTypes.func,
    init: PropTypes.func,
    signout: PropTypes.func,
    signup: PropTypes.func,
    validateEmail: PropTypes.func,
    requestResetPassword: PropTypes.func,
    resetPassword: PropTypes.func,
    setOrganizationByDefault: PropTypes.func,
    joinOrganization: PropTypes.func,
    upload: PropTypes.func,
    noteSearch: PropTypes.func,

    // errors
    errors: PropTypes.object,

    // app
    storeResetPasswordToken: PropTypes.func,
    setDarkMode: PropTypes.func,
    app: PropTypes.object,
    stopImpersonate: PropTypes.func,
  };

  static childContextTypes = {
    appBarMenuHook: PropTypes.func,
    onAppLogoClickedHook: PropTypes.func,
  };

  constructor(...args) {
    super(...args);

    this.state = {
      mobileOpen: false,
      anchorEl: null,
      miniActive: false,
      accountOpen: false,
      loading: true,
      lockSidebar: getCookie("lockSidebar") === "true" || false,
      alert: null,
      vh: 0,
    };

    if (GOOGLE_ANALYTICS_KEY) {
      ReactGA.initialize(GOOGLE_ANALYTICS_KEY);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
    window.addEventListener("resize", this.refreshInnerHeight.bind(this));
  }

  getChildContext() {
    return {
      appBarMenuHook: (e) => this.setState({ appBarMenuHook: e }),
      onAppLogoClickedHook: (e) => this.setState({ onAppLogoClickedHook: e }),
    };
  }

  componentDidMount() {
    this.refreshInnerHeight();
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location !== prevProps.location) {
      if (GOOGLE_ANALYTICS_KEY) {
        ReactGA.pageview(window.location.pathname + window.location.search);
      }
    }
  }

  getMenu() {
    const { routes, history, user, location, classes, cookies } = this.props;

    const { miniActive, lockSidebar } = this.state;

    const route = routes.find((r) => r.path === location.pathname);

    const JSX = [];
    for (const k in menu) {
      if (menu.hasOwnProperty(k)) {
        const menuItem = menu[k];
        const Icon = menuItem.icon;

        const currentRoute = routes.find((e) => e.path === menuItem.path);

        if (currentRoute && currentRoute.onEnter && currentRoute.onEnter()) {
          let selected = false;
          if (menuItem.path && menuItem.path === route.path) {
            selected = true;
          }

          JSX.push(
            <ListItem
              selected={selected && menuItem.nested === undefined}
              key={menuItem.path}
              button
              divider={menuItem.divider || Number(k) === menu.length - 1}
              onClick={() => {
                if (menuItem.path) {
                  history.push(menuItem.path);
                  if (menuItem.nested) {
                    if (this.state[menuItem.label] === undefined) {
                      this.setState({ [menuItem.label]: true });
                    } else {
                      this.setState((prevState) => ({
                        [menuItem.label]: !prevState[menuItem.label],
                      }));
                    }
                  }
                }
              }}
            >
              <ListItemIcon>
                <Icon
                  className={
                    selected && menuItem.nested === undefined
                      ? classes.selected
                      : undefined
                  }
                />
              </ListItemIcon>
              <ListItemText
                classes={{
                  primary:
                    selected && menuItem.nested === undefined
                      ? classes.selected
                      : undefined,
                }}
                primary={menuItem.label}
              />
              {menuItem.nested && (
                <div>
                  {this.state[menuItem.label] ? <ExpandLess /> : <ExpandMore />}
                </div>
              )}
            </ListItem>
          );

          if (menuItem.nested) {
            const nestedMenu = [];
            for (const m in menuItem.nested) {
              if (menuItem.nested.hasOwnProperty(m)) {
                const n = menuItem.nested[m];
                const NestedIcon = n.icon;
                if (n.path && n.path === route.path) {
                  selected = true;
                } else {
                  selected = false;
                }

                nestedMenu.push(
                  <ListItem
                    selected={selected}
                    key={`nested_${n.path}`}
                    button
                    className={classes.nested}
                    divider={n.divider || Number(k) === menu.length - 1}
                    onClick={() => {
                      if (n.path) {
                        history.push(n.path);
                      }
                    }}
                  >
                    <ListItemIcon>
                      <NestedIcon
                        className={selected ? classes.selected : undefined}
                      />
                    </ListItemIcon>
                    <ListItemText
                      inset
                      classes={{
                        primary: selected ? classes.selected : undefined,
                      }}
                      primary={n.label}
                    />
                  </ListItem>
                );
              }
            }

            JSX.push(
              <Collapse
                key={`collapse_${menuItem.path}`}
                in={
                  this.state[menuItem.label] !== undefined
                    ? this.state[menuItem.label]
                    : menuItem.nested.find((n) => n.path === route.path) !==
                      undefined
                }
                timeout="auto"
                unmountOnExit
              >
                <List
                  component="div"
                  disablePadding
                  className={classes.background}
                >
                  {nestedMenu}
                </List>
              </Collapse>
            );
          }
        }
      }
    }

    return (
      <div>
        <List className={classes.list} component="nav">
          {JSX}
          {user.services === undefined && (
            <ListItem
              key={"signin"}
              button
              divider
              onClick={() => {
                history.push(SIGNIN);
              }}
            >
              <ListItemIcon>
                <Fingerprint />
              </ListItemIcon>
              <ListItemText primary={"Signin"} />
            </ListItem>
          )}
        </List>
        <div
          style={{
            height: 40,
            position: "absolute",
            bottom: 0,
            width: "100%",
            cursor: "pointer",
            background: lockSidebar ? "rgba(155,155,155,0.2)" : undefined,
          }}
          onClick={() => {
            setCookie(cookies, "lockSidebar", !lockSidebar);
            this.setState({ lockSidebar: !lockSidebar });
          }}
        >
          <Grid
            container
            style={{
              height: 39,
              borderTop: "solid 1px rgba(155,155,155,0.3)",
            }}
            alignItems="center"
            justify="center"
          >
            <Grid item>
              {!miniActive && !lockSidebar ? (
                <Right className={classes.darkModeFix} />
              ) : (
                <Left className={classes.darkModeFix} />
              )}
            </Grid>
            {miniActive || lockSidebar ? (
              <Grow in>
                <Grid item>
                  <Typography>
                    {!lockSidebar ? "Keep Sidebar Open" : "Auto Collapse"}
                  </Typography>
                </Grid>
              </Grow>
            ) : (
              []
            )}
          </Grid>
        </div>
      </div>
    );
  }

  handleDrawerToggle = () => {
    this.setState((prevState) => ({ mobileOpen: !prevState.mobileOpen }));
  };

  handleClick = () => {
    this.setState((prevState) => ({ open: !prevState.open }));
  };

  refreshInnerHeight() {
    // We execute the same script as before
    console.log("refreshInnerHeight");
    const vh = window.innerHeight * 0.01;
    this.setState({ vh });
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  injectRoutes() {
    const { routes } = this.props;

    return (
      <div style={{ flexGrow: 1, display: "flex", position: "relative" }}>
        <Switch>
          {routes.map((route) =>
            route.path === DEFAULT ? (
              <Route key={route.path} component={route.component} />
            ) : (
              <Route
                key={route.path}
                path={route.path}
                component={route.component}
              />
            )
          )}
        </Switch>
      </div>
    );
  }

  render() {
    const {
      classes,
      location,
      routes,
      history,
      getCurrentUser,
      init,
      errors,
      signup,
      validateEmail,
      requestResetPassword,
      signout,
      storeResetPasswordToken,
      app,
      resetPassword,
      allRoutes,
      user,
      theme,
      setDarkMode,
      stopImpersonate,
      userApps,
      setOrganizationByDefault,
      websocketMessageReceived,
      joinOrganization,
      upload,
      noteSearch,
    } = this.props;

    const { loading, alert, lockSidebar } = this.state;

    const route = routes.find((r) => r.path === location.pathname);

    return (
      <div className="root">
        <AuthChecker
          routes={allRoutes}
          history={history}
          location={location}
          user={user}
          getCurrentUser={getCurrentUser}
          onReady={() => {
            this.setState({ loading: false });
          }}
          signinRoute={SIGNIN}
          redirectAccordingToRole={redirectAccordingToRole}
          init={init}
        >
          <NotificationCenter errors={errors}>
            <Centrifugo websocketMessageReceived={websocketMessageReceived}>
              <KeyboardEvents>
                <Signup
                  history={history}
                  signup={signup}
                  validateEmail={validateEmail}
                >
                  <UrlActions
                    location={location}
                    pathname={`${location.pathname}`}
                    history={history}
                    validateEmail={validateEmail}
                    user={user}
                    loading={loading}
                    storeResetPasswordToken={storeResetPasswordToken}
                  >
                    <ForgotPassword
                      history={history}
                      requestResetPassword={requestResetPassword}
                    >
                      <ResetPassword
                        token={app.resetPasswordToken}
                        resetPassword={resetPassword}
                        storeResetPasswordToken={storeResetPasswordToken}
                      >
                        <Upload upload={upload}>
                          {loading ? (
                            <Grid
                              container
                              className={classes.loading}
                              alignContent="center"
                              alignItems="center"
                            >
                              <Grid
                                item
                                xs={12}
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                <CircularProgress
                                  style={{ color: "#ffffff" }}
                                />
                              </Grid>
                            </Grid>
                          ) : (
                            <MessageDrawer
                              background={REACT_APP_ROOT_BACKGROUND}
                            >
                              <EnvMessage envMessage={ENV_MESSAGE} />
                              <div
                                style={{
                                  display: "flex",
                                  flexGrow: 1,
                                }}
                              >
                                {route && route.withSidebar && (
                                  <div
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    <Hidden mdUp>
                                      <Drawer
                                        variant="temporary"
                                        anchor={
                                          theme.direction === "rtl"
                                            ? "right"
                                            : "left"
                                        }
                                        open={this.state.mobileOpen}
                                        onClose={this.handleDrawerToggle}
                                        classes={{
                                          paper: classes.drawerPaper,
                                        }}
                                        ModalProps={{
                                          keepMounted: true,
                                        }}
                                      >
                                        <div
                                          className={classes.drawerContainer}
                                        >
                                          {this.getMenu()}
                                        </div>
                                      </Drawer>
                                    </Hidden>
                                    <Hidden smDown>
                                      <Drawer
                                        onMouseEnter={() => {
                                          this.setState({ miniActive: true });
                                        }}
                                        onMouseLeave={() => {
                                          this.setState({ miniActive: false });
                                        }}
                                        variant="permanent"
                                        open={lockSidebar}
                                        classes={{
                                          paper: classNames(
                                            classes.drawerPaper,
                                            !this.state.miniActive &&
                                              !lockSidebar &&
                                              classes.drawerPaperClose
                                          ),
                                        }}
                                        style={{
                                          height: "100%",
                                        }}
                                      >
                                        <div
                                          className={classes.drawerContainer}
                                        >
                                          {this.getMenu()}
                                        </div>
                                      </Drawer>
                                    </Hidden>
                                  </div>
                                )}
                                <div
                                  style={{
                                    flexGrow: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  {route && route.withAppBar && (
                                    <AppBar
                                      onMenuOpen={() => {
                                        this.handleDrawerToggle();
                                        if (this.state.appBarMenuHook) {
                                          this.state.appBarMenuHook();
                                        }
                                      }}
                                      onAppLogoClicked={() => {
                                        if (this.state.onAppLogoClickedHook) {
                                          this.state.onAppLogoClickedHook();
                                        }
                                      }}
                                      noteSearch={noteSearch}
                                      user={user}
                                      userApps={userApps}
                                      history={history}
                                      app={app}
                                      setDarkMode={setDarkMode}
                                      stopImpersonate={stopImpersonate}
                                      setOrganizationByDefault={
                                        setOrganizationByDefault
                                      }
                                      joinOrganization={joinOrganization}
                                      getCurrentUser={getCurrentUser}
                                      signout={signout}
                                    />
                                  )}
                                  {this.injectRoutes()}
                                  {alert}
                                </div>
                              </div>
                            </MessageDrawer>
                          )}
                        </Upload>
                      </ResetPassword>
                    </ForgotPassword>
                  </UrlActions>
                </Signup>
              </KeyboardEvents>
            </Centrifugo>
          </NotificationCenter>
        </AuthChecker>
        <EnableCookieMessage
          open={!navigator.cookieEnabled}
          close={() => this.setState({ enableCookie: false })}
        />
      </div>
    );
  }
}

export default withCookies(
  withRouter(
    connect()(withStyles(styles, { withTheme: true })(WrapperRootPage))
  )
);
