import { GET, PUT, DELETE, POST, DATA } from "constants/methods";

import { REACT_APP_API_PREFIX } from "config/env";

const service = "interdoc";
const notebooks = `${REACT_APP_API_PREFIX}/${service}/notebooks`;
const organizations = `${REACT_APP_API_PREFIX}/${service}/organizations`;

export function getNotebooks() {
  const url = `${notebooks}`;
  return {
    method: GET,
    url,
  };
}

export function importNote(notebookID, body) {
  const url = `${notebooks}/${notebookID}/import`;

  return {
    method: POST,
    url,
    body,
  };
}

export function getNotebookById(id) {
  const url = `${notebooks}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function getNotebookUsers(id) {
  const url = `${notebooks}/${id}/users`;
  return {
    method: GET,
    url,
  };
}

export function getInterdocUsers() {
  const url = `${organizations}/users`;
  return {
    method: GET,
    url,
  };
}

export function updateNotebook(id, body) {
  const url = `${notebooks}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteNotebook(id) {
  const url = `${notebooks}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createNotebook(body) {
  const url = `${notebooks}`;
  return {
    method: POST,
    url,
    body,
  };
}
