export default {
  name: "Message",
  description:
    "Display an important information in a dedicated box. Style changes depending of the level.",
  example: '[[message text="Hello World" level="notes"]]',
  attributes: [
    {
      name: "text",
      description: "The content of your message",
      type: "String",
      example: "Hello World",
      required: true,
    },
    {
      name: "level",
      description: "Level of your message (style changes)",
      type: "String",
      example: "notes | tip | error | warning | comments",
      required: true,
    },
  ],
};
