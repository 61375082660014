export default () => ({
  hidden: {
    filter: "blur(4px)",
    transition: "filter 0.5s",
    cursor: "pointer",
  },
  visible: {
    filter: "blur(0px)",
    transition: "filter 0.5s",
    cursor: "pointer",
  },
});
