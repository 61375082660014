import { ERROR_NOTIFICATION } from "constants/errors";

import { REACT_APP_SUPPORT_EMAIL } from "config";

export default (state = {}, action) => {
  if (action.type === ERROR_NOTIFICATION) {
    const { payload } = action;
    let isError = false;
    let isWarning = false;

    if (payload === undefined) {
      return state;
    }

    if (payload.status === 401) {
      return state;
    }

    // 4xx errors are warning (client side)
    if (payload.status <= 499 && payload.status >= 400) {
      isWarning = true;
    }

    // 5xx errors are erros (server side)
    if (payload.status <= 599 && payload.status >= 500) {
      isError = true;
    }

    const error = {
      notification: {
        message: {
          title: payload.description,
          subtitle: payload.resolution,
          body: `${payload.status} ${payload.errorCode}`,
          error: isError,
          warning: isWarning,
          timestamp: new Date().getTime(),
        },
        actions: {
          confirm: {
            label: payload.action,
            level: isError ? "error" : "warning",
            callback: () => {
              window.location.href = `mailto:${REACT_APP_SUPPORT_EMAIL}?subject=[Portal] ${new Date().getTime()} - ${
                payload.status
              } ${payload.errorCode}&body=${
                payload.function
              }%0D%0A%0D%0AStack:${payload.stack.map(
                (s) => `%0D%0A${s}`
              )}%0D%0A%0D%0ABrowser Info:%0D%0A${
                window.navigator.appCodeName
              }%0D%0A${window.navigator.appName}%0D%0A${
                window.navigator.appVersion
              }`; //eslint-disable-line
            },
          },
        },
      },
    };

    return error;
  }

  return state;
};
