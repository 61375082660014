export default () => ({
  shortcodeMessage: {
    fontFamily: "source-code-pro, monospace;",
    fontSize: 12,
    color: "white",
    background: "#f44336",
    border: "solid 1px #e53935",
    borderRadius: 4,
    padding: "0px 2px 0px 2px",
  },
  shortcodeDoc: {
    fontFamily: "source-code-pro, monospace;",
    fontSize: 12,
    color: "white",
    background: "#2196f3",
    border: "solid 1px #1e88e5",
    borderRadius: 4,
    padding: "0px 2px 2px 2px",
  },
  missing: {
    background: "white",
    color: "#2196f3",
    borderRadius: 8,
    padding: "0px 2px 0px 2px",
  },
});
