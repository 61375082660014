export default (theme) => ({
  container: {
    padding: 5,
  },
  link: {
    textDecoration: "none",
    color:
      theme.palette.type === "dark"
        ? theme.palette.primary.main[300]
        : undefined,
    // color: theme.palette.primary.main[500],
  },
});
