import { GET_CURRENT_USER } from "constants/users";

import { GET_MY_SIGNATORIES } from "constants/signatories";

import { SIGNIN } from "constants/sessions";

export default (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case `${GET_CURRENT_USER}_SUCCESS`:
    case `${SIGNIN}_SUCCESS`:
      return payload;
    case `${GET_MY_SIGNATORIES}_SUCCESS`:
      if (state) {
        const newState = Object.assign({}, state);
        newState.signatories = payload;
        return newState;
      }
      return state;
    default:
      return state;
  }
};
