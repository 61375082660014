import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import Fab from "@material-ui/core/Fab";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Copy from "@material-ui/icons/AddToPhotosTwoTone";
import Close from "@material-ui/icons/Close";
import Done from "@material-ui/icons/Done";
import Table from "components/Table";
import { copyTextToClipboard } from "helpers/url";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Select from "react-select";
// styles
import styles from "./styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

class Signatory extends Component {
  static propTypes = {
    classes: PropTypes.object,
    note: PropTypes.object,
    onClose: PropTypes.func,
    getSignatories: PropTypes.func,
    createSignatory: PropTypes.func,
    deleteSignatory: PropTypes.func,
    users: PropTypes.array,
    open: PropTypes.bool,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: true,
      signatures: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.getSignatures();
    }
  }

  async getSignatures() {
    const { getSignatories, note } = this.props;
    this.setState({ loading: true });
    const filters = [
      {
        name: "noteID",
        comparison: "eq",
        value: note.id,
      },
    ];

    const resp = await getSignatories(`?filters=${JSON.stringify(filters)}`);
    if (resp.success) {
      const signatures = resp.payload;
      this.setState({ signatures, loading: false });
    }
  }

  async deleteSignature(id) {
    const { deleteSignatory } = this.props;
    await deleteSignatory(id);
    this.getSignatures();
  }

  async createSignature(e) {
    const { note, createSignatory } = this.props;
    const { value } = e;

    await createSignatory({
      userUID: value.uuid,
      noteID: note.id,
    });

    this.getSignatures();
  }

  render() {
    const { classes, onClose, open, note, users } = this.props;

    const { signatures, loading } = this.state;

    const options = []; //eslint-disable-line
    for (const key in users) {
      if (users.hasOwnProperty(key)) {
        const u = users[key];
        options.push({
          value: u,
          label: `${u.firstName} ${u.lastName}`,
        });
      }
    }

    const href = `${window.location.origin}${window.location.pathname}?bookmark=${note.id}&signatureRequired`;

    return (
      <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <div className={classes.container}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  gutterBottom
                  variant={"h1"}
                  classes={{
                    h1: classes.h1,
                  }}
                >
                  {note.name} Signatory
                </Typography>
                <Typography
                  style={{ cursor: "pointer" }}
                  variant="caption"
                  color="textSecondary"
                  onClick={() =>
                    window.open(
                      `https://api.wa.archer-app.com/v3/interdoc/signatories/csv?filters=[{%22name%22:%22noteID%22,%22comparison%22:%22eq%22,%22value%22:${note.id}}]`
                    )
                  }
                >
                  <u>Export in CSV</u>
                </Typography>
              </div>
              <Typography
                gutterBottom
                variant={"h6"}
                classes={{
                  h6: classes.h6,
                }}
              >
                Ask someone to read the note and sign it. You can also share the
                following magic link. Any user who use the link will have to
                sign the document.
              </Typography>
              <Grid
                style={{
                  border: "solid 1px rgba(155,155,155,0.3)",
                  background: "rgba(155,155,155,0.1)",
                  margin: 4,
                  padding: 4,
                  width: "calc(100% - 8px)",
                  position: "relative",
                }}
                container
                alignItems="center"
                spacing={1}
              >
                <Grid item style={{ width: "calc(100% - 60px)" }}>
                  {href}
                </Grid>
                <Grid item style={{ width: 30 }}>
                  <Tooltip title="Copy to Clipboard">
                    <IconButton
                      style={{
                        padding: 5,
                      }}
                      onClick={() => {
                        copyTextToClipboard(href);
                      }}
                    >
                      <Copy style={{ fontSize: 18 }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {!loading ? (
                <React.Fragment>
                  <Select
                    placeholder={"Invite Someone to Sign"}
                    isClearable
                    onChange={this.createSignature.bind(this)}
                    options={options}
                  />
                  <br />
                  <Table
                    data={signatures}
                    meta={[
                      {
                        path: "firstName",
                        title: "Full Name",
                        numeric: false,
                        transform: (t, d) => `${d.firstName} ${d.lastName}`,
                      },
                      {
                        path: "signatureURL",
                        title: "Signature",
                        numeric: false,
                        render: (t, d) => (
                          <div>
                            {d.signed ? (
                              <Tooltip
                                title={moment(d.signedDate).format(
                                  "MM/DD/YYYY HH:mm"
                                )}
                              >
                                <img
                                  src={t}
                                  style={{ height: 50 }}
                                  alt="signature"
                                />
                              </Tooltip>
                            ) : (
                              <Typography
                                variant="caption"
                                color="textSecondary"
                              >
                                Not Signed
                              </Typography>
                            )}
                          </div>
                        ),
                      },
                      {
                        path: "id",
                        width: 40,
                        render: (t) => (
                          <IconButton
                            style={{ padding: 4 }}
                            onClick={() => this.deleteSignature(t)}
                          >
                            <Close style={{ fontSize: "18px" }} />
                          </IconButton>
                        ),
                      },
                    ]}
                    title={"Signatures"}
                    onRowSelect={(p) => console.log(p)}
                  />
                </React.Fragment>
              ) : (
                <CircularProgress />
              )}
            </Grid>
          </Grid>
          <div
            style={{
              position: "fixed",
              bottom: 20,
              right: 20,
            }}
          >
            <Fab
              style={{
                background: "#8bc34a",
                color: "white",
              }}
              variant="extended"
              onClick={onClose}
            >
              <Done /> Done
            </Fab>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default withStyles(styles)(Signatory);
