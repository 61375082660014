import React, { Component } from "react";
import PropTypes from "prop-types";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";

// custom
import NotebookListItem from "components/NotebookListItem";

// styles
import styles from "./styles";

class NotebookCollection extends Component {
  static propTypes = {
    notebooks: PropTypes.array,
    selectedNotebook: PropTypes.object,
    onSelect: PropTypes.func,
    onEdit: PropTypes.func,
    jiggle: PropTypes.bool,
    onRearrange: PropTypes.func,
    updateNotebook: PropTypes.func,
    refresh: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { notebooks } = this.props;
    this.state = {
      notebooks,
      orderChanged: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.orderChanged) {
      return true;
    }
    return !(nextProps.jiggle === this.props.jiggle);
  }

  onSortEnd = async ({ oldIndex, newIndex }) => {
    const { updateNotebook, refresh } = this.props;
    const notebookToUpdate = this.state.notebooks[oldIndex];
    updateNotebook(notebookToUpdate.id, { order: newIndex }).then(refresh);
    this.setState(({ notebooks }) => ({
      notebooks: arrayMove(notebooks, oldIndex, newIndex),
      orderChanged: true,
    }));
    setTimeout(() => this.setState({ orderChanged: false }), 200);
  };

  render() {
    const {
      selectedNotebook,
      onSelect,
      onEdit,
      onRearrange,
      jiggle,
    } = this.props;

    const { notebooks } = this.state;

    const SortableItem = SortableElement((g) => (
      <div style={{ zIndex: 99999 }}>
        <NotebookListItem
          key={g.value.id}
          n={g.value}
          selectedNotebook={selectedNotebook}
          onSelect={onSelect}
          jiggle={jiggle}
          onRearrange={onRearrange}
          onEdit={onEdit}
        />
      </div>
    ));

    const SortableList = SortableContainer(({ items }) => (
      <List
        aria-label="main mailbox folders"
        style={{ padding: 0 }}
        divider
        dense
      >
        {items &&
          items.map((value, index) => (
            <SortableItem
              disabled={!jiggle}
              key={value.id}
              index={index}
              value={value}
            />
          ))}
      </List>
    ));

    return (
      <SortableList axis="y" items={notebooks} onSortEnd={this.onSortEnd} />
    );
  }
}

export default withStyles(styles)(NotebookCollection);
