import { networkAction } from "helpers/network/networkAction";

import {
  GET_USER_NOTEBOOKS,
  GET_USER_NOTEBOOK_BY_ID,
  DELETE_USER_NOTEBOOK,
  UPDATE_USER_NOTEBOOK,
  CREATE_USER_NOTEBOOK,
} from "constants/userNotebooks";

import * as Api from "api";

export const getUserNotebooks = () => async (dispatch) =>
  networkAction(dispatch, GET_USER_NOTEBOOKS, Api.getUserNotebooks, []);

export const getUserNotebookById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_USER_NOTEBOOK_BY_ID, Api.getUserNotebookById, [
    id,
  ]);

export const deleteUserNotebook = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_USER_NOTEBOOK, Api.deleteUserNotebook, [id]);

export const createUserNotebook = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_USER_NOTEBOOK, Api.createUserNotebook, [body]);

export const updateUserNotebook = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_USER_NOTEBOOK, Api.updateUserNotebook, [
    id,
    body,
  ]);
