export default (theme) => ({
  leftDrawer: {
    width: 226,
    height: "calc(calc(var(--vh, 1vh) * 100) - 47px)",
    background: theme.palette.background.default,
    position: "absolute",
    zIndex: 99,
  },
  content: {
    marginLeft: 226,
    maxWidth: "calc(100vw - 226px)",
    flex: 1,
    zIndex: 0,
    background: theme.palette.background.paper,
  },
  contentMobile: {
    background: theme.palette.background.paper,
    width: "100vw",
  },
  leftDrawerMobile: {
    width: 226,
    background: theme.palette.background.default,
    zIndex: 0,
  },
  tabs: {
    background: "white",
  },
  tabsIndicator: {
    backgroundColor: theme.palette.text,
  },
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  tabsRoot: {
    background: theme.palette.background.paper,
    borderBottom: "solid 1px rgba(155,155,155,0.3)",
    padding: "0px 20px 0px 20px",
  },
  tabRoot: {
    textTransform: "initial",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: theme.palette.text,
      opacity: 1,
    },
    "&$tabSelected": {
      color: theme.palette.text,
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: theme.palette.text,
    },
  },
  tabSelected: {},
});
