import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { SketchField, Tools } from "react-sketch";
import Grid from "@material-ui/core/Grid";

import styles from "./styles";

class Signature extends Component {
  static propTypes = {
    classes: PropTypes.object,
    height: PropTypes.number,
    width: PropTypes.number,
    signature: PropTypes.any,
    onSignatureReady: PropTypes.func,
  };

  static contextTypes = {
    UploadCenter: PropTypes.object,
  };

  state = {
    value: null,
  };

  onSubmit() {
    this.sketch.clear();
    this.setState({
      value: null,
    });
  }

  async upload() {
    const { onSignatureReady } = this.props;
    const { UploadCenter } = this.context;
    const url = this.sketch.toDataURL();

    fetch(url)
      .then((res) => res.blob())
      .then(async (blob) => {
        const resp = await UploadCenter.upload(blob);
        if (resp.success) {
          onSignatureReady(resp.payload);
        }
      });
    this.sketch.clear();
  }

  handleChange(e) {
    this.setState({
      value: e.target.value,
    });
  }

  render() {
    const { classes, height, width, signature } = this.props;

    const { value } = this.state;

    return (
      <div style={{ width, height: height + 50 }}>
        {signature ? (
          <img
            alt="signatureImage"
            src={signature}
            className={classes.image}
            style={{
              height,
              width,
            }}
          />
        ) : (
          <div
            className={classes.signatureBlock}
            style={{
              height,
              width,
            }}
          >
            <SketchField
              ref={(c) => {
                this.sketch = c;
              }}
              width={width}
              height={height}
              tool={Tools.Pencil}
              lineColor="black"
              lineWidth={3}
              onChange={this.handleChange.bind(this)}
              value={value}
              imageFormat="png"
            />
            <Grid
              container
              justify="flex-end"
              spacing={1}
              className={classes.grid}
            >
              <Grid item>
                <Button
                  variant="contained"
                  margin="10px"
                  onClick={this.onSubmit.bind(this)}
                >
                  Reset
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.upload.bind(this)}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(Signature);
