import React, { Component } from "react";
import PropTypes from "prop-types";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import NoteCover from "components/NoteCover";

// styles
import styles from "./styles";

class NoteCollection extends Component {
  static propTypes = {
    classes: PropTypes.object,
    notes: PropTypes.array,
    signatories: PropTypes.object,
    history: PropTypes.object,
    updateNote: PropTypes.func,
    jiggle: PropTypes.bool,
    onRearrange: PropTypes.func,
    archived: PropTypes.bool,
  };

  constructor(...args) {
    super(...args);
    const { notes } = this.props;
    this.state = {
      notes,
      orderChanged: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.orderChanged) {
      return true;
    }
    return !(nextProps.jiggle === this.props.jiggle);
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { updateNote } = this.props;
    const noteToUpdate = this.state.notes[oldIndex];
    updateNote(noteToUpdate.id, { order: newIndex });
    this.setState(({ notes }) => ({
      notes: arrayMove(notes, oldIndex, newIndex),
      orderChanged: true,
    }));
    setTimeout(() => this.setState({ orderChanged: false }), 200);
  };

  render() {
    const { signatories, onRearrange, history, jiggle, archived } = this.props;

    const { notes } = this.state;

    const SortableItem = SortableElement((g) => (
      <Grid item>
        <div style={{ width: 160, padding: 12 }}>
          <NoteCover
            note={g.value}
            history={history}
            jiggle={jiggle}
            archived={archived}
            onRearrange={onRearrange}
            signatory={
              signatories && signatories.find((s) => s.noteID === g.value.id)
            }
          />
        </div>
      </Grid>
    ));

    const SortableList = SortableContainer(({ items }) => (
      <Grid container>
        {items &&
          items.map((value, index) => (
            <SortableItem
              disabled={!jiggle}
              key={value.id}
              index={index}
              value={value}
            />
          ))}
      </Grid>
    ));

    return (
      <div>
        <SortableList axis="xy" items={notes} onSortEnd={this.onSortEnd} />
      </div>
    );
  }
}

export default withStyles(styles)(NoteCollection);
