import React, { Component } from "react";
import PropTypes from "prop-types";
import { CirclePicker } from "react-color";

// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import SaveIcon from "@material-ui/icons/Check";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

// constants
import { NOTEBOOKS, NOTE } from "constants/routes";
import { REACT_APP_FRONT } from "config";

// styles
import styles from "./styles";

class FormDuplicate extends Component {
  static propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
    fullScreen: PropTypes.bool,

    close: PropTypes.func,
    open: PropTypes.bool,

    note: PropTypes.object,

    duplicateNote: PropTypes.func,
    notebooks: PropTypes.number,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      name: "",
      description: "",
      author: "",
      color: "",
      version: "1.0.0",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.init(nextProps.note);
    }
  }

  handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  init(note) {
    this.setState({
      loading: false,
      name: note ? note.name : "",
      description: note ? note.description : "",
      color: note ? note.color : "",
      author: note ? note.author : "",
      version: note ? note.version : "",
      notebookID: note ? note.notebookID : undefined,
    });
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle: "If you delete this note, you won't be able to recover it.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteNote, note, close, history } = this.props;

    const { NotificationCenter } = this.context;

    const resp = await deleteNote(note.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "Note has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      close();
      setTimeout(NotificationCenter.hide, 1500);
      history.push(NOTEBOOKS);
    }
  }

  async save() {
    const { duplicateNote, note, close } = this.props;

    const {
      name,
      description,
      color,
      author,
      version,
      notebookID,
    } = this.state;

    this.setState({ loading: true });
    const resp = await duplicateNote(note.id, {
      name,
      description,
      color,
      author,
      version,
      notebookID,
    });
    close();
    this.setState({ loading: false });
    window.location.replace(
      `${REACT_APP_FRONT}${NOTE}?bookmark=${resp.payload.id}`
    );
  }

  render() {
    const { classes, fullScreen, open, note, close, notebooks } = this.props;

    const {
      name,
      description,
      author,
      color,
      loading,
      version,
      notebookID,
    } = this.state;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        onClose={close}
        aria-labelledby="responsive-dialog-title"
        classes={{
          paper: classes.paper,
        }}
      >
        {loading ? (
          <div className={classes.progressContainer}>
            <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
            <CircularProgress />
          </div>
        ) : (
          <div>
            <DialogTitle id="responsive-dialog-title">
              Duplicate Note
            </DialogTitle>
            <DialogContent>
              <div style={{ textAlign: "center" }}>
                <img
                  src="https://storage.googleapis.com/wid-arranger/interdoc/7477e61d-ab2a-429e-8f16-ecd408ef4553/copier.png"
                  alt="copier"
                />
              </div>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    id="name"
                    label="Name"
                    className={classes.textField}
                    value={name}
                    onChange={this.handleChange("name")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="author"
                    label="Author"
                    className={classes.textField}
                    value={author}
                    onChange={this.handleChange("author")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="name"
                    label="Description"
                    className={classes.textField}
                    value={description}
                    multiline
                    onChange={this.handleChange("description")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="version"
                    label="Version"
                    className={classes.textField}
                    value={version}
                    multiline
                    onChange={this.handleChange("version")}
                  />
                </Grid>
                {note ? (
                  <Grid item xs={12}>
                    <FormControl className={classes.formControl} fullWidth>
                      <InputLabel htmlFor="group-simple">Notebook</InputLabel>
                      <Select
                        inputProps={{
                          name: "Change Notebook",
                          id: "group-simple",
                        }}
                        value={notebookID}
                        onChange={this.handleChange("notebookID")}
                      >
                        {notebooks.map((n) => (
                          <MenuItem key={`notebook_${n.id}`} value={n.id}>
                            {n.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                ) : (
                  []
                )}
                <Grid item xs={12}>
                  <Typography variant="caption" color="textSecondary">
                    Cover Color
                  </Typography>
                  <CirclePicker
                    color={color}
                    onChange={(c) => {
                      this.setState({
                        color: c.hex,
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <Button
                color="primary"
                className={classes.save}
                autoFocus
                onClick={this.save.bind(this)}
              >
                <SaveIcon className={classes.leftIcon} />
                Duplicate
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormDuplicate));
