import React, { Component } from "react";
import PropTypes from "prop-types";
import Select from "react-select";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import Add from "@material-ui/icons/Add";
import Done from "@material-ui/icons/Done";
import Chip from "@material-ui/core/Chip";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Hidden from "@material-ui/core/Hidden";

// custom
import FormNote from "components/FormNote";
import FormNotebook from "components/FormNotebook";
import UserNotebook from "components/UserNotebook";
import NoteCollection from "components/NoteCollection";
import NotebookCollection from "components/NotebookCollection";

// reactor
import Page from "components/Page";

// constants
import { EDITOR, roleDescriptions } from "constants/noteRoles";

// styles
import styles from "./styles";

class WrapperNotebooksPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    notebooks: PropTypes.array,
    selectedNotebook: PropTypes.object,
    urlParams: PropTypes.object,
    createNote: PropTypes.func,
    onSelect: PropTypes.func,
    updateNotebook: PropTypes.func,
    deleteNotebook: PropTypes.func,
    createNotebook: PropTypes.func,
    createUserNotebook: PropTypes.func,
    updateUserNotebook: PropTypes.func,
    deleteUserNotebook: PropTypes.func,
    importNote: PropTypes.func,
    refresh: PropTypes.func,
    updateNote: PropTypes.func,
    refreshKey: PropTypes.number,
    users: PropTypes.array,
    signatories: PropTypes.array,
  };

  static contextTypes = {
    appBarMenuHook: PropTypes.func,
    UploadCenter: PropTypes.object,
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    const { UploadCenter } = this.context;
    this.state = {
      formNoteOpen: false,
      formNotebookOpen: false,
      menuOpen: false,
      noteJiggle: false,
      notebookJiggle: false,
      index: Number(urlParams.index) || 0,
    };

    UploadCenter.register(
      () => console.log("upload"),
      this.userDidDrop.bind(this),
      () => console.log("upload callback"),
      undefined
    );
  }

  componentWillMount() {
    const { appBarMenuHook } = this.context;
    appBarMenuHook(this.onMenuToggle.bind(this));
  }

  onMenuToggle() {
    this.setState((prevState) => ({ menuOpen: !prevState.menuOpen }));
  }

  getStepContent(index) {
    const {
      history,
      selectedNotebook,
      createUserNotebook,
      updateUserNotebook,
      deleteUserNotebook,
      users,
      signatories,
      refresh,
      refreshKey,
      updateNote,
    } = this.props;

    const { noteJiggle } = this.state;

    const options = []; //eslint-disable-line
    for (const key in users) {
      if (users.hasOwnProperty(key)) {
        const u = users[key];
        options.push({
          value: u,
          label: `${u.firstName} ${u.lastName}`,
        });
      }
    }

    if (selectedNotebook) {
      console.log(
        selectedNotebook,
        selectedNotebook.notes.filter((n) => n.archived)
      );
    }

    switch (index) {
      case 0:
        return (
          <NoteCollection
            notes={
              selectedNotebook &&
              selectedNotebook.notes.filter((n) => !n.archived)
            }
            signatories={signatories}
            updateNote={updateNote}
            history={history}
            jiggle={noteJiggle}
            onRearrange={
              selectedNotebook && selectedNotebook.role === EDITOR
                ? () => this.setState({ noteJiggle: true })
                : () => console.log("unauthorized")
            }
            key={`${refreshKey}${
              selectedNotebook && selectedNotebook.id
            }${index}`}
          />
        );
      case 1:
        return (
          <NoteCollection
            archived
            notes={
              selectedNotebook &&
              selectedNotebook.notes.filter((n) => n.archived)
            }
            signatories={signatories}
            updateNote={updateNote}
            history={history}
            onRearrange={() => console.log("unauthorized")}
            key={`${refreshKey}${
              selectedNotebook && selectedNotebook.id
            }${index}`}
          />
        );
      case 2:
        return (
          <div
            key={selectedNotebook && selectedNotebook.id}
            style={{ padding: 20 }}
          >
            <div style={{ paddingBottom: 10 }}>
              <Select
                placeholder={"Add user to notebook"}
                isClearable
                onChange={this.addUserToNotebook.bind(this)}
                options={options}
              />
            </div>
            {selectedNotebook &&
              selectedNotebook.users &&
              selectedNotebook.users.map((u) => (
                <UserNotebook
                  key={u.uuid}
                  userNotebook={u}
                  refresh={refresh}
                  createUserNotebook={createUserNotebook}
                  updateUserNotebook={updateUserNotebook}
                  deleteUserNotebook={deleteUserNotebook}
                />
              ))}
          </div>
        );
      default:
        return "You have gone too far !";
    }
  }

  userDidDrop(files) {
    const { importNote, selectedNotebook, refresh } = this.props;
    const { NotificationCenter } = this.context;
    const file = files[0];
    const reader = new FileReader();
    reader.readAsText(file, "UTF-8");
    reader.onload = (evt) => {
      const json = JSON.parse(evt.target.result);
      if (json) {
        importNote(selectedNotebook.id, json).then((resp) => {
          if (resp.success) {
            NotificationCenter.sweetAlert({
              title: "Success",
              body: "Note has been imported",
              success: true,
              timestamp: new Date().getTime(),
            });
            refresh();
          } else {
            NotificationCenter.sweetAlert({
              title: "Error",
              body: "Something went wrong. Check your JSON",
              error: true,
              timestamp: new Date().getTime(),
            });
          }
        });
      }
    };
  }

  async addUserToNotebook(e) {
    const { createUserNotebook, selectedNotebook, refresh } = this.props;
    await createUserNotebook({
      userUID: e.value.uuid,
      notebookID: selectedNotebook.id,
      role: "reader",
    });
    refresh();
  }

  render() {
    const {
      classes,
      notebooks,
      selectedNotebook,
      updateNotebook,
      deleteNotebook,
      createNotebook,
      loading,
      history,
      createNote,
      refresh,
      refreshKey,
      onSelect,
    } = this.props;

    const {
      formNoteOpen,
      notebookToEdit,
      formNotebookOpen,
      menuOpen,
      index,
      noteJiggle,
      notebookJiggle,
    } = this.state;

    const UserTabs = (
      <Tabs
        value={index}
        onChange={(e, v) => {
          this.setState({
            index: v,
          });
          history.push(`?selected=${selectedNotebook.id}&index=${v}`);
        }}
        indicatorColor="primary"
        textColor="primary"
        scrollButtons="auto"
        classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
      >
        <Tab
          label="Notes"
          classes={{
            root: classes.tabRoot,
            selected: classes.tabSelected,
          }}
        />
        <Tab
          label="Archived"
          classes={{
            root: classes.tabRoot,
            selected: classes.tabSelected,
          }}
        />
        {selectedNotebook && selectedNotebook.role === EDITOR ? (
          <Tab
            label="Users"
            classes={{
              root: classes.tabRoot,
              selected: classes.tabSelected,
            }}
          />
        ) : (
          []
        )}
      </Tabs>
    );

    const Menu = (
      <div>
        <NotebookCollection
          notebooks={notebooks}
          selectedNotebook={selectedNotebook}
          onSelect={onSelect}
          refresh={refresh}
          updateNotebook={updateNotebook}
          key={`${refreshKey}${selectedNotebook && selectedNotebook.id}`}
          onEdit={(n) => {
            this.setState({ formNotebookOpen: true, notebookToEdit: n });
          }}
          jiggle={notebookJiggle}
          onRearrange={() => this.setState({ notebookJiggle: true })}
        />
        <div style={{ textAlign: "center", paddingTop: 10 }}>
          <Chip
            variant="outlined"
            label={notebookJiggle ? "Done Reordering" : "+ Notebook"}
            onClick={
              notebookJiggle
                ? () => this.setState({ notebookJiggle: false })
                : () => this.setState({ formNotebookOpen: true })
            }
          />
        </div>
      </div>
    );

    let fab = [];
    if (index === 0 && selectedNotebook && selectedNotebook.role === EDITOR) {
      fab = (
        <Fab
          color={"primary"}
          onClick={() => this.setState({ formNoteOpen: true })}
        >
          <Add />
        </Fab>
      );
    }

    if (noteJiggle) {
      fab = (
        <Fab
          style={{
            background: "#8bc34a",
            color: "white",
          }}
          variant="extended"
          onClick={() => this.setState({ noteJiggle: false })}
        >
          <Done /> Done
        </Fab>
      );
    }

    return (
      <Page
        helmet="Notebooks"
        loadingMessage={"Loading Notebooks"}
        loading={loading}
        noPadding
        fab={fab}
      >
        <Hidden smDown>
          <Drawer
            open
            variant="persistent"
            classes={{ paper: classes.leftDrawer }}
          >
            {Menu}
          </Drawer>
        </Hidden>
        <Hidden mdUp>
          <Drawer
            variant="temporary"
            open={menuOpen}
            onClose={() => this.setState({ menuOpen: false })}
            classes={{ paper: classes.leftDrawerMobile }}
          >
            {Menu}
          </Drawer>
        </Hidden>
        <Hidden smDown>
          <div className={classes.content}>
            {UserTabs}
            <div>
              {this.getStepContent(index)}
              <div
                style={{
                  textAlign: "center",
                  padding: 20,
                }}
              >
                <Typography variant="caption" color="textSecondary">
                  {selectedNotebook
                    ? roleDescriptions[selectedNotebook.role]
                    : ""}
                </Typography>
              </div>
            </div>
          </div>
        </Hidden>
        <Hidden mdUp>
          <div className={classes.contentMobile}>
            {UserTabs}
            <div>{this.getStepContent(index)}</div>
          </div>
        </Hidden>
        <FormNote
          createNote={createNote}
          notebookID={selectedNotebook && selectedNotebook.id}
          open={formNoteOpen}
          refresh={refresh}
          close={() => this.setState({ formNoteOpen: false })}
        />
        <FormNotebook
          notebook={notebookToEdit}
          createNotebook={createNotebook}
          updateNotebook={updateNotebook}
          deleteNotebook={deleteNotebook}
          open={formNotebookOpen}
          refresh={refresh}
          close={() => this.setState({ formNotebookOpen: false })}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperNotebooksPage);
