export default (theme) => ({
  messageBox: {
    padding: "1rem 1rem",
    marginBottom: "1rem",
    border: "1px solid transparent",
    color: theme.palette.type === "dark" ? "white !important" : undefined,
  },
  inputBase: {
    fontFamily: "realist, sans-serif",
    fontSize: 14,
    padding: 4,
  },
});
