import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Signature from "components/Signature";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class SignNote extends Component {
  static propTypes = {
    classes: PropTypes.object,
    onClose: PropTypes.func,
    updateSignatory: PropTypes.func,
    getMySignatories: PropTypes.func,
    signatory: PropTypes.object,
    open: PropTypes.bool,
    refresh: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  state = {
    loading: false,
  };

  async onSignatureReady(url) {
    const {
      updateSignatory,
      getMySignatories,
      signatory,
      onClose,
      refresh,
    } = this.props;
    const { NotificationCenter } = this.context;
    this.setState({ loading: true });
    const resp = await updateSignatory(signatory.id, {
      signatureURL: url,
      signed: true,
    });
    this.setState({ loading: false });
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "Thank You",
        body: "You just approved the note",
        success: true,
        timestamp: new Date().getTime(),
      });
    }
    onClose();
    await getMySignatories();
    refresh();
  }

  render() {
    const { onClose, open } = this.props;

    const { loading } = this.state;

    return (
      <Dialog
        onClose={onClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        style={{
          textAlign: "center",
        }}
      >
        <DialogTitle id="simple-dialog-title">
          <img
            src="https://storage.googleapis.com/wid-arranger/interdoc/b7dc382f-d540-448b-835b-bba2d08e3ada/pen.png"
            alt="pen"
          />
          <br />
          Note Approval
        </DialogTitle>
        {loading ? (
          <DialogContent>
            <CircularProgress />
            <Typography>Sending Signature</Typography>
          </DialogContent>
        ) : (
          <DialogContent>
            <Typography>
              In order to approve the content of this note, please sign in the
              box below :
            </Typography>
            <Typography variant="caption" color="textSecondary">
              The signatures on a document are considered prima facie proof that
              one has consented to the terms and conditions laid down in the
              document and further that he has read or the contents have bread
              out to him in and he has affixed his signatures thereto in token
              of its correctness.
            </Typography>
            <Signature
              height={250}
              onSignatureReady={this.onSignatureReady.bind(this)}
            />
          </DialogContent>
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(SignNote);
