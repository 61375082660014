import { GET_NOTEBOOKS } from "constants/notebooks";

export default (state = [], action) => {
  switch (action.type) {
    case `${GET_NOTEBOOKS}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
