import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import Person from "@material-ui/icons/PersonTwoTone";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";

// styles
import styles from "./styles";

const colors = {
  reader: "#8bc34a",
  user: "#2196f3",
  editor: "#f44336",
};

class UserNotebook extends Component {
  static propTypes = {
    classes: PropTypes.object,
    userNotebook: PropTypes.object,
    updateUserNotebook: PropTypes.func,
    deleteUserNotebook: PropTypes.func,
    refresh: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { userNotebook } = this.props;
    this.state = {
      ...userNotebook,
    };
  }

  async save(name, numeric) {
    const { updateUserNotebook, refresh, userNotebook } = this.props;

    if (userNotebook[name] === this.state[name]) return;

    this.setState({
      loading: true,
    });

    await updateUserNotebook(userNotebook.id, {
      [name]: numeric ? Number(this.state[name]) : this.state[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  async deleteUserNotebook() {
    const { deleteUserNotebook, userNotebook, refresh } = this.props;
    await deleteUserNotebook(userNotebook.id);
    refresh();
  }

  RoleMenu(props) {
    const { role, onChange } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);

    function handleClick(event) {
      event.stopPropagation();
      event.preventDefault();
      setAnchorEl(event.currentTarget);
    }

    function handleClose() {
      setAnchorEl(null);
    }

    const roleLC = role.toLowerCase();
    let background = colors[roleLC];
    if (background === undefined) background = "#9e9e9e";

    const menuObj = Object.assign({}, colors);
    delete menuObj[role];
    const menuOptions = [];
    for (const key in menuObj) {
      if (menuObj.hasOwnProperty(key)) {
        const item = menuObj[key];
        menuOptions.push({
          label: key.charAt(0).toUpperCase() + key.slice(1),
          color: item,
        });
      }
    }

    return (
      <div>
        <Tooltip title="Change role">
          <Button
            style={{
              background,
              color: "white",
              textTransform: "none",
            }}
            onClick={handleClick}
            size="small"
          >
            {role.charAt(0).toUpperCase() + role.slice(1)}
          </Button>
        </Tooltip>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {menuOptions.map((o) => (
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleClose();
                onChange(o.label.toLowerCase());
              }}
              key={o.label}
            >
              <Button
                style={{
                  background: o.color,
                  color: "white",
                  textTransform: "none",
                }}
              >
                {o.label}
              </Button>
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  }

  render() {
    const { classes } = this.props;

    const { role, userName, userUID } = this.state;

    return (
      <div className={classes.container}>
        <div style={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
          <div style={{ width: 30 }}>
            <Person color="inherit" />
          </div>
          <div
            style={{
              flex: 1,
              borderRight: "solid 1px rgba(155,155,155,0.3)",
              padding: 5,
            }}
          >
            <Typography>{userName}</Typography>
          </div>
          <div
            style={{
              flex: 1,
              borderRight: "solid 1px rgba(155,155,155,0.3)",
              padding: 5,
            }}
          >
            <Typography>{userUID}</Typography>
          </div>
          <div style={{ flex: 1, padding: 5, textAlign: "center" }}>
            <this.RoleMenu
              role={role || "undefined"}
              onChange={(newRole) =>
                this.setState({ role: newRole }, () => this.save("role"))
              }
            />
          </div>
        </div>
        <div style={{ width: 30, padding: 5 }}>
          <Tooltip title="Remove from Notebook">
            <IconButton
              style={{ padding: 5 }}
              onClick={this.deleteUserNotebook.bind(this)}
            >
              <Close style={{ fontSize: 18 }} />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(UserNotebook);
