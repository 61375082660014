import { GET, PUT, DELETE, POST } from "constants/methods";

import { REACT_APP_API_PREFIX } from "config/env";

const service = "interdoc";
const notes = `${REACT_APP_API_PREFIX}/${service}/notes`;

export function getNotes() {
  const url = `${notes}`;
  return {
    method: GET,
    url,
  };
}

export function getNoteById(id) {
  const url = `${notes}/${id}/full`;
  return {
    method: GET,
    url,
  };
}

export function noteSearch(filters) {
  const url = `${notes}/search${filters}`;
  return {
    method: GET,
    url,
  };
}

export function duplicateNote(id, body) {
  const url = `${notes}/${id}/duplicate`;
  return {
    method: POST,
    url,
    body,
  };
}

export function getPublicNoteById(token) {
  const url = `${notes}/public/${token}`;
  return {
    method: GET,
    url,
  };
}

export function updateNote(id, body) {
  const url = `${notes}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteNote(id) {
  const url = `${notes}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createNote(body) {
  const url = `${notes}`;
  return {
    method: POST,
    url,
    body,
  };
}
