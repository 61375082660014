import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import ImgShadow from "components/ImgShadow";

// styles
import styles from "./styles";

class Image extends Component {
  static propTypes = {
    classes: PropTypes.object,
    alt: PropTypes.string,
    src: PropTypes.string,
    title: PropTypes.string,
  };

  render() {
    return <ImgShadow {...this.props} />;
  }
}

export default withStyles(styles)(Image);
