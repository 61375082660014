export default {
  name: "Kb",
  description: "Show a feature request using the Moscow syntax",
  example:
    '[[Kb tags="tag1,tag2" problem="This is my problem" resolution="How I fixed it:" title="How to"]]',
  attributes: [
    {
      name: "tags",
      description: "List of tags separated by a coma",
      type: "String",
      example: "tag1,tag2",
      required: false,
    },
    {
      name: "problem",
      description: "Explain the problem",
      type: "String",
      example: "This is my problem",
      required: false,
    },
    {
      name: "resolution",
      description: "Explain how you fixed the problem",
      type: "String",
      example: "How I fixed it:",
      required: false,
    },
    {
      name: "title",
      description: "Give a title to the knowledge",
      type: "String",
      example: "How to do that",
      required: false,
    },
  ],
};
