import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import fileDownload from "react-file-download";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import Fab from "@material-ui/core/Fab";
import EditTT from "@material-ui/icons/EditTwoTone";
import Edit from "@material-ui/icons/Edit";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Eye from "@material-ui/icons/RemoveRedEye";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import ListItemText from "@material-ui/core/ListItemText";
import InfoTT from "@material-ui/icons/InfoTwoTone";
import ShareTT from "@material-ui/icons/ShareTwoTone";
import Translate from "@material-ui/icons/Translate";
import CloudDownload from "@material-ui/icons/CloudDownload";
import Code from "@material-ui/icons/Code";
import AddToPhotos from "@material-ui/icons/AddToPhotos";
import Tooltip from "@material-ui/core/Tooltip";
import Tour from "reactour";

// custom
import Page from "components/Page";
import Markdown from "components/Markdown";
import MarkdownEditor from "components/MarkdownEditor";
import FormSection from "components/FormSection";
import FormSubSection from "components/FormSubSection";
import FormNote from "components/FormNote";
import FormDuplicate from "components/FormDuplicate";
import ContentSummary from "components/ContentSummary";
import NoteMenu from "components/NoteMenu";
import SignNote from "components/SignNote";
import ShareNote from "components/ShareNote";
import Signatory from "components/Signatory";
import "codemirror/mode/markdown/markdown";
import Chip from "@material-ui/core/Chip";
import "codemirror/theme/monokai.css";
import "./codemirror.css";

// image
import sign from "assets/sign.svg";

import { REACT_APP_API, REACT_APP_API_PREFIX } from "config/env";
import { EDITOR, READER, USER } from "constants/noteRoles";
import { getShortcode, replaceShortcodeAttribute } from "helpers/shortcodes";

// styles
import styles from "./styles";
class WrapperNotePage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    app: PropTypes.object,
    note: PropTypes.object,
    loading: PropTypes.bool,
    onSelect: PropTypes.func,
    selectedSubSection: PropTypes.func,
    history: PropTypes.object,
    subscription: PropTypes.object,

    refresh: PropTypes.func,
    updateSection: PropTypes.func,
    createSection: PropTypes.func,
    deleteSection: PropTypes.func,

    updateSubSection: PropTypes.func,
    createSubSection: PropTypes.func,
    deleteSubSection: PropTypes.func,
    updateSignatory: PropTypes.func,
    getMySignatories: PropTypes.func,

    updateNote: PropTypes.func,
    deleteNote: PropTypes.func,
    setIgnoreWS: PropTypes.func,

    getSignatories: PropTypes.func,
    createSignatory: PropTypes.func,
    deleteSignatory: PropTypes.func,

    users: PropTypes.array,
    upload: PropTypes.func,

    duplicateNote: PropTypes.func,
    notebooks: PropTypes.array,
  };

  static contextTypes = {
    appBarMenuHook: PropTypes.func,
    KeyboardEventCenter: PropTypes.object,
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { KeyboardEventCenter } = this.context;

    this.state = {
      menuOpen: false,
      editing: false,
      init: false,
      saving: false,
      content: "",
      visibleAnchor: undefined,
      allowVisibleAnchorChange: false,
      formSectionOpen: false,
      formNoteOpen: false,
      isTourOpen: false,
    };

    KeyboardEventCenter.listen("alt+e", this.toggleEditMode.bind(this));
  }

  componentWillMount() {
    const { appBarMenuHook } = this.context;
    appBarMenuHook(this.onMenuToggle.bind(this));
  }

  componentWillReceiveProps(nextProps) {
    const { editing } = this.state;
    if (
      nextProps.selectedSubSection &&
      this.props.selectedSubSection === undefined
    ) {
      this.setState({
        content: nextProps.selectedSubSection.content,
        selectedSubSectionID: nextProps.selectedSubSection.id,
      });
    }
    if (nextProps.selectedSubSection && this.props.selectedSubSection) {
      if (nextProps.note.refreshKey !== this.props.note.refreshKey) {
        this.setState({
          content: nextProps.selectedSubSection.content,
          selectedSubSectionID: nextProps.selectedSubSection.id,
        });
      }

      if (
        nextProps.selectedSubSection.id !== this.props.selectedSubSection.id
      ) {
        if (editing) {
          this.save().then(() => {
            this.setState({
              content: nextProps.selectedSubSection.content,
              selectedSubSectionID: nextProps.selectedSubSection.id,
            });
          });
        } else {
          this.setState({
            content: nextProps.selectedSubSection.content,
            selectedSubSectionID: nextProps.selectedSubSection.id,
          });
        }
      }
    }
  }

  componentDidUpdate() {
    const { note } = this.props;
    const { content, init } = this.state;
    if (content && !init) {
      setTimeout(() => {
        const anchor = window.location.hash.split("#")[1];
        this.scrollTo(anchor)();
      }, 500);
      if (note && note.signatory && !note.signatory.signed) {
        this.state.isTourOpen = true; //eslint-disable-line
      }
      this.state.init = true; //eslint-disable-line
    }
  }

  componentWillUnmount() {
    const { KeyboardEventCenter } = this.context;
    KeyboardEventCenter.disregard();
  }

  onMenuToggle() {
    this.setState((prevState) => ({ menuOpen: !prevState.menuOpen }));
  }

  onEdit(type, item) {
    switch (type) {
      case "section":
        this.setState({ sectionToEdit: item, formSectionOpen: true });
        break;
      case "subSection":
        this.setState({ subSectionToEdit: item, formSubSectionOpen: true });
        break;
      default:
        break;
    }
  }

  onAdd(type, relatedSectionID) {
    switch (type) {
      case "section":
        this.setState({ formSectionOpen: true });
        break;
      case "subSection":
        this.setState({ formSubSectionOpen: true, relatedSectionID });
        break;
      default:
        break;
    }
  }

  onShortcodeChange(shortcodeID, newAttributes) {
    const { content } = this.state;
    const shortcode = getShortcode(content, shortcodeID);
    let tmpShortcode = `${shortcode}`;
    for (const attribute in newAttributes) {
      if (newAttributes.hasOwnProperty(attribute)) {
        const value = newAttributes[attribute];
        tmpShortcode = replaceShortcodeAttribute(
          tmpShortcode,
          attribute,
          value
        );
      }
    }

    this.setState(
      {
        content: content.replace(shortcode, tmpShortcode),
      },
      this.save
    );
  }

  onAddShortcode(shortcodeID, newShortcode) {
    const { content } = this.state;
    const shortcode = getShortcode(content, shortcodeID);

    this.setState(
      {
        content: content.replace(shortcode, `${shortcode}\n${newShortcode}`),
      },
      this.save
    );
  }

  onShortcodeDelete(shortcodeID) {
    const { content } = this.state;
    const shortcode = getShortcode(content, shortcodeID);
    this.setState(
      {
        content: content.replace(shortcode, ""),
      },
      this.save
    );
  }

  onCheck(checkboxIndex, value) {
    const { content } = this.state;
    const myRe = /\[( |X|x)\]*/g;
    const str = content;
    let myArray; // eslint-disable-line
    const res = [];
    while ((myArray = myRe.exec(str)) !== null) {
      res.push(myRe.lastIndex);
    }

    const i = res[checkboxIndex];
    this.setState(
      {
        content: `${str.substr(0, i - 3)}${value ? "[X]" : "[ ]"}${str.substr(
          i
        )}`,
      },
      this.save
    );
  }

  scrollTo = (anchor) => () => {
    const element = document.getElementById(anchor);
    this.state.allowVisibleAnchorChange = false; //eslint-disable-line
    if (element) element.scrollIntoView({ block: "start", behavior: "smooth" });
    this.updateAnchorInURL(anchor);
    setTimeout(() => {
      this.setState({
        allowVisibleAnchorChange: true,
        visibleAnchor: anchor,
      });
    }, 1500);
  };

  updateAnchorInURL(anchor) {
    const { history } = this.props;
    if (anchor) {
      history.push({
        search: `${window.location.search}${anchor ? `#${anchor}` : ""}`,
      });
    }
  }

  updateContent(content, callback) {
    this.setState(
      {
        content,
      },
      () => {
        if (callback) callback();
      }
    );
  }

  async save() {
    const { updateSubSection } = this.props;
    const { content, selectedSubSectionID } = this.state;
    this.setState({ saving: true });
    await updateSubSection(selectedSubSectionID, { content });
    // setIgnoreWS(true);
    // setTimeout(() => setIgnoreWS(false), 1500);
    this.setState({ saving: false });
  }

  openNoteForm() {
    this.setState({ formNoteOpen: true });
  }

  toggleEditMode() {
    const { note } = this.props;
    if (note && note.role === EDITOR) {
      this.setState((prevState) => ({
        editing: !prevState.editing,
        edit: true,
      }));
    }
  }

  NoteOptions(props) {
    const {
      onFormUpdate,
      toggleEditMode,
      editing,
      shareNote,
      openSignatory,
      openDuplicate,
      role,
      note,
      getNoteById,
    } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    function handleClick(event) {
      setAnchorEl(event.currentTarget);
    }

    function handleClose() {
      setAnchorEl(null);
    }

    return (
      <div>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          color="inherit"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: 200,
            },
          }}
        >
          {editing ? (
            <MenuItem
              onClick={() => {
                toggleEditMode();
                handleClose();
              }}
              style={{
                display: role === USER || role === READER ? "none" : undefined,
              }}
            >
              <ListItemIcon>
                <Eye />
              </ListItemIcon>
              <ListItemText>Read (Alt + E)</ListItemText>
            </MenuItem>
          ) : (
            <MenuItem
              onClick={() => {
                toggleEditMode();
                handleClose();
              }}
              style={{
                display: role === USER || role === READER ? "none" : undefined,
              }}
            >
              <ListItemIcon>
                <EditTT />
              </ListItemIcon>
              <ListItemText>Edit (Alt + E)</ListItemText>
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              onFormUpdate();
              handleClose();
            }}
            style={{
              display: role === USER || role === READER ? "none" : undefined,
            }}
          >
            <ListItemIcon>
              <InfoTT />
            </ListItemIcon>
            <ListItemText>Note Info</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              shareNote();
              handleClose();
            }}
          >
            <ListItemIcon>
              <ShareTT />
            </ListItemIcon>
            <ListItemText>Share</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              window.open(
                `${REACT_APP_API}${REACT_APP_API_PREFIX}/interdoc/notes/${note.id}/preview`,
                "_blank"
              );
            }}
          >
            <ListItemIcon>
              <CloudDownload />
            </ListItemIcon>
            <ListItemText>Export in PDF</ListItemText>
          </MenuItem>
          <MenuItem
            style={{
              display: role === USER || role === READER ? "none" : undefined,
            }}
            onClick={async () => {
              handleClose();
              const resp = await getNoteById(note.id);
              const n = resp.payload;
              fileDownload(
                JSON.stringify(n, null, "\t"),
                `${n.name}_v${n.version}.json`
              );
            }}
          >
            <ListItemIcon>
              <Code />
            </ListItemIcon>
            <ListItemText>Export in JSON</ListItemText>
          </MenuItem>
          <MenuItem
            style={{
              display: role === USER || role === READER ? "none" : undefined,
            }}
            onClick={() => {
              openSignatory();
              handleClose();
            }}
          >
            <ListItemIcon>
              <Translate />
            </ListItemIcon>
            <ListItemText>Signatory</ListItemText>
          </MenuItem>
          <MenuItem
            style={{
              display: role === USER || role === READER ? "none" : undefined,
            }}
            onClick={() => {
              openDuplicate();
              handleClose();
            }}
          >
            <ListItemIcon>
              <AddToPhotos />
            </ListItemIcon>
            <ListItemText>Duplicate</ListItemText>
          </MenuItem>
        </Menu>
      </div>
    );
  }

  render() {
    const {
      app,
      classes,
      loading,
      onSelect,
      note,
      selectedSubSection,
      updateSection,
      createSection,
      deleteSection,
      updateSubSection,
      createSubSection,
      deleteSubSection,
      updateNote,
      deleteNote,
      refresh,
      history,
      subscription,
      upload,
      setIgnoreWS,
      updateSignatory,
      getMySignatories,
      getSignatories,
      createSignatory,
      deleteSignatory,
      users,
      notebooks,
      duplicateNote,
      getNoteById,
    } = this.props;

    const {
      menuOpen,
      editing,
      content,
      visibleAnchor,
      saving,
      formSectionOpen,
      sectionToEdit,
      subSectionToEdit,
      formSubSectionOpen,
      formNoteOpen,
      relatedSectionID,
      selectedSubSectionID,
      duplicateOpen,
    } = this.state;

    const role = note && note.role;

    const options = {
      lineNumbers: true,
      autofocus: true,
      mode: "markdown",
      theme: app.darkMode ? "monokai" : "default",
    };

    const breadcrumbs = (
      <Breadcrumbs separator="›">
        <Typography variant="caption" color="textSecondary">
          {selectedSubSection && selectedSubSection.section
            ? selectedSubSection.section.title
            : ""}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          {selectedSubSection ? selectedSubSection.title : ""}
        </Typography>
      </Breadcrumbs>
    );

    const Header = (
      <div
        style={{
          background: "#2196f3",
          color: "white",
          padding: 10,
        }}
      >
        <Grid container justify="space-between" alignItems="center">
          <Grid item style={{ width: "calc(100% - 40px)" }}>
            <Typography
              color="inherit"
              variant="h6"
              style={{ fontFamily: "realist, sans-serif" }}
            >
              {note ? note.name : ""}
            </Typography>
            <Typography
              color="inherit"
              variant="caption"
              style={{ fontFamily: "realist, sans-serif" }}
            >
              {note ? note.description : ""}
            </Typography>
          </Grid>
          {note && note.role !== "public" ? (
            <Grid item style={{ width: 40 }}>
              <this.NoteOptions
                onFormUpdate={() => this.setState({ formNoteOpen: true })}
                openSignatory={() => this.setState({ signatoryOpen: true })}
                openDuplicate={() => this.setState({ duplicateOpen: true })}
                editing={editing}
                toggleEditMode={this.toggleEditMode.bind(this)}
                role={role}
                getNoteById={getNoteById}
                note={note}
                shareNote={() => this.setState({ shareNoteOpen: true })}
              />
            </Grid>
          ) : (
            []
          )}
        </Grid>
      </div>
    );

    const Signature = (
      <div>
        {note && note.signatory && note.signatory.signed ? (
          <div
            style={{
              padding: 20,
              height: 114,
            }}
          >
            <div
              style={{
                border: "solid 1px rgba(155,155,155, 0.3)",
                background: app.darkMode ? "#424242" : "white",
                borderRadius: 4,
                padding: 4,
                textAlign: "center",
                height: 104,
              }}
            >
              <img
                src={note.signatory.signatureURL}
                style={{
                  height: 70,
                  filter: app.darkMode ? "invert(100%)" : undefined,
                }}
                alt="Sour Signature"
              />
              <Typography variant="caption" color="textSecondary">
                {moment(note.signatory.signedDate).format("LLL")}
              </Typography>
            </div>
          </div>
        ) : (
          []
        )}
      </div>
    );

    return (
      <Page
        helmet={note ? `${note.name} - ${note.description}` : "Loading"}
        loadingMessage={"Loading Document"}
        loading={loading}
        fab={
          <React.Fragment>
            {editing && selectedSubSection ? (
              <Hidden smDown>
                <Tooltip title="Edit (Alt+E)">
                  <Fab
                    color={"primary"}
                    disabled={saving}
                    style={{ zIndex: 9999 }}
                    onClick={() => {
                      if (editing) {
                        this.save();
                      }
                      this.setState((prevState) => ({
                        editing: !prevState.editing,
                      }));
                    }}
                  >
                    {editing ? <Eye /> : <Edit />}
                  </Fab>
                </Tooltip>
              </Hidden>
            ) : (
              []
            )}
            {note && note.signatory && !note.signatory.signed && !editing ? (
              <Tooltip title="Sign Note">
                <Fab
                  data-tut="reactour__signature"
                  style={{
                    background: "#ff9800",
                    color: "white",
                    zIndex: 9999,
                  }}
                  disabled={saving}
                  onClick={() => {
                    this.setState({
                      signOpen: true,
                    });
                  }}
                >
                  <img src={sign} style={{ width: 30 }} alt="sign" />
                </Fab>
              </Tooltip>
            ) : (
              []
            )}
          </React.Fragment>
        }
        noPadding
      >
        <Hidden smDown>
          <Drawer
            open
            variant="persistent"
            classes={{ paper: classes.leftDrawer }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "space-between",
                height: "calc(100vh - 47px)",
                overflow: "scroll",
              }}
            >
              <div>{Header}</div>
              <div
                style={{
                  flexGrow: 1,
                }}
              >
                <NoteMenu
                  note={note}
                  edit={editing && role === EDITOR}
                  selectedSubSection={selectedSubSection}
                  updateSection={updateSection}
                  updateSubSection={updateSubSection}
                  onSelect={onSelect}
                  refresh={refresh}
                  onAdd={this.onAdd.bind(this)}
                  onEdit={this.onEdit.bind(this)}
                />
              </div>
              {Signature}
            </div>
          </Drawer>
          {editing && role === EDITOR && selectedSubSection ? (
            <div className={classes.codeMirror}>
              <MarkdownEditor
                value={content}
                subscription={subscription}
                onChange={
                  role === EDITOR ? this.updateContent.bind(this) : undefined
                }
                save={role === EDITOR ? this.save.bind(this) : undefined}
                options={options}
                upload={upload}
                refreshKey={note && note.refreshKey}
                key={selectedSubSectionID}
                setIgnoreWS={setIgnoreWS}
                className="CustomCodemirror"
              />
            </div>
          ) : (
            <div className={classes.content}>
              {selectedSubSection ? (
                <React.Fragment>
                  <Grid
                    container
                    justify="space-between"
                    className={classes.breadcrumb}
                  >
                    <Grid item>{breadcrumbs}</Grid>
                    <Grid item>
                      {selectedSubSection.next ? (
                        <Chip
                          label="Next →"
                          size="small"
                          color="primary"
                          onClick={selectedSubSection.next}
                        />
                      ) : (
                        []
                      )}
                    </Grid>
                  </Grid>
                  <div>
                    <Markdown
                      source={content}
                      darkMode={app.darkMode}
                      onCheck={
                        role === EDITOR || role === USER
                          ? this.onCheck.bind(this)
                          : undefined
                      }
                      onChange={
                        role === EDITOR
                          ? this.updateContent.bind(this)
                          : undefined
                      }
                      save={role === EDITOR ? this.save.bind(this) : undefined}
                      onAddShortcode={
                        role === EDITOR
                          ? this.onAddShortcode.bind(this)
                          : undefined
                      }
                      onShortcodeChange={
                        role === EDITOR || role === USER
                          ? this.onShortcodeChange.bind(this)
                          : undefined
                      }
                      onDelete={
                        role === EDITOR || role === USER
                          ? this.onShortcodeDelete.bind(this)
                          : undefined
                      }
                      scrollTo={this.scrollTo.bind(this)}
                      setVisible={(anchor) => {
                        if (this.state.allowVisibleAnchorChange) {
                          this.setState({ visibleAnchor: anchor });
                          // this.updateAnchorInURL(anchor);
                        }
                      }}
                    />
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Typography variant="h6" color="textSecondary">
                    {!editing
                      ? "← To Get Started click on edit (or Alt + E)."
                      : "← Now, create a section and a subsection."}
                  </Typography>
                </React.Fragment>
              )}
            </div>
          )}
          {!editing && selectedSubSection ? (
            <Drawer
              open
              anchor="right"
              variant="persistent"
              classes={{ paper: classes.rightDrawer }}
            >
              <ContentSummary
                source={content}
                key={selectedSubSection && selectedSubSection.id}
                expanded
                visibleAnchor={visibleAnchor}
                onSelect={this.scrollTo.bind(this)}
              />
            </Drawer>
          ) : (
            []
          )}
        </Hidden>
        <Hidden mdUp>
          <Drawer
            variant="temporary"
            open={menuOpen}
            classes={{ paper: classes.leftDrawerMobile }}
            onClose={() => this.setState({ menuOpen: false })}
          >
            {Header}
            <NoteMenu
              note={note}
              edit={editing && role === EDITOR}
              selectedSubSection={selectedSubSection}
              onSelect={onSelect}
              // key={note && note.refreshKey}
              refresh={refresh}
              updateSection={updateSection}
              updateSubSection={updateSubSection}
              onEdit={this.onEdit.bind(this)}
              onAdd={this.onAdd.bind(this)}
            />
            {Signature}
          </Drawer>
          <div>
            {selectedSubSection ? (
              <React.Fragment>
                <Grid
                  container
                  justify="space-between"
                  className={classes.mobileBreadcrumb}
                >
                  <Grid item>{breadcrumbs}</Grid>
                  <Grid item>
                    {selectedSubSection.next ? (
                      <Chip
                        label="Next →"
                        size="small"
                        color="primary"
                        onClick={selectedSubSection.next}
                      />
                    ) : (
                      []
                    )}
                  </Grid>
                </Grid>
              </React.Fragment>
            ) : (
              []
            )}
          </div>
          <div className={classes.contentMobile}>
            {selectedSubSection ? (
              <div>
                <ContentSummary
                  source={content}
                  key={selectedSubSection && selectedSubSection.id}
                  onSelect={this.scrollTo.bind(this)}
                />
                <Markdown
                  source={content}
                  darkMode={app.darkMode}
                  onCheck={this.onCheck.bind(this)}
                  onShortcodeChange={this.onShortcodeChange.bind(this)}
                  scrollTo={this.scrollTo.bind(this)}
                />
              </div>
            ) : (
              <React.Fragment>
                <Typography variant="h6" color="textSecondary">
                  To Get Started, Create Sections and Subsections.
                </Typography>
              </React.Fragment>
            )}
          </div>
        </Hidden>
        <FormSection
          section={sectionToEdit}
          createSection={createSection}
          open={formSectionOpen}
          deleteSection={deleteSection}
          updateSection={updateSection}
          refresh={refresh}
          noteID={note && note.id}
          close={() =>
            this.setState({ formSectionOpen: false, sectionToEdit: undefined })
          }
        />
        <FormSubSection
          subSection={subSectionToEdit}
          createSubSection={createSubSection}
          open={formSubSectionOpen}
          deleteSubSection={deleteSubSection}
          updateSubSection={updateSubSection}
          refresh={refresh}
          sectionID={relatedSectionID}
          close={() =>
            this.setState({
              formSubSectionOpen: false,
              subSectionToEdit: undefined,
            })
          }
        />
        <FormNote
          note={note}
          updateNote={updateNote}
          notebooks={notebooks}
          open={formNoteOpen}
          deleteNote={deleteNote}
          refresh={refresh}
          history={history}
          close={() => this.setState({ formNoteOpen: false })}
        />
        <FormDuplicate
          note={note}
          duplicateNote={duplicateNote}
          notebooks={notebooks}
          open={duplicateOpen}
          history={history}
          close={() => this.setState({ duplicateOpen: false })}
        />
        <Tour
          steps={[
            {
              selector: '[data-tut="reactour__signature"]',
              content:
                "Whenever you are ready, click on this button to sign and approve the note.",
            },
          ]}
          accentColor={"#2196f3"}
          isOpen={this.state.isTourOpen}
          onRequestClose={() => this.setState({ isTourOpen: false })}
        />
        <SignNote
          open={this.state.signOpen || false}
          onClose={() => this.setState({ signOpen: false })}
          signatory={note && note.signatory}
          updateSignatory={updateSignatory}
          getMySignatories={getMySignatories}
          refresh={refresh}
        />
        <ShareNote
          open={this.state.shareNoteOpen || false}
          note={note}
          onClose={() => this.setState({ shareNoteOpen: false })}
        />
        <Signatory
          open={this.state.signatoryOpen || false}
          note={note}
          onClose={() => {
            this.setState({ signatoryOpen: false });
          }}
          users={users}
          getSignatories={getSignatories}
          createSignatory={createSignatory}
          deleteSignatory={deleteSignatory}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperNotePage);
