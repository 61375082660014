import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { Textfit } from "react-textfit";
import "./anime.css";

// routes
import { NOTE } from "constants/routes";

import Sign from "assets/sign.svg";

// styles
import styles from "./styles";

class NoteCover extends Component {
  static propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
    note: PropTypes.object,
    signatory: PropTypes.object,
    jiggle: PropTypes.bool,
    archived: PropTypes.bool,
    onRearrange: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    console.log("constructor");
  }

  state = {
    hover: false,
    mouseDown: false,
    random: [this.random(), this.random(), this.random(), this.random()],
  };

  hexToRgbA(hex, opacity) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return (
        "rgba(" +
        [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
        `,${opacity})`
      );
    }
    throw new Error("Bad Hex");
  }

  shadeColor(color, percent) {
    var R = parseInt(color.substring(1, 3), 16);
    var G = parseInt(color.substring(3, 5), 16);
    var B = parseInt(color.substring(5, 7), 16);

    R = parseInt((R * (100 + percent)) / 100);
    G = parseInt((G * (100 + percent)) / 100);
    B = parseInt((B * (100 + percent)) / 100);

    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;

    var RR =
      R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
    var GG =
      G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
    var BB =
      B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);

    return "#" + RR + GG + BB;
  }

  random() {
    let sign = 1;
    if (Math.random() > 0.5) {
      sign = -1;
    }
    const random = Math.round(Math.random() * 35 * sign);

    return random;
  }

  render() {
    const { hover, random } = this.state;
    const {
      note,
      history,
      signatory,
      jiggle,
      onRearrange,
      archived,
    } = this.props;

    const redirect = `${NOTE}?bookmark=${note.id}`;

    let elevation = 1;
    if (hover) elevation = 5;
    if (jiggle) elevation = 10;

    return (
      <div //eslint-disable-line
        onClick={() => {
          if (!jiggle) {
            history.push(redirect);
          }
        }}
        onMouseDown={() => {
          this.setState({
            mouseDown: true,
          });
          setTimeout(() => {
            if (this.state.mouseDown) {
              this.setState({
                mouseDown: false,
              });
              onRearrange();
            }
          }, 1000);
        }}
        onMouseUp={() => {
          this.setState({
            mouseDown: false,
          });
        }}
        style={{
          animationDelay: jiggle ? `-${Math.random()}s` : undefined,
          animationDuration: jiggle
            ? `.${Math.floor(Math.random() * 11) + 20}s`
            : undefined,
          opacity: archived ? 0.5 : 1,
          WebkitTouchCallout: "none",
          userSelect: "none",
        }}
        className="icon"
      >
        <Paper
          onMouseEnter={() => this.setState({ hover: true })}
          onMouseLeave={() => this.setState({ hover: false })}
          elevation={elevation}
          className="anime"
          style={{
            background: `linear-gradient(-45deg, ${this.shadeColor(
              note.color,
              0
            )}, ${this.shadeColor(note.color, random[1])}, ${this.shadeColor(
              note.color,
              random[2]
            )}, ${this.shadeColor(note.color, random[3])})`,
            backgroundSize: "400% 400%",
            animation: "gradientBG 10s ease infinite",
            boxShadow: `0px 4px 10px ${this.hexToRgbA(note.color, 0.5)}`,
          }}
        >
          <Textfit>
            <Grid
              container
              style={{
                height: 190,
                width: 140,
                color: "white",
              }}
              justify="center"
              alignItems="center"
            >
              <Grid item>{note.name}</Grid>
            </Grid>
          </Textfit>
          <div
            style={{ color: "white", position: "relative", textAlign: "left" }}
          >
            <Typography color="inherit">By {note.author}</Typography>
            <Typography variant="caption" color="inherit">
              v{note.version}
            </Typography>
            {signatory && signatory.signatureURL ? (
              <img
                src={signatory.signatureURL}
                style={{
                  filter: "invert(100%)",
                  height: 40,
                  width: 70,
                  bottom: 0,
                  right: -10,
                  position: "absolute",
                }}
                alt="your signature"
              />
            ) : (
              []
            )}
            {signatory && !signatory.signed ? (
              <img
                src={Sign}
                style={{
                  height: 30,
                  bottom: 5,
                  right: 2,
                  position: "absolute",
                }}
                alt="your signature"
              />
            ) : (
              []
            )}
          </div>
          {/* <Require
            updateProject={updateProject}
          >
            <Chip
              variant="outlined"
              avatar={(
                <Avatar style={{ background: 'none' }}>
                  <Info />
                </Avatar>
              )}
              label="Edit Info"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onEdit(project);
              }}

              className={classes.button}
            />
          </Require> */}
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(NoteCover);
