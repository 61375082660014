import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/icons/Link";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Fade from "@material-ui/core/Fade";
import VisibilitySensor from "react-visibility-sensor";

// helpers
import { copyTextToClipboard } from "helpers/url";

// styles
import styles from "./styles";

class Heading extends Component {
  static propTypes = {
    level: PropTypes.number,
    children: PropTypes.node,
    classes: PropTypes.object,
    scrollTo: PropTypes.func,
    setVisible: PropTypes.func,
  };

  state = {
    hover: false,
  };

  onChange(anchor, isVisible) {
    const { setVisible } = this.props;
    if (isVisible && setVisible) {
      setVisible(anchor);
    }
  }

  render() {
    const { level, children, classes, scrollTo } = this.props;
    const { hover } = this.state;
    const text = children[0] ? children[0].props.children : "";
    const anchor = `${text}`.toLowerCase().replace(/ /g, "-");

    return (
      <VisibilitySensor
        onChange={(isVisible) => this.onChange(anchor, isVisible)}
      >
        <Grid
          container
          alignItems="center"
          id={anchor}
          style={{ position: "relative" }}
          onMouseEnter={() => this.setState({ hover: true })}
          onMouseLeave={() => this.setState({ hover: false })}
        >
          <Grid item style={{ position: "absolute", left: -26 }}>
            {hover ? (
              <Fade in>
                <IconButton
                  style={{ padding: 5 }}
                  onClick={() => {
                    scrollTo(anchor)();
                    copyTextToClipboard(window.location.href);
                  }}
                >
                  <Link style={{ fontSize: 20 }} />
                </IconButton>
              </Fade>
            ) : (
              []
            )}
          </Grid>
          <Grid item style={{ flexGrow: 1 }}>
            <Typography
              gutterBottom
              variant={`h${level}`}
              classes={{
                h1: classes.h1,
                h2: classes.h2,
                h3: classes.h3,
                h4: classes.h4,
                h5: classes.h5,
                h6: classes.h6,
              }}
            >
              {children}
            </Typography>
          </Grid>
        </Grid>
      </VisibilitySensor>
    );
  }
}

export default withStyles(styles)(Heading);
