import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";

// styles
import styles from "./styles";

// images
import Gitlab from "./gitlab.svg";

class Repo extends Component {
  static propTypes = {
    url: PropTypes.string,
  };

  state = {
    hover: false,
  };

  parseUrl() {
    const { url } = this.props;
    const parse = url.split("/");

    return {
      group: parse[3],
      project: parse[4],
      favicon: `${parse[0]}//${parse[2]}/favicon.ico`,
    };
  }

  render() {
    const { url } = this.props;
    const { hover } = this.state;
    const issue = this.parseUrl();

    return (
      <Tooltip title="Open in Gitlab">
        <Paper
          style={{
            margin: "8px 0px 16px 0px",
            display: "inline-block",
            border: "solid 1px rgba(155,155,155,0.3)",
            background: hover ? "rgba(155,155,155,0.1)" : "rgba(155,155,155,0)",
            borderRadius: 4,
            padding: 6,
            cursor: "pointer",
          }}
          elevation={hover ? 4 : 0}
          onMouseEnter={() => this.setState({ hover: true })}
          onMouseLeave={() => this.setState({ hover: false })}
          onClick={() => window.open(url, "_blank")}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <img
                src={Gitlab}
                style={{ height: 30, width: 30 }}
                alt="gitlab"
              />
            </Grid>
            <Grid item>
              <Typography
                color="textSecondary"
                variant="caption"
                style={{ fontFamily: "realist, sans-serif" }}
              >
                Gitlab Group: {issue.group}
              </Typography>
              <br />
              <Typography
                variant="body"
                style={{ fontFamily: "realist, sans-serif", fontWeight: 500 }}
              >
                {issue.project}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Tooltip>
    );
  }
}

export default withStyles(styles)(Repo);
