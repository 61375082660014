export default {
  name: "Divider",
  description: "Divide wrapper items",
  example: '[[Divider title="Burndown List"]]',
  attributes: [
    {
      name: "title",
      description: "The title you want to display",
      type: "String",
      example: "Burndown List",
      required: false,
    },
  ],
};
