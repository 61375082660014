import { GET, PUT, DELETE, POST } from "constants/methods";

import { REACT_APP_API_PREFIX } from "config/env";

const service = "interdoc";
const userNotebooks = `${REACT_APP_API_PREFIX}/${service}/userNotebooks`;

export function getUserNotebooks() {
  const url = `${userNotebooks}`;
  return {
    method: GET,
    url,
  };
}

export function getUserNotebookById(id) {
  const url = `${userNotebooks}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateUserNotebook(id, body) {
  const url = `${userNotebooks}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteUserNotebook(id) {
  const url = `${userNotebooks}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createUserNotebook(body) {
  const url = `${userNotebooks}`;
  return {
    method: POST,
    url,
    body,
  };
}
