export default (theme) => ({
  container: {
    padding: 5,
  },
  sectionLabel: {
    padding: 6,
    fontFamily: "realist, sans-serif",
    fontWeight: 600,
  },
  selectedSectionLabel: {
    padding: 6,
    fontFamily: "realist, sans-serif",
    fontWeight: 600,
    color: theme.palette.primary.color[800],
  },
  subSectionLabel: {
    padding: 6,
    fontFamily: "realist, sans-serif",
    fontWeight: 300,
    fontSize: 14,
  },
  selectedSubSectionLabel: {
    padding: 6,
    fontFamily: "realist, sans-serif",
    fontWeight: 300,
    fontSize: 14,
    color: theme.palette.primary.color[500],
  },
  noFocus: {
    "& :focus .MuiTreeItem-content": {
      background: "none !important",
    },
    "& :hover .MuiTreeItem-content": {
      background: "none !important",
    },
  },
  add: {
    fontFamily: "realist, sans-serif",
    fontWeight: 300,
    fontSize: 12,
    color: "rgba(155,155,155,0.8)",
    border: "solid 1px rgba(155,155,155,0.8)",
    borderRadius: 20,
    padding: "2px 4px 2px 4px",
    margin: 4,
  },
});
