export default (theme) => ({
  codeheader: {
    padding: "0.8rem 0.8rem",
    border: `solid 1px ${
      theme.palette.type === "dark" ? "#444444" : "#e9e9e9"
    }`,
    borderBottom: "none",
    background:
      theme.palette.type === "dark"
        ? "rgba(155,155,155, 0.2)"
        : "rgba(96,125,139, 0.2)",
    width: "calc(100% - 1.6rem)",
    fontWeight: 500,
  },
});
