import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";

// styles
import styles from "./styles";

class RendTableCell extends Component {
  static propTypes = {
    classes: PropTypes.object,
    children: PropTypes.any,
    align: PropTypes.string,
  };

  render() {
    const { children, align } = this.props;

    return <TableCell align={align || undefined}>{children}</TableCell>;
  }
}

export default withStyles(styles)(RendTableCell);
