import React from "react";
import PropTypes from "prop-types";

// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Public from "@material-ui/icons/PublicTwoTone";

// helpers
import { getJsonFromUrl } from "helpers";

// styles
import styles from "./styles";

class UrlActions extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    location: PropTypes.object,
    pathname: PropTypes.string,
    history: PropTypes.object,
    user: PropTypes.object,
    loading: PropTypes.bool,
    validateEmail: PropTypes.func,
    storeResetPasswordToken: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
    openSignup: PropTypes.func,
  };

  componentWillMount() {
    const { loading } = this.props;
    if (!loading) {
      this.routine();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.loading && this.props.loading) {
      setTimeout(this.routine.bind(this), 100);
    }

    if (nextProps.pathname !== this.props.pathname) {
      setTimeout(this.routine.bind(this), 100);
    }
  }

  routine() {
    const { location, storeResetPasswordToken, user } = this.props;
    const { NotificationCenter } = this.context;
    const { openSignup } = this.context;
    const search = getJsonFromUrl(location);
    let localStorage;
    if (window && navigator.cookieEnabled) {
      localStorage = window.localStorage;
    }

    for (const k in search) {
      if (search.hasOwnProperty(k)) {
        switch (k) {
          case "activateToken":
            this.activateEmail(search[k]);
            break;
          case "inviteToken":
            if (user && user.uuid) {
              // setTimeout(() => NotificationCenter.sweetAlert(
              //   {
              //     title: (
              //       <Grid container direction="column">
              //         <Grid item>
              //           <Public
              //             style={{ fontSize: 70 }}
              //           />
              //         </Grid>
              //         <Grid item>
              //           <Typography variant="h6">
              //             Join Organization
              //           </Typography>
              //         </Grid>
              //       </Grid>
              //     ),
              //     body: (
              //       <Typography>
              //         You received an invitation to join an organization.
              //       </Typography>
              //     ),
              //     timestamp: new Date().getTime(),
              //   },
              //   {
              //     cancel: {
              //       label: 'Decline',
              //       level: 'cancel',
              //     },
              //     confirm: {
              //       label: 'Accept',
              //       level: 'success',
              //       callback: () => {
              //         openSignup(search[k]);
              //       },
              //     },
              //   },
              // ), 500);
            } else {
              NotificationCenter.sweetAlert(
                {
                  title: (
                    <Grid container direction="column">
                      <Grid item>
                        <Public style={{ fontSize: 70 }} />
                      </Grid>
                      <Grid item>
                        <Typography variant="h6">Join Organization</Typography>
                      </Grid>
                    </Grid>
                  ),
                  body: (
                    <Typography>
                      You have been invited to join an organization. If you
                      already have an account, just sign in and accept.
                      Otherwise, create an account.
                    </Typography>
                  ),
                  timestamp: new Date().getTime(),
                },
                {
                  cancel: {
                    label: "Sign In",
                    level: "cancel",
                  },
                  confirm: {
                    label: "Create an Account",
                    level: "info",
                    callback: () => openSignup(search[k]),
                  },
                }
              );
            }
            break;
          case "resetPassword":
            storeResetPasswordToken(search[k]);
            break;
          case "redirectTo":
            if (localStorage) {
              localStorage.redirectUrl = decodeURIComponent(search[k]);
            }
            break;
          case "signup":
            openSignup();
            break;
          default:
            return;
        }
      }
    }
  }

  async activateEmail(token) {
    const { validateEmail, history } = this.props;
    const { NotificationCenter } = this.context;

    const resp = await validateEmail(token);
    if (resp.success) {
      NotificationCenter.sweetAlert(
        {
          title: "Good Job !",
          subtitle: "You email has been successfully confirmed.",
          body: "You can now start using the portal",
          success: true,
          timestamp: new Date().getTime(),
        },
        {
          confirm: {
            label: "Go to the Portal",
            level: "success",
            callback: () => history.push("/"),
          },
        }
      );
    }
  }

  render() {
    const { children } = this.props;

    return <div>{children}</div>;
  }
}

export default withStyles(styles)(UrlActions);
